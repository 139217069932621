import { HandleClickSweaterData } from "components/Design/DesignAccordion/SweaterAccordion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import {
  Sweater,
  SweaterConstruction,
  SweaterKeys,
  SweaterSleeve,
} from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import "./SelectionBox.scss";
import { createSelectionOptions } from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import "./SleeveSelectionBox.scss";
import { validSleeves } from "utils/designUtils";

interface SleeveSelectionBoxProps {
  handleClick: HandleClickSweaterData;
}

const SleeveSelectionBox = (props: SleeveSelectionBoxProps) => {
  const { design, updateDesign } = useDesign();
  const { t } = useTranslation();
  const useEffectDependencies = [
    design.designChoices.size,
    SweaterKeys.SLEEVE in design.designChoices
      ? design.designChoices.sleeve
      : "",
  ];

  const [activeClassSleeve, setActiveClassSleeve] = useState(
    SweaterKeys.SLEEVE in design.designChoices
      ? design.designChoices.sleeve
      : "",
  );

  useEffect(() => {
    if (design.patternType === ProductType.SWEATERPATTERN)
      setActiveClassSleeve(design.designChoices.sleeve);
  }, useEffectDependencies);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (design.patternType !== ProductType.SWEATERPATTERN) return;
    if (
      design.designChoices.construction === SweaterConstruction.RAGLAN &&
      design.designChoices.sleeve === SweaterSleeve.NOSLEEVE
    ) {
      updateDesign<Sweater, SweaterKeys.SLEEVE>(
        SweaterKeys.SLEEVE,
        SweaterSleeve.REGULAR,
      );
      setActiveClassSleeve(SweaterSleeve.REGULAR);
      props.handleClick(SweaterKeys.SLEEVE, SweaterSleeve.REGULAR);
    }
  });

  if (design.patternType !== ProductType.SWEATERPATTERN) return <></>;

  const sleeveOptions = createSelectionOptions<Sweater>({
    keys: Object.values(SweaterSleeve),
    dispatchFunction: updateDesign,
    designChoiceCategory: SweaterKeys.SLEEVE,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassSleeve,
    translationMapper: (key) => t(`design.designAccordion.sleeves.${key}`),
    isInvalid: (key: any) => !validSleeves(design.designChoices).includes(key),
  });

  // set information for sleeve option for key === tshirt
  const sleeveOptionsWithInfo = sleeveOptions.map((option) => {
    if (option.key === "tshirt") {
      return {
        ...option,
        information: true,
      };
    }
    return option;
  });

  return (
    <SelectionContainer>
      <SelectionOptionsBox
        options={sleeveOptionsWithInfo}
        activeOption={activeClassSleeve}
        displayType="column"
      />
    </SelectionContainer>
  );
};

export default SleeveSelectionBox;
