import { AxiosResponse } from "axios";
import { APIPath } from "types/APIPath";
import {
  CalculateHatYardage,
  CalculateSweaterYardage,
  CalculateYardageApiResponse,
} from "types/APITypes";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";

export const CalculateYardageApi = {
  post: async function (
    data: CalculateSweaterYardage | CalculateHatYardage,
  ): Promise<AxiosResponse<CalculateYardageApiResponse>> {
    return api.post(APIPath.CALCULATE_YARDAGE, data).catch((error) => {
      checkStatusAndLogApiError(APIPath.CALCULATE_YARDAGE, error, data);
      throw error;
    });
  },
};
