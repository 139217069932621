import { ColorLens } from "@mui/icons-material";
import { Link as LinkComponent, Popover } from "@mui/material";
import ColorButton from "components/Other/ColorButton/ColorButton";
import DreamknitLink from "components/Other/DreamknitLink";
import NeedleSizeMappingTable from "components/Other/NeedleSizeMapping/NeedleSizeMappingTable";
import AddYarnInputFields from "components/Yarn/AddYarn/AddYarnInputFields/AddYarnInputFields";
import PopUpWindow from "components/Yarn/AddYarn/PopUpWindow/PopUpWindow";
import { useEffect, useState } from "react";
import { ColorResult, TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";
import AppPath from "types/AppPath";
import { Language } from "types/Language";
import { COLORS } from "utils/constants/illustrationColors";
import { getLanguage } from "utils/intl/languageUtil";
import { isNorwegianAndIsBuyingYarn } from "utils/requireDeliveryAndShippingForm";
import "./SelectionBox.scss";
import { SelectionContainer } from "./SelectionContainer";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";
import { useShoppingCart } from "store/ShoppingCartContext";
import GaugeWidth from "components/Yarn/YarnInfo/GaugeWidth";
import InfoTag, { InfoTagColors } from "./InfoTag";
import useGaugeWidthAndNeedlesizeAPI from "hooks/useGaugeWidthAndNeedlesizeAPI";
import Needlesize from "components/Yarn/Needlesize";

const YarnSelectionBox = () => {
  const { design, yarn, yarnIsChosen, color, setColor } = useDesign();
  const { shoppingCart } = useShoppingCart();
  const { t } = useTranslation();
  const [showColorPicker, setShowColorPicker] = useState(false);
  const {
    gaugeWidths: calculatedGaugeWidths,
    needlesizes: calculatedNeedlesizes,
    calculateGaugeWidthsAndNeedlesizes,
  } = useGaugeWidthAndNeedlesizeAPI(yarn.gaugeWidth, yarn.needlesize);

  const {
    supplier,
    name: nameOne,
    secondName: nameTwo,
    needlesize,
    gaugeWidth,
    yardage: yardageOne,
    secondYardage: yardageTwo,
  } = yarn;

  useEffect(() => {
    if (design.patternType === ProductType.SWEATERPATTERN) return;

    const requestData = {
      gaugeWidth,
      needlesize,
    };

    calculateGaugeWidthsAndNeedlesizes(requestData);
  }, [needlesize, gaugeWidth]);

  if (
    calculatedGaugeWidths === undefined ||
    calculatedNeedlesizes === undefined
  )
    return <></>;

  const handleChangeColor = (color: ColorResult) => {
    setColor(color.hex);
    trackEvent(TrackingEvents.CLICK_ON_COLOR, { colorPickerColor: color.hex });
    handleShowColorPicker();
  };

  const handleShowColorPicker = () => {
    if (!showColorPicker) trackEvent(TrackingEvents.OPEN_SHOW_COLOR_PICKER);
    setShowColorPicker((value) => !value);
  };

  const shouldRenderColorVisualizer = () => {
    return (
      !isNorwegianAndIsBuyingYarn(shoppingCart) &&
      design.patternType !== ProductType.HATPATTERN
    );
  };

  return (
    <SelectionContainer>
      {shouldRenderColorVisualizer() && (
        <div className="visualize-color">
          <div className="visualize-color-link-container">
            <ColorLens
              fontSize="medium"
              color="primary"
              className="colorlens-icon"
              onClick={handleShowColorPicker}
            />
            <LinkComponent
              sx={{
                color: "secondary.dark",
                textDecorationColor: "secondary.dark",
              }}
              style={{ cursor: "pointer" }}
              onClick={handleShowColorPicker}
            >
              {t("design.designAccordion.yarn.visualizeColor")}
            </LinkComponent>
          </div>
          {showColorPicker && !isNorwegianAndIsBuyingYarn(shoppingCart) ? (
            <div className="colorPicker">
              <TwitterPicker
                onChange={handleChangeColor}
                color={color}
                colors={COLORS}
                triangle="hide"
              />
            </div>
          ) : null}
        </div>
      )}
      {yarnIsChosen && (
        <div className="yarnSelectionBoxInfo">
          <p>
            {t("design.designAccordion.yarn.title")}: {supplier} {nameOne}
          </p>
          <p>
            {t("design.designAccordion.yarn.yarnSelectionInfo.needlesize")}:{" "}
            <Needlesize needlesizes={calculatedNeedlesizes} />
          </p>
          <p>
            {t("design.designAccordion.yarn.yarnSelectionInfo.gauge")}:{" "}
            <GaugeWidth gaugeWidths={calculatedGaugeWidths} />
            {design.patternType === ProductType.HATPATTERN && (
              <InfoTag
                color={InfoTagColors.BLACK}
                textContent={t(
                  "design.designAccordion.yarn.yarnSelectionInfo.gaugeWidthInfo",
                )}
              />
            )}
          </p>
          <p>
            {t("design.designAccordion.yarn.yarnSelectionInfo.yardage")}:{" "}
            {yardageOne} meter
          </p>
          {nameTwo && (
            <>
              <p>
                {t(
                  "design.designAccordion.yarn.yarnSelectionInfo.heldTogetherWith",
                )}
                : {supplier} {nameTwo}
              </p>
              <p>
                {t("design.designAccordion.yarn.yarnSelectionInfo.yardage")}:{" "}
                {yardageTwo} m
              </p>{" "}
            </>
          )}
        </div>
      )}
      <SelectYarnOptions yarnIsChosen={yarnIsChosen} />
    </SelectionContainer>
  );
};

interface SelectYarnOptions {
  yarnIsChosen: boolean;
}

const SelectYarnOptions = ({ yarnIsChosen }: SelectYarnOptions) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openNeedleSizeTable = Boolean(anchorEl);
  const id = openNeedleSizeTable ? "simple-popover" : undefined;
  const mql = window.matchMedia("(max-width: 970px)");
  const mobileView = mql.matches;

  const toggleYarnInputFieldsVisibility = () => {
    setHidden(!hidden);
  };

  const handleOpenNeedleSizeTable = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNeedleSizeTable = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="yarnSelectionBoxButton"
      style={{ marginTop: 20, textAlign: "center" }}
    >
      <DreamknitLink to={AppPath.YARN}>
        <ColorButton fullWidth className="colorButton">
          {t(
            `design.designAccordion.yarn.${
              yarnIsChosen ? "changeYarn" : "chooseYarn"
            }`,
          )}{" "}
        </ColorButton>
      </DreamknitLink>
      <p style={{ lineHeight: 2 }}>{t("design.designAccordion.yarn.or")}</p>
      {mobileView ? (
        <PopUpWindow buttonText={t("yarndb.addCustomYarn") ?? ""} />
      ) : (
        <div
          className={
            hidden ? "select-yarn-button-hidden" : "select-yarn-button"
          }
        >
          <LinkComponent
            sx={{
              color: "secondary.dark",
              textDecorationColor: "secondary.dark",
              cursor: "pointer",
            }}
            onClick={toggleYarnInputFieldsVisibility}
          >
            {hidden
              ? t("yarndb.addCustomYarn")
              : t("design.designAccordion.yarn.close")}
          </LinkComponent>
          {!hidden && (
            <div
              className={
                hidden
                  ? "addCustomYarnContainerHidden"
                  : "addCustomYarnContainer"
              }
              style={{ marginTop: "15px" }}
            >
              {getLanguage() === Language.EN && (
                <>
                  <LinkComponent
                    sx={{
                      color: "secondary.dark",
                      cursor: "pointer",
                      alignSelf: "start",
                      fontSize: "0.8rem",
                    }}
                    onClick={handleOpenNeedleSizeTable}
                    underline="always"
                  >
                    Needle size guide
                  </LinkComponent>
                  <Popover
                    className="popover"
                    id={id}
                    open={openNeedleSizeTable}
                    anchorEl={anchorEl}
                    onClose={handleCloseNeedleSizeTable}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                  >
                    <NeedleSizeMappingTable />
                  </Popover>
                </>
              )}
              <div className="yarnSelectionBoxButton">
                <AddYarnInputFields />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default YarnSelectionBox;
