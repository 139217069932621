import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Language } from "types/Language";
import { getLanguage } from "utils/intl/languageUtil";

const TermsOfSale = () => {
  const { t } = useTranslation();

  function getPDFName(): string {
    switch (getLanguage()) {
      case Language.NO:
        return "/Salgsbetingelser.pdf";
      case Language.EN:
      default:
        return "/Salesconditions.pdf";
    }
  }

  return (
    <Typography>
      <Trans t={t} i18nKey="checkout.checkoutForm.termsOfSale">
        Jeg har lest og godkjent{" "}
        <a
          href={`${process.env.PUBLIC_URL}${getPDFName()}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          salgsvilkårene
        </a>
      </Trans>
    </Typography>
  );
};

export default TermsOfSale;
