import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { logError } from "utils/logging/applicationInsightsLogger";
import sortButtonStyles from "./SortButton.module.scss";

export enum AllowedSortingOrders {
  ALPHABETICAL_ASCENDING = "name",
  SUPPLIER_ASCENDING = "supplier",
  ALPHABETICAL_DESCENDING = "-name",
  SUPPLIER_DESCENDING = "-supplier",
}

// The values must be equivalent to the backend name of the field to sort by
const SORTING_OPTIONS = [
  {
    value: AllowedSortingOrders.ALPHABETICAL_ASCENDING,
    label: "sorting.alphabeticalAscending",
  },
  {
    value: AllowedSortingOrders.ALPHABETICAL_DESCENDING,
    label: "sorting.alphabeticalDescending",
  },
  {
    value: AllowedSortingOrders.SUPPLIER_ASCENDING,
    label: "sorting.supplierAscending",
  },
  {
    value: AllowedSortingOrders.SUPPLIER_DESCENDING,
    label: "sorting.supplierDescending",
  },
  // { value: "orderCount", label: "Popularity (ascending)" },
  // { value: "-orderCount", label: "Popularity (descending)" },
];

type SortButtonProps = {
  handleSortSelection: (string: AllowedSortingOrders) => void;
};

export default function SortButton({ handleSortSelection }: SortButtonProps) {
  const { t } = useTranslation();
  const [selectedSortOption, setSelectedSortOption] =
    useState<AllowedSortingOrders>(AllowedSortingOrders.SUPPLIER_ASCENDING);

  function isAllowedSortingOrder(value: any): value is AllowedSortingOrders {
    return Object.values(AllowedSortingOrders).includes(
      value as AllowedSortingOrders,
    );
  }

  const handleSelectionChange = (
    event: SelectChangeEvent<AllowedSortingOrders>,
  ) => {
    const value = event.target.value;
    if (isAllowedSortingOrder(value)) {
      setSelectedSortOption(value);
      handleSortSelection(value);
    } else {
      console.warn("Received unexpected value:", value);
      logError({
        name: "SortButtonValueError",
        message: `Received unexpected value: ${value}`,
      });
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={sortButtonStyles.container}
      sx={{ minWidth: "10em", textAlign: "left" }}
    >
      <InputLabel id="sort-label">{t("sorting.sortBy")}</InputLabel>
      <Select
        value={selectedSortOption}
        autoWidth={true}
        onChange={handleSelectionChange}
        displayEmpty
        labelId="sort-label"
        label={t("sorting.sortBy")}
        size="small"
        fullWidth={true}
      >
        {SORTING_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ minWidth: "10em" }}
          >
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
