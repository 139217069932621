import { AxiosResponse } from "axios";
import {
  CalculateGaugeWidth,
  CalculateGaugeWidthApiResponse,
} from "types/APITypes";
import { api } from "./api";
import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";

export const CalculateGaugeWidthApi = {
  post: async function (
    data: CalculateGaugeWidth,
  ): Promise<AxiosResponse<CalculateGaugeWidthApiResponse>> {
    return api.post(APIPath.CALCULATE_GAUGEWIDTH, data).catch((error) => {
      checkStatusAndLogApiError(APIPath.CALCULATE_GAUGEWIDTH, error, data);
      throw error;
    });
  },
};
