import { DesignChoices } from "types/DesignChoices";
import {
  PatternItemDetails,
  ProductType,
  ShoppingCartItem,
} from "types/ShoppingCart";

const PATTERN_EXCLUDE_FIELDS = ["skeins", "yardageMeter"] as const;
const YARN_EXCLUDE_FIELDS = ["supplier"] as const;
const SHOPPING_CART_ITEM_EXCLUDE_FIELDS = [
  "illustration",
  "productDetails",
  "price",
] as const;

// Utility function to exclude properties from an object
function excludeProperties<T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> {
  const newObj = { ...obj };
  keys.forEach((key) => delete newObj[key]);
  return newObj;
}

// Extract design details that are relevant for checkout
function getRelevantDesignDetails<T extends DesignChoices>(
  patternDetails: PatternItemDetails<T>,
) {
  return excludeProperties(patternDetails.pattern, ...PATTERN_EXCLUDE_FIELDS);
}

// Extract yarn details that are relevant for checkout
function getRelevantYarnDetails<T extends DesignChoices>(
  patternDetails: PatternItemDetails<T>,
) {
  return excludeProperties(patternDetails.yarn, ...YARN_EXCLUDE_FIELDS);
}

// Extract shopping cart item details that are relevant for checkout
function getRelevantShoppingCartItemDetails(item: ShoppingCartItem) {
  return excludeProperties(item, ...SHOPPING_CART_ITEM_EXCLUDE_FIELDS);
}

export function mapShoppingCartForCheckout(items: ShoppingCartItem[]) {
  const mappedShoppingCart = items.flatMap((item) => {
    if (item.productTypeId !== ProductType.YARN) {
      const patternDetails = item.productDetails;
      return {
        shoppingCartItem: {
          ...getRelevantShoppingCartItemDetails(item),
          quantity: 1,
          productDetails: {
            ...getRelevantDesignDetails<DesignChoices>(patternDetails),
            ...getRelevantYarnDetails<DesignChoices>(patternDetails),
            language: patternDetails.language.toLowerCase(),
          },
        },
      };
    } else if (item.productTypeId === ProductType.YARN) {
      const shoppingCartItems: Array<{}> = [];

      const variantYarnShoppingCartItem = {
        shoppingCartItem: {
          ...getRelevantShoppingCartItemDetails(item),
          productId: item.productDetails.variantYarn.productId,
          quantity: item.productDetails.variantYarn.quantity,
        },
      };
      shoppingCartItems.push(variantYarnShoppingCartItem);

      if ("secondVariantYarn" in item.productDetails) {
        const secondVariantYarnShoppingCartItem = {
          shoppingCartItem: {
            ...getRelevantShoppingCartItemDetails(item),
            productId: item.productDetails.secondVariantYarn!.productId,
            quantity: item.productDetails.secondVariantYarn!.quantity,
          },
        };
        shoppingCartItems.push(secondVariantYarnShoppingCartItem);
      }

      return shoppingCartItems;
    } else {
      throw new Error("Invalid product type in shopping cart");
    }
  });

  return mappedShoppingCart;
}
