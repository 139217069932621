import { Checkbox } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DkEmailInputField from "components/Other/Form/DkEmailInputField";
import DkRadioButtonGroup from "components/Other/Form/DkRadioButtonGroup";
import { Newsletter } from "components/checkout/Newsletter";
import OrderSummary from "components/checkout/OrderSummary";
import TermsOfSale from "components/checkout/TermsOfSale";
import DeliveryOption from "components/checkout/delivery/DeliveryOption";
import ShippingInformationForm from "components/checkout/delivery/ShippingInformationForm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import AppPath from "types/AppPath";
import { DeliveryMethodOptions } from "types/Checkout";
import { CheckoutInitiateApi } from "utils/api/CheckoutInitiateApi";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import { mapShoppingCartForCheckout } from "utils/mappers/mapShoppingCartForCheckout";
import { validateEmail } from "utils/regexValidators";
import { requiresDeliveryAndShippingForm } from "utils/requireDeliveryAndShippingForm";
import { SessionStoragePatternId } from "utils/shoppingCartUtils";
import { useCheckout } from "../../components/checkout/CheckoutContext";
import CheckoutForm from "../../components/checkout/CheckoutForm";
import "./CheckoutView.scss";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

export default function App() {
  const {
    clientSecret,
    updateClientSecret,
    updateOrderId,
    updateOriginalValue,
    updateCurrentValue,
    currentValue,
    shippingPrice,
    updateShippingPrice,
  } = useCheckout();
  const { shoppingCart, updateShoppingCartPrices } = useShoppingCart();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasRendered, setHasRendered] = useState(false);

  const pushToHistory = useDreamknitHistory();

  useEffect(() => {
    const data = {
      shoppingCart: mapShoppingCartForCheckout(shoppingCart.items),
    };
    CheckoutInitiateApi.post(data)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          updateClientSecret(data.clientSecret);
          updateOrderId(data.orderId);
          updateShippingPrice(data.potentialShippingPrice);
          updateOriginalValue(data.totalPrice);
          updateCurrentValue(data.totalPrice);
          const shoppingCartItems = Object.values(data.shoppingCart);
          updateShoppingCartPrices(shoppingCartItems);
        }
      })
      .catch((err) => {
        pushToHistory({ pathname: AppPath.SWEATER });
      })
      .finally(() => {
        setIsLoading(false);
        setHasRendered(true);
      });
  }, []);

  const options = {
    clientSecret,
  };

  window.onbeforeunload = function () {
    SessionStoragePatternId.remove();
  };

  return (
    <div className="checkout-container">
      <div className="order-summary-section">
        <OrderSummary renderSpinner={isLoading} />
      </div>

      <div className="delivery-info-and-payment-section">
        <div className="colored-background">
          <div className="delivery-info-section">
            <div className="delivery-info-container">
              <h2 className="delivery-title">
                {t("checkout.checkoutForm.shipping.shippingInformation")}
              </h2>
              {requiresDeliveryAndShippingForm(shoppingCart) ? (
                <>
                  <DeliveryAndShippingForm
                    shippingPrice={shippingPrice}
                    currentValue={currentValue}
                  />
                </>
              ) : (
                <>
                  <label htmlFor="email" />
                  <EmailInput />
                </>
              )}
              <CheckboxSection />
            </div>
          </div>
          <div className="payment-section">
            {clientSecret && hasRendered && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm renderSpinner={isLoading} />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface DeliveryAndShippingFormProps {
  shippingPrice: number;
  currentValue: number;
}

const DeliveryAndShippingForm = (props: DeliveryAndShippingFormProps) => {
  const { shippingPrice, currentValue } = props;
  const { t } = useTranslation();
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    DeliveryMethodOptions.MAIL,
  );

  const deliveryOptions = [
    {
      value: DeliveryMethodOptions.MAIL,
      label: (
        <DeliveryOption
          title={t("checkout.checkoutForm.shipping.inMail")}
          deliveryDescription={t(
            "checkout.checkoutForm.shipping.inMailDescription",
          )}
          deliveryDescriptionSecondary={t(
            "checkout.checkoutForm.shipping.inMailDeliveryInformation",
          )}
          cost={t("checkout.checkoutForm.shipping.inMailPrice", {
            shippingPrice,
          })}
        />
      ),
      labelTitle: <p>{t("checkout.checkoutForm.shipping.inMail")}</p>,
    },
    // {
    //   value: DeliveryMethodOptions.PICKUP,
    //   label: (
    //     <DeliveryOption
    //       title={t("checkout.checkoutForm.shipping.pickUp")}
    //       deliveryDescription={t(
    //         "checkout.checkoutForm.shipping.pickUpDescription",
    //       )}
    //       deliveryDescriptionSecondary={t(
    //         "checkout.checkoutForm.shipping.pickUpDeliveryInformation",
    //       )}
    //       cost={t("checkout.checkoutForm.shipping.pickUpPrice")}
    //     />
    //   ),
    //   labelTitle: <p>{t("checkout.checkoutForm.shipping.pickUp")}</p>,
    // },
  ];

  return (
    <>
      <DkRadioButtonGroup
        options={deliveryOptions}
        setSelectedValue={setSelectedDeliveryMethod}
        defaultValue={DeliveryMethodOptions.MAIL}
        id="checkout-delivery-form-radio-group"
      />
      <ShippingInformationForm id="checkout-delivery-form-text-group" />
    </>
  );
};

const EmailInput = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  return (
    <DkEmailInputField
      value={email}
      isOutlined={false}
      backgroundColor="#fff"
      label={t("checkout.checkoutForm.emailAddress")}
      id="shipping-information-user-email"
      onChange={(e) => setEmail(e.target.value)}
      errorCheck={true}
      validator={validateEmail}
      style={{ width: "95%", marginTop: "1rem" }}
      InputLabelProps={{
        sx: {
          top: "-5px",
        },
      }}
    />
  );
};

const CheckboxSection = () => (
  <div
    style={{
      display: "flex",
      alignItems: "flex-start",
      alignSelf: "flex-start",
      flexDirection: "column",
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        alignSelf: "flex-start",
      }}
    >
      <Checkbox
        color="primary"
        id="shipping-information-accept-terms"
        sx={{ paddingLeft: 0 }}
      />
      <TermsOfSale />
    </div>

    <div
      style={{
        display: "flex",
        alignItems: "center",
        alignSelf: "flex-start",
      }}
    >
      <Checkbox
        color="primary"
        id="shipping-information-newsletter"
        sx={{ paddingLeft: 0 }}
      />
      <Newsletter />
    </div>
  </div>
);
