import { useEffect, useState } from "react";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import "./ConstructionSelectionBox.scss"; // TODO REMOVE
import "./FitSelectionBox.scss"; // TODO REMOVE
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { HandleClickHatData } from "components/Design/DesignAccordion/HatAccordion";
import { Hat } from "types/DesignChoices";
import {
  HatBodyStructure,
  HatBrim,
  HatBrimStructure,
  HatKeys,
} from "types/DesignChoices/Hat";
import { validHatBrimStructures, validHatBrims } from "utils/designUtils";

const BrimSelectionBox = (props: { handleClick: HandleClickHatData }) => {
  const { design, updateDesign } = useDesign();

  const useEffectDependencies = [
    HatKeys.BRIM in design.designChoices ? design.designChoices.brim : "",
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : "",
    HatKeys.BRIM_STRUCTURE in design.designChoices
      ? design.designChoices.brimStructure
      : "",
  ];

  const [activeClassBrim, setActiveClassBrim] = useState(
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : "",
  );

  const [activeClassBrimStructure, setActiveClassBrimStructure] = useState(
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : "",
  );

  useEffect(() => {
    if (design.patternType === ProductType.HATPATTERN) {
      setActiveClassBrim(design.designChoices.brim);
      setActiveClassBrimStructure(design.designChoices.brimStructure);

      if (
        design.designChoices.bodyStructure === HatBodyStructure.STOCKINETTE &&
        design.designChoices.brimStructure === HatBrimStructure.STOCKINETTE &&
        design.designChoices.brim === HatBrim.SINGLE
      ) {
        updateDesign<Hat, HatKeys.BRIM>(HatKeys.BRIM, HatBrim.NO);
      }

      if (design.designChoices.bodyStructure === HatBodyStructure.ONE_BY_ONE) {
        updateDesign<Hat, HatKeys.BRIM_STRUCTURE>(
          HatKeys.BRIM_STRUCTURE,
          HatBrimStructure.ONE_BY_ONE,
        );
      } else if (
        design.designChoices.bodyStructure === HatBodyStructure.TWO_BY_TWO
      ) {
        updateDesign<Hat, HatKeys.BRIM_STRUCTURE>(
          HatKeys.BRIM_STRUCTURE,
          HatBrimStructure.TWO_BY_TWO,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);

  const brimOptions = createSelectionOptions<Hat>({
    keys: Object.values(HatBrim),
    dispatchFunction: updateDesign,
    designChoiceCategory: HatKeys.BRIM,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassBrim,
    translationMapper: (key) => mapKeyToTranslationKey(key, HatKeys.BRIM),
    isInvalid: (key: any) =>
      !validHatBrims(design.designChoices as Hat).includes(key),
  });

  const brimStructureOptions = createSelectionOptions<Hat>({
    keys: Object.values(HatBrimStructure),
    dispatchFunction: updateDesign,
    designChoiceCategory: HatKeys.BRIM_STRUCTURE,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassBrimStructure,
    translationMapper: (key) =>
      mapKeyToTranslationKey(key, HatKeys.BRIM_STRUCTURE),
    isInvalid: (key: any) =>
      !validHatBrimStructures(design.designChoices as Hat).includes(key),
  });

  return (
    <SelectionContainer>
      <SelectionOptionsBox
        options={brimOptions}
        activeOption={activeClassBrim}
        displayType="column"
      />
      <SelectionOptionsBox
        options={brimStructureOptions}
        activeOption={activeClassBrimStructure}
        displayType="column"
      />
    </SelectionContainer>
  );
};

export default BrimSelectionBox;
