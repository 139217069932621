import { CircularProgress } from "@mui/material";
import spinnerStyles from "./Spinner.module.scss";

interface SpinnerProps {
  color?: "primary" | "secondary" | "white" | "black";
}

const Spinner = ({ color }: SpinnerProps) => {
  return (
    <div className={spinnerStyles["spinner-container"]}>
      <CircularProgress style={{ color: color }} />
    </div>
  );
};

export default Spinner;
