function generateAndTestRegex(pattern: string | RegExp, candidate: string) {
  const regexp = new RegExp(pattern);
  return regexp.test(candidate);
}

export function validateEmail(email: string) {
  //eslint-disable-next-line
  return generateAndTestRegex(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    email,
  );
}

export function validateName(name: string) {
  return generateAndTestRegex(/^([^0-9]*)$/, name);
}

export function validateZipcode(zipCode: string) {
  return generateAndTestRegex(/^\d{4}$/, zipCode);
}

export function validatePhoneNumber(phoneNumber: string) {
  // ty til https://www.epinova.no/folg-med/blogg/2020/regex-huskeliste-for-norske-formater-i-episerver-forms/
  return generateAndTestRegex(/^((\+47)?)[4|9]\d{7}$/, phoneNumber);
}
