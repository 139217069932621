interface DeliveryOptionProps {
  title: string;
  deliveryDescription: string;
  deliveryDescriptionSecondary: string;
  cost: string;
}

const DeliveryOption = ({
  title,
  deliveryDescription,
  deliveryDescriptionSecondary,
  cost,
}: DeliveryOptionProps) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        lineHeight: "1",
      }}
    >
      <div>
        <div className="text-m bold">{title}</div>
        <p className="text-m">{deliveryDescription}</p>
        <p className="text-s">{deliveryDescriptionSecondary}</p>
      </div>
      <div>
        <div
          className="text-m bold"
          style={{ minWidth: "50px", textAlign: "right" }}
        >
          {cost}
        </div>
      </div>
    </div>
  );
};

export default DeliveryOption;
