import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./FilterButton.scss";

interface FilterButtonProps {
  toggleShowFilterControl: () => void;
}

export default function FilterButton({
  toggleShowFilterControl,
}: FilterButtonProps) {
  const { t } = useTranslation();
  return (
    <Button
      disableRipple
      variant="outlined"
      className="filterButton"
      onClick={toggleShowFilterControl}
    >
      <p>{t("yarndb.filterSection.filter")}</p>
    </Button>
  );
}
