import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const CONNECTION_STRING =
  process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;

const browserHistory = createBrowserHistory({ basename: "" });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
  config: {
    connectionString: CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

export { appInsights };
