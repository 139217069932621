import { Typography } from "@mui/material";
import { Yarn } from "types/Yarn";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import { useTranslation } from "react-i18next";
import "./YarnCard.scss";
import AppPath from "types/AppPath";
import { Folders } from "types/APIPath";

interface YarnCardProps {
  yarnData: Yarn;
}

export default function YarnCard(props: YarnCardProps) {
  const { t } = useTranslation();
  const { yarnId, name, secondName, imagePath, popular, supplier, numColors } =
    props.yarnData;
  const pushToHistory = useDreamknitHistory();

  const handleClick = () => {
    pushToHistory({ pathname: `${AppPath.YARN}/${yarnId}` });
  };

  return (
    <div className="yarn-card" onClick={handleClick}>
      <img
        src={`${process.env.PUBLIC_URL}${Folders.YARNS}/${imagePath}`}
        alt="yarn card"
      />
      {popular && (
        <div className="yarn-card-tags-container">
          <Typography>{t("yarndb.yarnCardSection.popular")}</Typography>
        </div>
      )}
      <div className="yarn-card-info">
        <Typography sx={{ fontSize: "0.875rem" }}>{supplier}</Typography>
        <Typography>
          {name} {secondName ? `+ ${secondName}` : ""}
        </Typography>
        <div className="yarn-card-info-color-price-container">
          {numColors && (
            <Typography sx={{ fontSize: "0.875rem" }}>
              {numColors} {t("yarndb.yarnCardSection.colors")}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
