// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import Logo from "assets/logo_dreamknit.png";
import { Turn as Hamburger } from "hamburger-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { useShoppingCart } from "store/ShoppingCartContext";
import AppPath from "types/AppPath";
import { Language } from "types/Language";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import { getUrlLanguage } from "utils/intl/languageUtil";
import {
  handleLanguageCookie,
  languageCodes,
  pathLanguageCodes,
  updatePathnameForLanguage,
} from "utils/languagePathUtils";
import LanguageDropdown from "./Dropdown/LanguageDropdown";
import "./NavBar.scss";
import { Tooltip } from "@mui/material";

interface navLinkInterface {
  Design: string[];
  Shop: string[];
  // GiftCard: string[];
  Video: string[];
  AboutUs: string[];
}

interface languagesInterface {
  en: string[];
  no: string[];
}

interface shoppingCartDisplayInterface {
  ShoppingCart: any;
}
export type NavLinkTypes =
  | navLinkInterface
  | languagesInterface
  | shoppingCartDisplayInterface;

const Navbar = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const [click, setClick] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(
    getUrlLanguage(history.location.pathname),
  );

  const { setIsOpen: setIsShoppingCartOpen, getShoppingCartSize } =
    useShoppingCart();

  const pushToHistory = useDreamknitHistory();
  // TODO: rename this to something more descriptive
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const shoppingCartSize = getShoppingCartSize();
  const [redirectPath, setRedirectPath] = useState<string>();

  // Remove after the solution has been tested
  // function setCookie(name, value, days) {
  //   var expires = "";
  //   if (days) {
  //     var date = new Date();
  //     date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //     expires = "; expires=" + date.toUTCString();
  //   }
  //   document.cookie = name + "=" + (value || "") + expires;
  // }
  // const shopLocale = "nb";
  // setCookie("language", shopLocale, 7); // Cookie expires in 7 days
  // console.log("Locale set in cookie:", shopLocale);

  useEffect(() => {
    handleLanguageCookie(setLanguageAndGetPathname, setRedirectPath);
    const urlLang = getUrlLanguage(history.location.pathname);
    setActiveLanguage(urlLang);

    i18n.changeLanguage(urlLang === Language.EN ? "en" : "nb");
  }, [redirectPath]);

  const navLinks: navLinkInterface = {
    Design: [t("navbar.designTool"), t("navbar.designToolUrl")],
    Shop: [t("navbar.shop"), t("navbar.shopUrl")],
    // GiftCard: [t("navbar.giftcard"), t("navbar.giftcardUrl")],
    Video: [t("navbar.videos"), "https://www.youtube.com/@dreamknit"],
    AboutUs: [t("navbar.aboutUs"), t("navbar.aboutUsUrl")],
  };

  const isInCheckoutView = history.location.pathname.includes(AppPath.CHECKOUT);

  const languages = [Language.EN, Language.NO];
  function renderRedirect() {
    if (redirectPath === undefined) return;
    return <Redirect to={redirectPath} />;
  }

  function setLanguageAndGetPathname(lang: Language) {
    // Mapping for language to their code for i18n

    const langCode = languageCodes[lang] || "en";
    i18n.changeLanguage(langCode);
    setActiveLanguage(lang);

    return updatePathnameForLanguage(
      history.location.pathname + history.location.search,
      pathLanguageCodes[lang],
    );
  }

  const openShoppingCart = () => {
    if (shoppingCartSize > 0) setIsShoppingCartOpen(true);
  };

  return (
    <div>
      <div className={click ? "main-container" : ""} onClick={() => Close()} />
      <nav className="navbar" onClick={(e) => e.stopPropagation()}>
        <div className="nav-container">
          <div className="nav-icon" onClick={handleClick}>
            <Hamburger toggled={click} size={24} />
          </div>
          <div className="nav-upper nav-items-desktop">
            <div className="logo">
              <a href="https://www.dreamknit.com" className="nav-logo">
                <img src={Logo} alt="logo" />
                <i className="fa fa-code"></i>
              </a>
            </div>
            {redirectPath !== undefined && renderRedirect()}
            <div className="language-picker">
              <LanguageDropdown
                languages={languages}
                activeLanguage={activeLanguage}
                setLanguageAndGetPathname={setLanguageAndGetPathname}
              />
            </div>
            {!isInCheckoutView && (
              <div className="shopping-cart" style={{ cursor: "pointer" }}>
                <ShoppingCartTooltip>
                  <a onClick={openShoppingCart}>
                    <ShoppingBagOutlinedIcon fontSize="large"></ShoppingBagOutlinedIcon>
                  </a>
                </ShoppingCartTooltip>
              </div>
            )}
          </div>
          <div className="nav-items-mobile">
            <a href="https://www.dreamknit.com">
              <img
                src={Logo}
                alt="logo"
                style={{ height: "24px", paddingTop: "10px" }}
              />
              <i className="fa fa-code"></i>
            </a>
          </div>
          <div className="shoppingCartIcon">
            <ShoppingCartTooltip>
              <a className="nav-links" onClick={openShoppingCart}>
                <ShoppingBagOutlinedIcon fontSize="large"></ShoppingBagOutlinedIcon>
              </a>
            </ShoppingCartTooltip>
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {Object.entries(navLinks).map(([key, value]) => {
              const title = value[0];
              const href = value[1];
              const getLinkElementProps = () => {
                switch (key) {
                  case "Design":
                    return {
                      onClick: () => {
                        if (click) handleClick();
                        pushToHistory({
                          pathname: AppPath.BASE,
                          search: window.location.search,
                        });
                      },
                    };
                  case "Video":
                    return {
                      href,
                      target: "_blank",
                      rel: "noopener",
                    };
                  default:
                    return {
                      href,
                    };
                }
              };

              return (
                <li className="nav-item" key={key}>
                  <a className="nav-links" key={key} {...getLinkElementProps()}>
                    {title}
                  </a>
                </li>
              );
            })}

            <div className="nav-items-mobile">
              <li className="nav-item">
                <LanguageDropdown
                  languages={languages}
                  activeLanguage={activeLanguage}
                  setLanguageAndGetPathname={setLanguageAndGetPathname}
                />
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </div>
  );
};

const ShoppingCartTooltip = ({ children }: { children: React.ReactNode }) => {
  const { getShoppingCartSize } = useShoppingCart();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  if (getShoppingCartSize() > 0) return <>{children}</>;

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <Tooltip
      title={t("navbar.shoppingCartTooltip")}
      open={show}
      onMouseLeave={handleClose}
      onClick={handleOpen}
      disableHoverListener
      arrow
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default Navbar;
