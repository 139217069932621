import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

interface AccordionSummaryProps {
  expandIcon: JSX.Element;
  elevation: number;
  key: string;
  children: JSX.Element;
}

export const AccordionSummary = (props: AccordionSummaryProps) => (
  <StyledAccordionSummary {...props} />
);
const StyledAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "4rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  border: 0,
  backgroundColor: "#eff3f4",
  color: theme.palette.primary.contrastText,
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

interface AccordionDetailsProps {
  elevation: number;
  key: string;
  children: JSX.Element;
}

export const AccordionDetails = (props: AccordionDetailsProps) => (
  <StyledAccordionDetails {...props} />
);
const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: 0,
}));
