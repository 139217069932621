import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, Popover } from "@mui/material";
import { useState } from "react";
import ColorButton from "components/Other/ColorButton/ColorButton";
import AddYarnInputFields from "../AddYarnInputFields/AddYarnInputFields";
import NeedleSizeMappingTable from "components/Other/NeedleSizeMapping/NeedleSizeMappingTable";
import { useTranslation } from "react-i18next";
import { getLanguage } from "utils/intl/languageUtil";
import { Language } from "types/Language";

interface PopUpWindowProps {
  buttonText: string;
  className?: string;
}

const PopUpWindow = (props: PopUpWindowProps) => {
  const { buttonText } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenNeedleSizeTable = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNeedleSizeTable = () => {
    setAnchorEl(null);
  };

  const openNeedleSizeTable = Boolean(anchorEl);
  const id = openNeedleSizeTable ? "simple-popover" : undefined;

  return (
    <div className={props.className}>
      <Link
        sx={{
          color: "secondary.dark",
          textDecorationColor: "secondary.dark",
        }}
        onClick={handleClickOpen}
      >
        {buttonText}
      </Link>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("yarndb.addCustomYarn")}</DialogTitle>
        <DialogContent>
          {getLanguage() === Language.EN && (
            <>
              <Link
                sx={{
                  color: "primary.dark",
                  cursor: "pointer",
                  marginBottom: "10px",
                  marginLeft: "15px",
                  fontSize: "0.8rem",
                }}
                onClick={handleOpenNeedleSizeTable}
                underline="always"
              >
                Needle size guide
              </Link>
              <Popover
                className="popover"
                id={id}
                open={openNeedleSizeTable}
                anchorEl={anchorEl}
                onClose={handleCloseNeedleSizeTable}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <NeedleSizeMappingTable />
              </Popover>
            </>
          )}
          <AddYarnInputFields handleClose={handleClose} />
          <ColorButton
            sx={{ marginRight: 4, marginLeft: 0, marginTop: 1 }}
            onClick={handleClose}
            fullWidth
          >
            {t("design.designAccordion.yarn.goBack")}
          </ColorButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PopUpWindow;
