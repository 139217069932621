import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useState,
} from "react";

interface CheckoutContextProps {
  currentValue: number;
  updateCurrentValue: (value: number) => void;
  clientSecret: string;
  updateClientSecret: (value: string) => void;
  orderId: string;
  updateOrderId: (value: string) => void;
  originalValue: number;
  updateOriginalValue: (value: number) => void;
  promoCode: string;
  updatePromoCode: (promoCode: string) => void;
  shippingPrice: number;
  updateShippingPrice: (amount: number) => void;
  amountCommitted: number;
  updateAmountCommitted: (amountCommitted: number) => void;
}

const CheckoutContext = React.createContext<CheckoutContextProps>(
  {} as CheckoutContextProps,
);

export function useCheckout(): CheckoutContextProps {
  return useContext(CheckoutContext);
}

export function CheckoutProvider({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const [currentValue, setCurrentValue] = useState<number>(75);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [orderId, setOrderId] = useState<string>("");
  const [originalValue, setOriginalValue] = useState<number>(75);
  const [shippingPrice, setShippingPrice] = useState<number>(89);
  const [promoCode, setPromoCode] = useState<string>("");
  const [amountCommitted, setAmountCommitted] = useState<number>(0);

  function updateAmountCommitted(amountCommitted: number) {
    setAmountCommitted(amountCommitted);
  }

  function updatePromoCode(promoCode: string) {
    setPromoCode(promoCode);
  }

  function updateCurrentValue(amount: number) {
    if (amount >= 0) {
      setCurrentValue(amount);
    }
  }

  function updateClientSecret(secret: string) {
    setClientSecret(secret);
  }

  function updateOrderId(id: string) {
    setOrderId(id);
  }

  function updateOriginalValue(amount: number) {
    if (amount >= 0) {
      setOriginalValue(amount);
    }
  }

  function updateShippingPrice(amount: number) {
    setShippingPrice(amount);
  }

  return (
    <CheckoutContext.Provider
      value={{
        currentValue,
        updateCurrentValue,
        clientSecret,
        updateClientSecret,
        orderId,
        updateOrderId,
        originalValue,
        updateOriginalValue,
        promoCode,
        updatePromoCode,
        shippingPrice,
        updateShippingPrice,
        amountCommitted,
        updateAmountCommitted,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
