import { VariantYarn, VariantYarnCollection } from "types/Yarn";
import ColorCircle from "./ColorCircle";

interface ColorCircleContainerProps {
  hasSecondaryYarn: boolean;
  variantYarnData: VariantYarnCollection | undefined;
  selectedVariantYarnMain: VariantYarn | undefined;
  setSelectedVariantYarnMain: React.Dispatch<
    React.SetStateAction<VariantYarn | undefined>
  >;
  setSelectedVariantYarnSecondary: React.Dispatch<
    React.SetStateAction<VariantYarn | undefined>
  >;
}

const ColorCircleContainer = (props: ColorCircleContainerProps) => {
  const {
    hasSecondaryYarn,
    variantYarnData,
    selectedVariantYarnMain,
    setSelectedVariantYarnMain,
    setSelectedVariantYarnSecondary,
  } = props;
  const Circles: JSX.Element[] = [];
  type VariantYarnCombination = { color: string; outOfStock: boolean };
  const variantYarnCombinations: VariantYarnCombination[] = [];

  const setSelectedVariantYarns = (
    variantYarnMain: any,
    variantYarnSecondary?: any,
  ) => {
    setSelectedVariantYarnMain(variantYarnMain);
    variantYarnSecondary !== undefined &&
      setSelectedVariantYarnSecondary(variantYarnSecondary);
  };

  // Find all colors
  variantYarnData?.variants.forEach((variant) => {
    const color = variant.color;
    const combination = variantYarnCombinations.find(
      (combination) => combination.color === color,
    );

    // If color not in array yet, add it to the array
    if (combination === undefined) {
      variantYarnCombinations.push({ color, outOfStock: !variant.available });
    } else if (hasSecondaryYarn && !combination.outOfStock) {
      // If color in array and available, but this variant yarn is unavailable:
      // --> set this combination to be out of stock.
      if ((!variant.main && !variant.available) || !variant.available)
        combination.outOfStock = true;
    }
  });

  // For each color, create a color circle
  variantYarnCombinations.forEach((combination) => {
    const isActive = selectedVariantYarnMain?.color === combination.color;
    const mainVariant = variantYarnData?.variants.find(
      (variant) => variant.main && variant.color === combination.color,
    );
    const secondaryVariant = variantYarnData?.variants.find(
      (variant) => !variant.main && variant.color === combination.color,
    );
    Circles.push(
      <ColorCircle
        color={combination.color}
        active={isActive}
        outOfStock={combination.outOfStock}
        clickable={true}
        onClick={() => setSelectedVariantYarns(mainVariant, secondaryVariant)}
        key={combination.color}
      />,
    );
  });

  return <>{Circles}</>;
};

export default ColorCircleContainer;
