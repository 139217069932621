import { useState } from "react";
import {
  CalculateGaugeWidth,
  CalculateGaugeWidthApiResponse,
} from "types/APITypes";
import { CalculateGaugeWidthApi } from "utils/api/CalculateGaugeWidthApi";

export interface GaugeWidths {
  stockinette: {
    gaugeWidth: number;
  };
  rib?: {
    gaugeWidth: number;
  };
}

export interface Needlesizes {
  stockinette: {
    needlesize: number;
  };
  rib?: {
    needlesize: number;
  };
}

const useGaugeWidthAndNeedlesizeAPI = (
  designGaugeWidth?: number,
  designNeedlesize?: number,
) => {
  const [gaugeWidthsAndNeedlesizes, setGaugeWidthsAndNeedlesizes] =
    useState<CalculateGaugeWidthApiResponse>();

  const calculateGaugeWidthsAndNeedlesizes = (data: CalculateGaugeWidth) => {
    if (data.gaugeWidth <= 0 || data.needlesize <= 0) return;
    CalculateGaugeWidthApi.post(data)
      .then((res) => res.data)
      .then(setGaugeWidthsAndNeedlesizes);
  };

  if (designGaugeWidth === undefined || designNeedlesize === undefined)
    return {
      gaugeWidths: undefined,
      needlesizes: undefined,
      calculateGaugeWidthsAndNeedlesizes,
    };

  const getGaugeWidths = (): GaugeWidths => {
    if (gaugeWidthsAndNeedlesizes === undefined)
      return { stockinette: { gaugeWidth: designGaugeWidth } };

    return {
      stockinette: {
        gaugeWidth: gaugeWidthsAndNeedlesizes.stockinette.gaugeWidth,
      },
      rib: {
        gaugeWidth: gaugeWidthsAndNeedlesizes.rib.gaugeWidth,
      },
    };
  };

  const getNeedlesizes = (): Needlesizes => {
    if (gaugeWidthsAndNeedlesizes === undefined)
      return { stockinette: { needlesize: designNeedlesize } };

    return {
      stockinette: {
        needlesize: gaugeWidthsAndNeedlesizes.stockinette.needlesize,
      },
      rib: {
        needlesize: gaugeWidthsAndNeedlesizes.rib.needlesize,
      },
    };
  };

  return {
    gaugeWidths: getGaugeWidths(),
    needlesizes: getNeedlesizes(),
    calculateGaugeWidthsAndNeedlesizes,
  };
};

export default useGaugeWidthAndNeedlesizeAPI;
