interface YarnTitleAndSupplierProps {
  supplier: string;
  yarnName: string;
  yarnPrice?: number;
  yarnTwoName?: string;
  yarnTwoPrice?: number;
  colorName?: string;
}

const YarnTitleAndSupplier = (props: YarnTitleAndSupplierProps) => {
  const {
    supplier,
    yarnName,
    yarnPrice,
    yarnTwoName,
    yarnTwoPrice,
    colorName,
  } = props;

  return (
    <>
      <p className="supplier-name">{supplier}</p>
      <p className="yarn-name">
        <span>
          {yarnName}
          {"\u00A0"}
          {yarnTwoName !== undefined && yarnTwoName !== "" && "+"}
        </span>

        <span>
          {"\u00A0"}
          {yarnPrice ? `${yarnPrice} kr` : ""}
        </span>
      </p>
      {yarnTwoName !== undefined && (
        <p className="yarn-name">
          <span>{yarnTwoName}</span>
          <span>
            {"\u00A0"}
            {yarnTwoPrice ? `${yarnTwoPrice} kr` : ""}
          </span>
        </p>
      )}
      <p className="color-name">{colorName}</p>
    </>
  );
};

export default YarnTitleAndSupplier;
