import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { isValidElement, useState } from "react";
import Paper from "@mui/material/Paper";

export enum InfoTagColors {
  BLACK = "black",
  WHITE = "white",
}

interface InfoTagProps {
  textContent: React.ReactNode;
  color?: InfoTagColors;
}

const InfoTag = (props: InfoTagProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openDescription = Boolean(anchorEl);
  const { color, textContent } = props;

  const handleOpenDescription = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDescription = () => {
    setAnchorEl(null);
  };

  const renderContent = (content: React.ReactNode) =>
    isValidElement(content) ? content : <p>{content}</p>;

  return (
    <>
      <IconButton
        color="primary"
        onClick={handleOpenDescription}
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <InfoOutlinedIcon
          fontSize="small"
          style={{
            color: color ? color : "black",
          }}
        />
      </IconButton>
      <Popover
        id={openDescription ? "simple-popover" : ""}
        open={openDescription}
        onClose={handleCloseDescription}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Paper
          sx={{
            maxWidth: "370px",
            padding: "10px",
            fontSize: "14px",
          }}
        >
          {renderContent(textContent)}
        </Paper>
      </Popover>
    </>
  );
};

export default InfoTag;
