import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { useShoppingCart } from "store/ShoppingCartContext";
import { ProductType } from "types/ShoppingCart";
import { getLanguage } from "utils/intl/languageUtil";
import {
  SessionStoragePatternId,
  deepCompareDesignContextToShoppingCartPattern,
  findPatternItem,
  findYarnItem,
  mapProductTypeToPrice,
} from "utils/shoppingCartUtils";
import { getIllustrationForProductType } from "../../../utils/illustrationUtils";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

export interface AddToShoppingCartButtonProps {
  yarnIsChosen: boolean;
  openShoppingCart: () => void;
}

export const AddToShoppingCartButton = (
  props: AddToShoppingCartButtonProps,
) => {
  const { yarnIsChosen, openShoppingCart } = props;
  const { design, yarn } = useDesign();
  const { addPatternToShoppingCart, modifyPattern, shoppingCart } =
    useShoppingCart();
  const { t } = useTranslation();
  const [patternModified, setPatternModified] = useState(false);

  // Check if pattern has been modified
  useEffect(() => {
    const patternId = SessionStoragePatternId.get();
    if (patternModified || patternId === null) return;

    const pattern = findPatternItem(shoppingCart.items, patternId);
    if (pattern === undefined) return;

    if (
      deepCompareDesignContextToShoppingCartPattern(
        design.designChoices,
        pattern.productDetails.pattern,
      )
    )
      return;

    setPatternModified(true);
  }, [design, yarn]);

  const commitDesignChoices = () => {
    const patternId = SessionStoragePatternId.get();

    let item;
    // TODO: Generalize this, currently types do not infer type safety from Design type
    switch (design.patternType) {
      case ProductType.SWEATERPATTERN:
        item = {
          productTypeId: design.patternType,
          price: mapProductTypeToPrice(design.patternType),
          illustration: getIllustrationForProductType(design),
          productDetails: {
            yarn,
            pattern: design.designChoices,
            language: getLanguage(),
          },
        };
        break;
      case ProductType.HATPATTERN:
        item = {
          productTypeId: design.patternType,
          price: mapProductTypeToPrice(design.patternType),
          illustration: getIllustrationForProductType(design),
          productDetails: {
            yarn,
            pattern: design.designChoices,
            language: getLanguage(),
          },
        };
        break;
      default:
        break;
    }

    let itemId: number;

    if (patternId !== null) {
      itemId = patternId;
      const yarnItem = findYarnItem(shoppingCart.items, itemId);
      let itemPrice: number;

      if (yarnItem === undefined) {
        itemPrice = mapProductTypeToPrice(design.patternType);
      } else {
        itemPrice = mapProductTypeToPrice(design.patternType, true);
      }
      modifyPattern({
        ...item,
        shoppingCartItemId: itemId,
        price: itemPrice,
      });
    } else {
      itemId = addPatternToShoppingCart(item);
    }

    SessionStoragePatternId.set(itemId);

    openShoppingCart();
  };

  const getShoppingCartButtonText = () => {
    const patternId = SessionStoragePatternId.get();

    if (patternId === null) {
      return t("design.designAccordion.cartSection.addToShoppingCart");
    } else if (patternModified) {
      return t("design.designAccordion.cartSection.updatePattern");
    }
    return t("design.designAccordion.cartSection.openShoppingCart");
  };

  return (
    <Button
      size="large"
      variant="contained"
      fullWidth
      disabled={!yarnIsChosen}
      onClick={() => {
        commitDesignChoices();
        setPatternModified(false);
        trackEvent(TrackingEvents.ADD_TO_SHOPPING_CART, {
          productType: design.patternType,
        });
      }}
      sx={{
        bgColor: "primary.main",
        "&:hover": { backgroundColor: "primary.light" },
      }}
    >
      {getShoppingCartButtonText()}
    </Button>
  );
};
