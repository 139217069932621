import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import tabStyles from "./MobileTab.module.scss";

interface TabPanelProps {
  index: number;
  value: number;
  [other: string]: any;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={tabStyles["tab-panel-container"]}
    >
      {value === index && (
        <Box className={tabStyles["tab-panel-box"]}>
          <Typography sx={{ width: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
