import { Button, Container } from "@mui/material";
import HatIllustration from "components/Design/IllustrationContainer/HatIllustration";
import SweaterIllustration from "components/Design/IllustrationContainer/SweaterIllustration/SweaterIllustration";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { useShoppingCart } from "store/ShoppingCartContext";
import { Folders } from "types/APIPath";
import { Language } from "types/Language";
import {
  HatPatternItemBeforeCommit,
  ProductType,
  SweaterPatternItemBeforeCommit,
  YarnItemBeforeCommit,
  YarnItemDetails,
} from "types/ShoppingCart";
import { MainAndSecondarySkeins, VariantYarn, Yarn } from "types/Yarn";
import {
  HAT_PATTERN_PRICE_WITHOUT_YARN,
  SWEATER_PATTERN_PRICE_WITH_YARN,
} from "utils/constants/ShoppingCartConstants";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import { getLanguage } from "utils/intl/languageUtil";
import { setUrlParamsFromYarnAndDesign } from "utils/setUrlParams";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";
import {
  SessionStoragePatternId,
  conditionallyRemoveYarnItem,
} from "utils/shoppingCartUtils";
import PriceAndStockContainer from "./PriceAndStockContainer";

interface PurchasableYarnSectionProps {
  shouldRenderPurchaseSection?: boolean;
  yarnData?: Yarn;
  selectedVariantYarnMain?: VariantYarn;
  selectedVariantYarnSecondary?: VariantYarn;
  skeins?: MainAndSecondarySkeins;
}

const PurchasableYarnSection = (props: PurchasableYarnSectionProps) => {
  const {
    shouldRenderPurchaseSection = false,
    yarnData,
    selectedVariantYarnMain,
    selectedVariantYarnSecondary,
    skeins,
  } = props;
  const { t } = useTranslation();
  const {
    design,
    setYarn,
    yarn,
    setYarnIsChosen: setDesignContextYarnIsChosen,
  } = useDesign();
  const {
    addPatternAndYarnItemToShoppingCart,
    addYarnToShoppingCart,
    modifyPattern,
    removeYarnFromShoppingCart,
    shoppingCart,
  } = useShoppingCart();
  const pushToHistory = useDreamknitHistory();

  const setPatternAndYarnItem = (
    variantYarn: VariantYarn,
    skeins: MainAndSecondarySkeins,
    yarnData: Yarn,
  ) => {
    let patternItem:
      | SweaterPatternItemBeforeCommit
      | HatPatternItemBeforeCommit;
    if (design.patternType === ProductType.SWEATERPATTERN) {
      patternItem = {
        productDetails: {
          yarn,
          pattern: design.designChoices,
          language: getLanguage(),
        },
        price: SWEATER_PATTERN_PRICE_WITH_YARN,
        illustration: <SweaterIllustration sweater={design.designChoices} />,
        productTypeId: ProductType.SWEATERPATTERN,
      };
    } else if (design.patternType === ProductType.HATPATTERN) {
      patternItem = {
        productDetails: {
          yarn,
          pattern: design.designChoices,
          language: getLanguage(),
        },
        price: HAT_PATTERN_PRICE_WITHOUT_YARN,
        illustration: <HatIllustration hat={design.designChoices} />,
        productTypeId: ProductType.HATPATTERN,
      };
    } else {
      throw new Error("Pattern type not supported");
    }
    const yarnItemProductDetails: YarnItemDetails = {
      variantYarn: {
        productId: parseInt(variantYarn.id),
        quantity: skeins.main,
        originalQuantity: skeins.main,
        supplier: yarnData.supplier,
        name: variantYarn.name,
        price: variantYarn.price,
      },
    };
    let totalPrice =
      yarnItemProductDetails.variantYarn.quantity *
      yarnItemProductDetails.variantYarn.price;

    if (selectedVariantYarnSecondary !== undefined) {
      yarnItemProductDetails.secondVariantYarn = {
        productId: parseInt(selectedVariantYarnSecondary.id),
        quantity: skeins.secondary,
        originalQuantity: skeins.secondary,
        supplier: yarnData.supplier,
        name: selectedVariantYarnSecondary.name,
        price: selectedVariantYarnSecondary.price,
      };

      totalPrice +=
        yarnItemProductDetails.secondVariantYarn.quantity *
        yarnItemProductDetails.secondVariantYarn.price;
    }

    const yarnItem: YarnItemBeforeCommit = {
      productDetails: yarnItemProductDetails,
      price: totalPrice,
      illustration: (
        <img
          src={`${process.env.PUBLIC_URL}${Folders.YARNS}/${variantYarn.imagePath}`}
          alt={t("yarndb.detailedYarnView.imageAltText") ?? ""}
          className="shopping-cart-item-illustration-image"
        />
      ),
      productTypeId: ProductType.YARN,
    };

    const activePatternId = SessionStoragePatternId.get();

    if (activePatternId === null) {
      const shoppingCartItemId = addPatternAndYarnItemToShoppingCart(
        patternItem,
        yarnItem,
      );
      trackEvent(TrackingEvents.ADD_TO_SHOPPING_CART);

      return shoppingCartItemId;
    } else {
      modifyPattern({
        ...patternItem,
        shoppingCartItemId: activePatternId,
      });
      addYarnToShoppingCart({
        ...yarnItem,
        shoppingCartItemId: activePatternId,
      });

      return activePatternId;
    }
  };

  const setYarnIsChosen = (addToShoppingCart: boolean) => {
    if (yarnData === undefined) return;
    const {
      yarnId,
      name,
      secondName,
      supplier,
      needlesize,
      yardage,
      secondYardage,
      gaugeWidth,
      popular,
    } = yarnData;

    setYarn({
      yarnId,
      name,
      secondName,
      supplier,
      needlesize,
      yardage,
      secondYardage,
      gaugeWidth,
    });
    setDesignContextYarnIsChosen(true);

    const dataIsUndefined =
      selectedVariantYarnMain === undefined ||
      skeins === undefined ||
      yarnData === undefined;

    if (dataIsUndefined || !addToShoppingCart) {
      conditionallyRemoveYarnItem(
        shoppingCart.items,
        removeYarnFromShoppingCart,
      );
      setUrlParamsFromYarnAndDesign({
        yarn: yarn,
        design: design,
        pushToHistory: pushToHistory,
        yarnInfo: {
          id: yarnId ?? "",
          name: name ?? "",
        },
      });
    } else {
      const shoppingCartItemId = setPatternAndYarnItem(
        selectedVariantYarnMain,
        skeins,
        yarnData,
      );

      SessionStoragePatternId.set(shoppingCartItemId ?? 0); // TODO: Remove nullish coalescing when addPatternAndYarnItem is fixed

      setUrlParamsFromYarnAndDesign({
        yarn: yarn,
        design: design,
        pushToHistory: pushToHistory,
        yarnInfo: {
          id: yarnId ?? "",
          name: name ?? "",
        },
      });
    }
    trackEvent(TrackingEvents.CHOOSE_YARN, {
      yarnId: yarnId,
      name: name,
      secondName: secondName,
      supplier: supplier,
      needlesize: needlesize,
      yardage: yardage,
      secondYardage: secondYardage,
      gaugeWidth: gaugeWidth,
      popular: popular,
    });
  };

  return (
    <Container className="detailed-yarn-view-section purchasable-yarn-section">
      <p>{t("yarndb.detailedYarnView.yarnAmountCalculation")}</p>
      {shouldRenderPurchaseSection && getLanguage() === Language.NO ? (
        <>
          <PriceAndStockContainer
            supplier={yarnData?.supplier ?? ""}
            outOfStock={
              !selectedVariantYarnMain?.available ||
              (selectedVariantYarnSecondary !== undefined &&
                !selectedVariantYarnSecondary.available)
            }
            color={selectedVariantYarnMain?.color}
            yarnName={yarnData?.name ?? ""}
            yarnAmount={skeins?.main ?? 0}
            yarnPrice={selectedVariantYarnMain?.price ?? 0}
            yarnTwoName={yarnData?.secondName}
            yarnTwoPrice={selectedVariantYarnSecondary?.price}
            yarnTwoAmount={skeins?.secondary}
            patternType={design.patternType}
          />
          <Button
            size="small"
            variant="contained"
            color="primary"
            disabled={
              !selectedVariantYarnMain?.available ||
              (selectedVariantYarnSecondary !== undefined &&
                !selectedVariantYarnSecondary.available)
            }
            fullWidth
            className="detailed-yarn-view-button purchase-yarn-button"
            data-cy="purchase-yarn-button"
            onClick={() => setYarnIsChosen(true)}
            sx={{ "&:hover": { backgroundColor: "primary.light" } }}
          >
            {t("yarndb.detailedYarnView.purchaseYarn")}
          </Button>
          <Button
            size="small"
            variant="contained"
            fullWidth
            className="detailed-yarn-view-button purchase-pattern-button"
            data-cy="purchase-pattern-button"
            onClick={() => setYarnIsChosen(false)}
          >
            {t("yarndb.detailedYarnView.purchasePatternWithoutYarn")}
          </Button>
        </>
      ) : (
        <Button
          size="small"
          variant="contained"
          color="primary"
          fullWidth
          className="detailed-yarn-view-button"
          data-cy="purchase-pattern-button"
          onClick={() => setYarnIsChosen(false)}
          sx={{ "&:hover": { backgroundColor: "primary.light" } }}
        >
          {t("yarndb.detailedYarnView.purchasePattern")}
        </Button>
      )}
    </Container>
  );
};

export default PurchasableYarnSection;
