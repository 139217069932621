import GiftcardCheckoutForm from "./GiftcardCheckoutForm";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { useState } from "react";
import VippsCheckoutForm from "./VippsCheckoutForm";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import DkRadioButtonGroup, {
  RadioButtonOption,
} from "components/Other/Form/DkRadioButtonGroup";
import { ReactComponent as VippsMark } from "assets/VippsMark.svg";
import { getLanguage } from "utils/intl/languageUtil";
import { Language } from "types/Language";
import { useCheckout } from "./CheckoutContext";

export enum AvailablePaymentMethod {
  GIFTCARD = "giftcard",
  STRIPE = "stripe",
  VIPPS = "vipps",
  NONE = "none",
}

const Forms = (props: {
  setChosenPaymentMethod: React.Dispatch<
    React.SetStateAction<AvailablePaymentMethod>
  >;
}) => {
  const { setChosenPaymentMethod } = props;
  const { t } = useTranslation();
  const { amountCommitted } = useCheckout();

  const giftcardUsed = amountCommitted > 0;

  const paymentMethodOptions: RadioButtonOption<AvailablePaymentMethod>[] = [
    {
      value: AvailablePaymentMethod.STRIPE,
      label: (
        <div className="text-m">
          {" "}
          {t("checkout.checkoutForm.stripe.title") ?? ""}
        </div>
      ),
      children: <StripeCheckoutForm />,
    },
    {
      value: AvailablePaymentMethod.GIFTCARD,
      label: (
        <div className="text-m">
          {" "}
          {t("checkout.checkoutForm.giftcard.title") ?? ""}
        </div>
      ),
      children: <GiftcardCheckoutForm />,
      inactive: giftcardUsed,
    },
  ];

  if (getLanguage() === Language.NO) {
    paymentMethodOptions.unshift({
      value: AvailablePaymentMethod.VIPPS,
      label: (
        <div
          className="text-m"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("checkout.checkoutForm.vipps.title")}
          <VippsMark style={{ scale: "0.75" }} />
        </div>
      ),
      children: <VippsCheckoutForm />,
    });
  }

  return (
    <div className="payment-methods-forms">
      <DkRadioButtonGroup
        options={paymentMethodOptions}
        setSelectedValue={setChosenPaymentMethod}
        defaultValue={AvailablePaymentMethod.STRIPE}
        fullWidth
      />
    </div>
  );
};

interface CheckoutFormProps {
  renderSpinner: boolean;
}

export default function CheckoutForm(props: CheckoutFormProps) {
  const { renderSpinner } = props;
  const { t } = useTranslation();
  const [chosenPaymentMethod, setChosenPaymentMethod] =
    useState<AvailablePaymentMethod>(AvailablePaymentMethod.STRIPE);

  const renderCircularProgress = () => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: "2rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  return (
    <div className="payment-container">
      <div className="payment-methods-section">
        <h2 className="payment-section-title">
          {t("checkout.checkoutForm.paymentMethod")}
        </h2>
        <form onSubmit={(e) => e.preventDefault()} className="checkout-form">
          {renderSpinner ? (
            renderCircularProgress()
          ) : (
            <Forms setChosenPaymentMethod={setChosenPaymentMethod} />
          )}
        </form>
      </div>
    </div>
  );
}
