import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Location } from "history";

interface ScrollToTopProps {
  location?: Location;
}

const ScrollToTop = (props: React.PropsWithChildren<ScrollToTopProps>) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [props.location?.pathname]);

  return <>{props.children}</>;
};

export default withRouter(ScrollToTop);
