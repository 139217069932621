import GaugeWidth from "components/Yarn/YarnInfo/GaugeWidth";
import { useTranslation } from "react-i18next";
import { MainAndSecondarySkeins, Yarn } from "types/Yarn";
import yarnCharacteristicsStyles from "./yarnCharacteristics.module.scss";
import useGaugeWidthAndNeedlesizeAPI from "hooks/useGaugeWidthAndNeedlesizeAPI";
import { useEffect } from "react";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import Needlesize from "components/Yarn/Needlesize";

interface YarnCharacteristicsProps {
  yarnData?: Yarn;
  skeins?: MainAndSecondarySkeins;
}

const YarnCharacteristics = (props: YarnCharacteristicsProps) => {
  const { yarnData, skeins } = props;
  const { design } = useDesign();
  const { t } = useTranslation();
  const {
    gaugeWidths: calculatedGaugeWidths,
    needlesizes: calculatedNeedlesizes,
    calculateGaugeWidthsAndNeedlesizes,
  } = useGaugeWidthAndNeedlesizeAPI(yarnData?.gaugeWidth, yarnData?.needlesize);

  useEffect(() => {
    if (design.patternType === ProductType.SWEATERPATTERN) return;
    if (yarnData === undefined) return;

    const requestData = {
      gaugeWidth: yarnData.gaugeWidth,
      needlesize: yarnData.needlesize,
    };

    calculateGaugeWidthsAndNeedlesizes(requestData);
  }, [yarnData?.needlesize, yarnData?.gaugeWidth]);

  if (
    yarnData === undefined ||
    calculatedGaugeWidths === undefined ||
    calculatedNeedlesizes === undefined
  )
    return <></>;

  const getYardageString = (yardage?: number, secondYardage?: number) => {
    if (secondYardage === null)
      return `${yardage} ${t("genericTerms.meter_other").toLowerCase()}`;
    return `${yardage} + ${secondYardage} ${t(
      "genericTerms.meter_other",
    ).toLowerCase()}`;
  };

  const getSkeinString = (skeins?: MainAndSecondarySkeins): string => {
    if (skeins === undefined) return "";
    return `${skeins?.main}${
      skeins.secondary ? ` + ${skeins.secondary}` : ""
    } ${t("genericTerms.skein_other").toLowerCase()}`;
  };

  return (
    <div
      className={yarnCharacteristicsStyles["yarn-characteristics-container"]}
    >
      <p className={yarnCharacteristicsStyles["yarn-characteristics-row"]}>
        <span>
          {t(
            "yarndb.detailedYarnView.yarnCharacteristics.recommendedNeedlesize",
          )}
        </span>
        <Needlesize needlesizes={calculatedNeedlesizes} />
      </p>
      <p className={yarnCharacteristicsStyles["yarn-characteristics-row"]}>
        <span>{t("yarndb.detailedYarnView.yarnCharacteristics.gauge")}</span>
        <GaugeWidth gaugeWidths={calculatedGaugeWidths} />
      </p>
      <p className={yarnCharacteristicsStyles["yarn-characteristics-row"]}>
        <span>{t("yarndb.detailedYarnView.yarnCharacteristics.yardage")}</span>
        <span>
          {getYardageString(yarnData?.yardage, yarnData?.secondYardage)}
        </span>
      </p>
      <p className={yarnCharacteristicsStyles["yarn-characteristics-row"]}>
        <span>
          {t(
            "yarndb.detailedYarnView.yarnCharacteristics.calculatedYarnAmount",
          )}
        </span>
        <span>{getSkeinString(skeins)}</span>
      </p>
    </div>
  );
};

export default YarnCharacteristics;
