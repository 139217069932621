import "./SizeGuide.scss";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function createData(
  size: string,
  brystmål: string,
  normal: string,
  oversized: string,
) {
  return { size, brystmål, normal, oversized };
}

const rows = [
  createData("XS", "80-85 cm", "90 cm", "100 cm"),
  createData("S", "85-90 cm", "95 cm", "105 cm"),
  createData("M", "90-95 cm", "100 cm", "110 cm"),
  createData("L", "95-105 cm", "110 cm", "120 cm"),
  createData("XL", "105-115 cm", "120 cm", "130 cm"),
  createData("XXL", "115-125 cm", "130 cm", "140 cm"),
];

const SizeGuide = () => {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)({
    padding: 5,
    width: "25%",
  });
  const StyledTableCellSmall = styled(TableCell)({
    padding: 5,
    width: "15%",
  });

  return (
    <div className="sizeGuideContainer">
      <TableContainer className="table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCellSmall>
                {t("design.designAccordion.fit.sizeGuideTable.size")}
              </StyledTableCellSmall>
              <StyledTableCell>
                {t("design.designAccordion.fit.sizeGuideTable.bustMeasurement")}
              </StyledTableCell>
              <StyledTableCell>
                {t(
                  "design.designAccordion.fit.sizeGuideTable.bustCircumferenceRegular",
                )}
              </StyledTableCell>
              <StyledTableCell>
                {t(
                  "design.designAccordion.fit.sizeGuideTable.bustCircumferenceOversized",
                )}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.size}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCellSmall>{row.size}</StyledTableCellSmall>
                <StyledTableCell>{row.brystmål}</StyledTableCell>
                <StyledTableCell>{row.normal}</StyledTableCell>
                <StyledTableCell>{row.oversized}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SizeGuide;
