import "./../IllustrationContainer.scss";
import { useTranslation } from "react-i18next";

interface DegreeOfDifficultyDescriptionProps {
  difficulty?: number;
}

const DegreeOfDifficultyDescription = ({
  difficulty,
}: DegreeOfDifficultyDescriptionProps) => {
  const { t } = useTranslation();

  function getDifficultyDescriptionText(difficulty: number) {
    switch (difficulty) {
      case 1:
        return "oneOfFiveInfoText";
      case 2:
        return "twoOfFiveInfoText";
      case 3:
        return "threeOfFiveInfoText";
      case 4:
        return "fourOfFiveInfoText";
      case 5:
        return "fiveOfFiveInfoText";
      default:
        return null;
    }
  }

  if (difficulty === undefined) return <></>;

  return (
    <div className="difficulty-popover-container text-s">
      <p style={{ fontWeight: "bold" }}>
        {t("design.illustrationContainer.difficulty.difficultyTitle")}:{" "}
        {t("design.illustrationContainer.difficulty.difficultyNumber", {
          difficulty: difficulty,
        })}
      </p>
      <p>
        {t(
          "design.illustrationContainer.difficulty." +
            getDifficultyDescriptionText(difficulty),
        )}
      </p>
    </div>
  );
};

export default DegreeOfDifficultyDescription;
