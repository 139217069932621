import { VariantYarn, VariantYarnCollection, Yarn } from "types/Yarn";
import { api } from "./api";
import { Language } from "types/Language";
import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";

type MultiLingualInfoText = { "<NB>": string; "<EN>": string };

function toQueryString(params) {
  let queryString = "";

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      // If the value is an array, iterate over it and append each item to the query string
      value.forEach((item) => {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          item,
        )}&`;
      });
    } else {
      // If the value is not an array (e.g., a string), append it directly to the query string
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }
  });

  // Remove the last '&' from the query string
  return queryString.slice(0, -1);
}

export const YarnsApi = {
  getAll: async function () {
    return api.get(`${APIPath.GET_YARNS}/`).catch((error) => {
      checkStatusAndLogApiError(`${APIPath.GET_YARNS}/`, error, undefined);
      throw error;
    });
  },
  queryAll: async function (
    language: string,
    queryParams?: { [key: string]: string | string[] | boolean },
  ): Promise<Yarn[]> {
    const queryString = queryParams && `&${toQueryString(queryParams)}`;
    const yarnPath =
      language === "nb"
        ? `${APIPath.GET_YARNS}/?${queryString}`
        : `${APIPath.GET_YARNS}/?language=english${queryString}`;

    const yarns: Yarn[] = await api
      .get(yarnPath)
      .then((res): Yarn[] => res.data)
      .catch((error) => {
        checkStatusAndLogApiError(yarnPath, error, undefined);
        throw error;
      });
    return yarns;
  },
  getById: async function (id: number): Promise<Yarn> {
    validateYarnId(id);

    return api
      .get(`${APIPath.GET_YARNS}/${id}`)
      .then((res) => res.data as Yarn);
  },
  getVariantYarnById: async function (
    id: number,
  ): Promise<VariantYarnCollection> {
    validateYarnId(id);
    return api
      .get(`${APIPath.GET_VARIANTS}/${id}`)
      .then((res) => {
        const variantYarnBackendFormat = res.data;
        const variantYarnFrontendFormat = convertFromBackendYarnVariantFormat(
          variantYarnBackendFormat,
        );

        return variantYarnFrontendFormat;
      })
      .catch((error) => {
        checkStatusAndLogApiError(
          `${APIPath.GET_VARIANTS}/${id}`,
          error,
          undefined,
        );
        throw error;
      });
  },
};

const validateYarnId = (id: number) => {
  const isNumber = !isNaN(id);
  if (!isNumber) {
    throw new Error("Argument not a number");
  }
};

export const convertFromBackendYarnVariantFormat = (
  variantYarnCollection,
): VariantYarnCollection => {
  const variantYarns: VariantYarn[] = [];

  variantYarnCollection.variants.forEach((variantYarn) => {
    variantYarns.push({
      available: variantYarn.available,
      color: variantYarn.color,
      colorName: variantYarn.colorName,
      id: variantYarn.productId,
      imagePath: variantYarn.imagePath,
      main: variantYarn.main,
      name: variantYarn.name,
      price: variantYarn.price,
    });
  });

  return {
    fullName: variantYarnCollection.fullName,
    id: variantYarnCollection.yarnId,
    infoText: variantYarnCollection.infoText,
    name: variantYarnCollection.name,
    numberColors: variantYarnCollection.numColors,
    supplier: variantYarnCollection.supplier,
    variants: variantYarns,
  };
};

/**
 * Converts the info text from the database into the given language or
 * raises an exception if language isn't present in database
 * @param infoText from database. Can either be a string or a JSON object.
 * @param language to extract
 * @returns Info text string
 */
export const parseInfoText = (infoText: string, language: Language): string => {
  if (infoTextIsMultilingual(infoText)) {
    const infoTextObject: MultiLingualInfoText = JSON.parse(infoText);
    switch (language) {
      case Language.NO:
        return infoTextObject["<NB>"];
      case Language.EN:
        return infoTextObject["<EN>"];
      default:
        return infoTextObject["<NB>"];
    }
  }
  return language === Language.NO ? infoText : "";
};

/**
 * Checks whether info text from database is multilingual or unilingual.
 * If multilingual, parsing JSON object will succeed. Else, info text
 * is unilingual.
 * @param infoText from database. Can either be a string or a JSON object.
 * @returns true if infoText is multilingual (JSON object), false otherwise
 */
export const infoTextIsMultilingual = (infoText: string): boolean => {
  if (infoText === null) return false;
  try {
    // try to parse infoText
    JSON.parse(infoText);
    // if no error is thrown, return true
    return true;
  } catch (error) {
    // if this happens, infoText is stored as a plain string in database because
    // of unsuccessful data merge or only norwegian is stored
    // only norwegian is stored in database
    return false;
  }
};
