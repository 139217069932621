import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";

const YarnAmount = () => {
  const { design, yarn, yarnIsChosen } = useDesign();
  const { t } = useTranslation();

  const { supplier, name: nameOne, secondName: nameTwo } = yarn;
  const { skeins, yardageMeter } = design.designChoices;
  const skeinsOne = typeof skeins === "number" ? skeins : skeins[0];
  const skeinsTwo = typeof skeins === "number" ? undefined : skeins[1];

  const twoYarns = yarn.secondName ? true : false;

  const styles = {
    padding: "10px",
    position: "relative",
    zIndex: 1,
  };

  return (
    <div>
      {yarnIsChosen ? (
        <Paper className="text-s" elevation={2} sx={styles} data-cy="paper">
          <p>{t("design.illustrationContainer.yarnAmount.title")}</p>
          {twoYarns ? (
            <>
              {t(
                "design.illustrationContainer.yarnAmount.twoYarnsDescription",
                { skeins: skeinsOne, name: nameOne, supplier, yardageMeter },
              )}
              <br />
              {t(
                "design.illustrationContainer.yarnAmount.twoYarnsDescription",
                { skeins: skeinsTwo, name: nameTwo, supplier, yardageMeter },
              )}
            </>
          ) : (
            <>
              {t("design.illustrationContainer.yarnAmount.oneYarnDescription", {
                name: nameOne,
                skeins,
                yardageMeter,
                supplier,
              })}
            </>
          )}
        </Paper>
      ) : (
        <Paper elevation={2} sx={styles} data-cy="paper">
          <p>{t("design.illustrationContainer.yarnAmount.title")}</p>
          {t("design.illustrationContainer.yarnAmount.calculation")}
        </Paper>
      )}
    </div>
  );
};

export default YarnAmount;
