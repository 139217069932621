import { useShoppingCart } from "store/ShoppingCartContext";
import { Button, Drawer } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import AppPath from "types/AppPath";
import { ShoppingCartItemsAndTotalPrice } from "components/shoppingcart/ShoppingCartItemsAndTotalPrice";
import { SessionStoragePatternId } from "utils/shoppingCartUtils";
import "components/shoppingcart/ShoppingCart.scss";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

interface ShoppingCartProps {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShoppingCart = (props: ShoppingCartProps) => {
  const { open, setIsOpen } = props;

  const closeShoppingCart = () => {
    setIsOpen(false);
  };

  return (
    <Drawer open={open} onClose={closeShoppingCart} anchor="right">
      <div id="shopping-cart-wrapper">
        <div id="shopping-cart">
          <ShoppingCartItemsAndTotalPrice
            editable
            removable
            inCheckout={false}
          />
          <ButtonSection />
        </div>
      </div>
    </Drawer>
  );
};

const ButtonSection = () => {
  const { t } = useTranslation();
  const { setIsOpen } = useShoppingCart();
  const pushToHistory = useDreamknitHistory();

  return (
    <div className="shopping-cart-button-container">
      <Button
        variant="contained"
        size="small"
        sx={{ "&:hover": { backgroundColor: "primary.light" } }}
        onClick={() => {
          setIsOpen(false);
          SessionStoragePatternId.remove();
          pushToHistory({ pathname: AppPath.BASE });
          trackEvent(TrackingEvents.DESIGN_NEW_PATTERN);
        }}
      >
        <AddIcon fontSize="small" />
        {t("shoppingCart.newDesign")}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className="secondary-button"
        onClick={() => {
          setIsOpen(false);
          pushToHistory({ pathname: AppPath.CHECKOUT });
        }}
      >
        {t("shoppingCart.toCheckout")}
      </Button>
    </div>
  );
};

export default ShoppingCart;
