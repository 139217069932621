import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import { t } from "i18next";
import { classNames } from "styles/StyleUtils";
import FilterYarnSection from "../FilterYarnSection";
import FilterButton from "./FilterButton";
import searchSectionStyles from "./SearchContainer.module.scss";
import SortButton, { AllowedSortingOrders } from "./SortButton";
import { FilterDataObject } from "./YarnContainer";

interface SearchSectionProps {
  isSearching: boolean;
  showFilterControl: boolean;
  setShowFilterControl: (show: boolean) => void;
  setIsSearching: (searching: boolean) => void;
  debouncedHandleSearchStringChange: (event: any) => void;
  searchString: string;
  setSearchString: (string: string) => void;
  toggleShowFilterControl: () => void;
  handleGaugeWidthChange: (value: number[]) => void;
  filterData: FilterDataObject;
  allSuppliers: string[];
  setFilterData: (value: FilterDataObject) => void;
  setSortType: (sortType: AllowedSortingOrders) => void;
}

export default function SearchSection({
  isSearching,
  showFilterControl,
  setShowFilterControl,
  setIsSearching,
  debouncedHandleSearchStringChange,
  searchString,
  setSearchString,
  toggleShowFilterControl,
  handleGaugeWidthChange,
  filterData,
  setSortType,
  allSuppliers,
  setFilterData,
}: SearchSectionProps) {
  return (
    <div className={searchSectionStyles["search-container"]}>
      <div className={searchSectionStyles["row"]}>
        <TextField
          name="search"
          color="secondary"
          fullWidth
          placeholder={t("yarndb.filterSection.search") ?? ""}
          type="Outlined"
          value={searchString}
          sx={{ flexGrow: 1 }}
          onChange={(event) => {
            if (!isSearching) {
              setIsSearching(true);
            }
            setSearchString(event.target.value);
            debouncedHandleSearchStringChange(event);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            style: {
              fontFamily: "Brut Grotesque Book",
            },
          }}
          data-cy="searchField"
        />
      </div>
      <div
        className={classNames(
          searchSectionStyles["row"],
          searchSectionStyles["filtering-section"],
        )}
      >
        <SortButton handleSortSelection={setSortType} />
        <FilterButton toggleShowFilterControl={toggleShowFilterControl} />
      </div>
      <FilterYarnSection
        setShowFilterYarnDrawer={setShowFilterControl}
        show={showFilterControl}
        filterData={filterData}
        setFilterData={setFilterData}
        handleGaugeWidthChange={handleGaugeWidthChange}
        allSuppliers={allSuppliers}
      />
    </div>
  );
}
