import { GaugeWidths } from "hooks/useGaugeWidthAndNeedlesizeAPI";
import { useTranslation } from "react-i18next";
import { Design, useDesign } from "store/DesignContext";
import { HatBodyStructure } from "types/Accessories";
import { HatKeys } from "types/DesignChoices/Hat";

interface GaugeWidthProps {
  gaugeWidths: GaugeWidths;
}

/**
 * Component that renders the gauge width. Default gauge width in yarn is
 * calculated for stockinette stitch.
 * @param gaugeWidths: the gauge width(s) of the design
 * @returns React.ReactElement
 */
const GaugeWidth = ({ gaugeWidths }: GaugeWidthProps): React.ReactElement => {
  const { design } = useDesign();
  const { t } = useTranslation();

  const getDesignStructure = (design: Design) => {
    return HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : HatBodyStructure.STOCKINETTE;
  };

  const getGaugeWidth = (design: Design, gaugeWidths: GaugeWidths) => {
    const isStockinette =
      getDesignStructure(design) === HatBodyStructure.STOCKINETTE;
    if (isStockinette || gaugeWidths.rib === undefined)
      return gaugeWidths.stockinette.gaugeWidth;
    return gaugeWidths.rib.gaugeWidth;
  };

  const getStructureDescriptor = (design: Design) => {
    const structure = getDesignStructure(design);

    switch (structure) {
      case HatBodyStructure.STOCKINETTE:
        return t("design.designAccordion.yarn.yarnSelectionInfo.inStockinette");
      case HatBodyStructure.ONE_BY_ONE:
        return t("design.designAccordion.yarn.yarnSelectionInfo.in1x1");
      case HatBodyStructure.TWO_BY_TWO:
      default:
        return t("design.designAccordion.yarn.yarnSelectionInfo.in2x2");
    }
  };

  return (
    <span>
      {getGaugeWidth(design, gaugeWidths)}{" "}
      {t("design.designAccordion.yarn.yarnSelectionInfo.stitches")}/10 cm{" "}
      {getStructureDescriptor(design)}
    </span>
  );
};

export default GaugeWidth;
