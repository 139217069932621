import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AppPath from "types/AppPath";
import {
  getGaLinkerUrlParam,
  getOnlyGaLinkerString,
  getSearchStringWithGaLinker,
} from "utils/crossDomainTracking";
import { getLanguage } from "utils/intl/languageUtil";
import {
  pathLanguageCodes,
  updatePathnameForLanguage,
} from "utils/languagePathUtils";
import "./GoBackButton.scss";

interface GoBackButtonProps {
  to?: string;
  fallbackPath?: string;
}

/**
 *
 * @param fallbackPath path to go to if the previous page is not a Dreamknit page
 * @param to path to go to, regardless of the previous page
 *
 * @returns
 */
export default function GoBackButton({ fallbackPath, to }: GoBackButtonProps) {
  const history = useHistory();
  const { t } = useTranslation();

  const handleGoBack = () => {
    const previousPage = sessionStorage.getItem("dkLastPage");

    //Used to keep track of the previous page visited
    sessionStorage.setItem("dkLastPage", window.location.hostname);

    if (to) {
      history.push(
        updatePathnameForLanguage(to, pathLanguageCodes[getLanguage()]) +
          getOnlyGaLinkerString(),
      );
    }
    // If the previous page is not a Dreamknit page, go to the fallback path
    else if (!previousPage?.includes(window.location.hostname)) {
      history.push(
        updatePathnameForLanguage(
          fallbackPath ?? AppPath.BASE,
          pathLanguageCodes[getLanguage()],
        ),
      );
    } else {
      const ga_linker_string = getGaLinkerUrlParam();
      if (ga_linker_string === null) {
        history.goBack();
        history.replace(
          updatePathnameForLanguage(
            window.location.pathname,
            pathLanguageCodes[getLanguage()],
          ),
        );
      } else {
        history.goBack();
        history.replace(
          updatePathnameForLanguage(
            window.location.pathname,
            pathLanguageCodes[getLanguage()],
          ),
          getSearchStringWithGaLinker(window.location.search),
        );
      }
    }
  };

  return (
    <Button
      disableRipple
      className="goBackButton link"
      onClick={handleGoBack}
      startIcon={<ArrowBack />}
    >
      {t("genericTerms.back")}
    </Button>
  );
}
