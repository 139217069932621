import { Language } from "types/Language";

const mapMaterialToEnglishDict = {
  alpakka: "alpaca",
  nylon: "nylon",
  ull: "wool",
  "norsk ull": "norwegian wool",
  merinoull: "merino wool",
  silke: "silk",
  bomull: "cotton",
  mohair: "mohair",
  polyamid: "polyamide",
  akryl: "acrylic",
  kashmir: "cashmere",
  polyester: "polyester",
  donegal: "donegal",
  viskose: "viscose",
  ployamid: "polyamide",
  lin: "linen",
};

export const mapMaterialToNorwegianDict = {
  alpaca: "alpakka",
  nylon: "nylon",
  wool: "ull",
  "norwegian wool": "norsk ull",
  "merino wool": "merinoull",
  silk: "silke",
  cotton: "bomull",
  mohair: "mohair",
  polyamide: "polyamid",
  acrylic: "akryl",
  cashmere: "kashmir",
  polyester: "polyester",
  donegal: "donegal",
  viscose: "viskose",
  linen: "lin",
};

export function materialMapper(materials: string[], language: string) {
  if (language === "nb") {
    return materials;
  }
  return materials.map((material) => {
    return mapMaterialToEnglishDict[material] || material;
  });
}

export function reverseMaterialMapper(materials: string[], language: Language) {
  if (language === Language.EN) {
    return materials.map((material) => {
      return mapMaterialToNorwegianDict[material];
    });
  }
  return materials;
}
