import { ProductType } from "types/ShoppingCart";
import { createDesignView } from "./DesignView";
import { useDesign } from "store/DesignContext";
import { useEffect } from "react";

const SweaterDesignView = () => {
  const { design, updatePatternType } = useDesign();

  useEffect(() => {
    updatePatternType(ProductType.SWEATERPATTERN);
  }, []);

  return <>{createDesignView(design)}</>;
};

export default SweaterDesignView;
