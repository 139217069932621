import { classNames } from "styles/StyleUtils";
import pillButtonStyles from "./PillButton.module.scss";

interface PillButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  active?: boolean;
  size?: "small" | "large" | "fit-content";
}

export function PillButton({
  children,
  onClick,
  className,
  active,
  size = "fit-content",
}: PillButtonProps) {
  function mapSizeToClassName(size: string) {
    switch (size) {
      case "small":
        return pillButtonStyles["small-button"];
      case "large":
        return pillButtonStyles["large-button"];
      default:
        return pillButtonStyles["fit-content"];
    }
  }

  const buttonStyles = classNames(
    pillButtonStyles["button"],
    className,
    active && pillButtonStyles["active"],
    mapSizeToClassName(size),
  );

  return (
    <button onClick={onClick} className={buttonStyles}>
      {children}
    </button>
  );
}
