import "./CollapsibleContainer.scss";

import { ChevronRight } from "@mui/icons-material";
import { useState } from "react";

export interface CollapsibleContainerProps {
  startAsExpanded?: boolean;
  collapsedText: string;
}

export default function CollapsibleContainer(
  props: React.PropsWithChildren<CollapsibleContainerProps>,
) {
  const [collapsed, setCollapsed] = useState<boolean>(
    props.startAsExpanded ?? true,
  );

  return (
    <div className="collapsible-container">
      <p
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => setCollapsed(!collapsed)}
      >
        {props.collapsedText}
        <ChevronRight
          id="collapsible-icon"
          style={{
            transform: !collapsed ? "rotate(90deg)" : "none",
          }}
        />
      </p>

      <div
        className={
          collapsed
            ? "collapsible-children-container collapsed"
            : "collapsible-children-container expanded"
        }
      >
        {props.children}
      </div>
    </div>
  );
}
