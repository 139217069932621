import { useHistory } from "react-router-dom";
import { getSearchStringWithGaLinker } from "utils/crossDomainTracking";

export const useDreamknitHistory = () => {
  const history = useHistory();

  const pushToHistory = ({
    pathname,
    search,
  }: {
    pathname: string;
    search?: string;
  }) => {
    /**
     * Extends the history.push function of react router to account for
     * Dreamknit specific conscerns such as which language is currently used
     * and to keep the _ga linker parameter for cross domain tracking.
     * @param pathname: The pathname of the path you want to go to.
     * @param search: The search string you want to add.
     */
    let newPath = pathname;
    if (history.location.pathname.includes("/no")) {
      newPath = "/no" + newPath;
      if (newPath.endsWith("/") && newPath !== "/") {
        newPath = newPath.slice(0, -1);
      }
    }
    const searchString = getSearchStringWithGaLinker(search);

    //Used to keep track of the previous page visited
    sessionStorage.setItem("dkLastPage", window.location.hostname);
    history.push({ pathname: newPath, search: searchString });
  };

  return pushToHistory;
};
