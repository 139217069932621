import { Collapse } from "@mui/material";
import { ReactComponent as FaChevronDown } from "assets/fa-chevron-down.svg";

interface CollapsibleInformationContainerInterface {
  title: string;
  isOpen: boolean;
  toggleOpen: () => void;
}

const CollapsibleInformationContainer = (
  props: React.PropsWithChildren<CollapsibleInformationContainerInterface>,
) => {
  const { title, isOpen, toggleOpen, children } = props;

  return (
    <div
      className={`collapsible-information-container ${isOpen ? "open" : ""}`}
      data-cy="collapsible-information-container"
    >
      <button
        onClick={() => toggleOpen()}
        className="collapsible-information-container-button"
      >
        <span>{title}</span>
        <span className={`chevron-icon ${isOpen ? "up" : "down"}`}>
          <FaChevronDown scale="2x" />
        </span>
      </button>
      <Collapse in={isOpen} unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

export default CollapsibleInformationContainer;
