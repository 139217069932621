import { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { Grid } from "@mui/material";
import DkTextField from "components/Other/Form/DkTextField";
import { DeliveryMethodOptions } from "types/Checkout";
import { useTranslation } from "react-i18next";
import DkEmailInputField from "components/Other/Form/DkEmailInputField";
import {
  getSelectedDeliveryMethod,
  isEmailAddressValid,
  isNameValid,
  isPhoneNumberValid,
  isZipcodeValid,
} from "utils/shippingInformationFormUtils";

interface ShippingInformationFormProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const ShippingInformationForm = (props: ShippingInformationFormProps) => {
  const { ...otherProps } = props;
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setValue(event.target.value);
  };

  const isStringValid = (s) => {
    return s.length > 0;
  };

  const commonTextFieldProps = {
    fullWidth: true,
    errorCheck: true,
    isOutlined: false,
    backgroundColor: "#FFF",
    InputLabelProps: {
      sx: {
        top: "-5px",
      },
    },
  };

  return (
    <div {...otherProps}>
      <Grid container rowSpacing={0.75} columnSpacing={0.5}>
        <Grid item xs={12}>
          <DkEmailInputField
            onChange={(e) => handleTextFieldChange(e, setEmail)}
            label={`${t("checkout.checkoutForm.emailAddress")}*`}
            id="shipping-information-email"
            value={email}
            validator={isEmailAddressValid}
            {...commonTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <DkTextField
            onChange={(e) => handleTextFieldChange(e, setFirstName)}
            label={`${t("checkout.checkoutForm.shipping.givenName")}*`}
            id="shipping-information-firstname"
            value={firstName}
            validator={isNameValid}
            inputProps={{ "auto-complete": "given-name" }}
            {...commonTextFieldProps}
          />
        </Grid>
        <Grid item xs={6}>
          <DkTextField
            onChange={(e) => handleTextFieldChange(e, setLastName)}
            label={`${t("checkout.checkoutForm.shipping.lastName")}*`}
            id="shipping-information-lastname"
            value={lastName}
            validator={isNameValid}
            inputProps={{ "auto-complete": "family-name" }}
            {...commonTextFieldProps}
          />
        </Grid>
        {getSelectedDeliveryMethod() === DeliveryMethodOptions.MAIL && (
          <>
            <Grid item xs={12}>
              <DkTextField
                onChange={(e) => handleTextFieldChange(e, setAddress)}
                label={`${t("checkout.checkoutForm.shipping.address")}*`}
                id="shipping-information-address"
                value={address}
                validator={isStringValid}
                inputProps={{ "auto-complete": "street-address" }}
                {...commonTextFieldProps}
              />
            </Grid>
            <Grid item xs={6}>
              <DkTextField
                onChange={(e) => handleTextFieldChange(e, setZipcode)}
                label={`${t("checkout.checkoutForm.shipping.zipCode")}*`}
                id="shipping-information-zip"
                value={zipcode}
                validator={isZipcodeValid}
                inputProps={{ "auto-complete": "postal-code" }}
                {...commonTextFieldProps}
              />
            </Grid>
            <Grid item xs={6}>
              <DkTextField
                onChange={(e) => handleTextFieldChange(e, setCity)}
                label={`${t("checkout.checkoutForm.shipping.city")}*`}
                id="shipping-information-city"
                value={city}
                validator={isStringValid}
                {...commonTextFieldProps}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <DkTextField
            onChange={(e) => handleTextFieldChange(e, setPhone)}
            label={`${t("checkout.checkoutForm.shipping.phoneNumber")}*`}
            id="shipping-information-phone"
            value={phone}
            validator={isPhoneNumberValid}
            inputProps={{ "auto-complete": "tel" }}
            {...commonTextFieldProps}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShippingInformationForm;
