import { Link } from "@mui/material";
import { HandleClickSweaterData } from "components/Design/DesignAccordion/SweaterAccordion";
import SizeGuide from "components/Design/SizeGuide/SizeGuide";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import {
  Sweater,
  SweaterBodyWidth,
  SweaterKeys,
  SweaterSize,
} from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import "./ConstructionSelectionBox.scss";
import "./FitSelectionBox.scss";
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

const FitSelectionBox = (props: { handleClick: HandleClickSweaterData }) => {
  const { design, updateDesign } = useDesign();
  const { t } = useTranslation();
  const useEffectDependencies = [
    design.designChoices.size,
    SweaterKeys.BODY_WIDTH in design.designChoices
      ? design.designChoices.bodyWidth
      : "",
  ];

  const [showSizeGuide, setShowSizeGuide] = useState(false);

  const handleShowSizeGuide = () => {
    if (!showSizeGuide) {
      trackEvent(TrackingEvents.SHOW_SIZE_GUIDE);
    }
    setShowSizeGuide((value) => !value);
  };

  const [activeClassSize, setActiveClassSize] = useState(
    SweaterKeys.SIZE in design.designChoices ? design.designChoices.size : "",
  );

  const [activeClassBodyWidth, setActiveClassBodyWidth] = useState(
    SweaterKeys.BODY_WIDTH in design.designChoices
      ? design.designChoices.bodyWidth
      : "",
  );

  useEffect(() => {
    if (design.patternType === ProductType.SWEATERPATTERN) {
      setActiveClassSize(design.designChoices.size);
      setActiveClassBodyWidth(design.designChoices.bodyWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);

  const sizeOptions = createSelectionOptions<Sweater>({
    keys: Object.values(SweaterSize),
    dispatchFunction: updateDesign,
    designChoiceCategory: SweaterKeys.SIZE,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassSize,
    translationMapper: (key) => key.toUpperCase(),
  });

  const bodyWidthOptions = createSelectionOptions<Sweater>({
    keys: Object.values(SweaterBodyWidth),
    dispatchFunction: updateDesign,
    designChoiceCategory: SweaterKeys.BODY_WIDTH,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassBodyWidth,
    translationMapper: (key) =>
      mapKeyToTranslationKey(key, SweaterKeys.BODY_WIDTH),
  });

  return (
    <SelectionContainer>
      <div className="sizeSelectionBoxInfo">
        <Link
          className="sizeGuideLink"
          onClick={handleShowSizeGuide}
          sx={{ color: "secondary.dark" }}
        >
          {t("design.designAccordion.fit.sizeGuide")}
        </Link>
        {showSizeGuide ? <SizeGuide /> : null}
      </div>
      <SelectionOptionsBox
        options={sizeOptions}
        activeOption={activeClassSize}
        displayType="row"
      />
      <SelectionOptionsBox
        options={bodyWidthOptions}
        activeOption={activeClassBodyWidth}
        displayType="row"
      />
    </SelectionContainer>
  );
};

export default FitSelectionBox;
