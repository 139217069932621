import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox, Drawer, Slider } from "@mui/material";
import MultipleSelect from "components/Other/MultipleSelect/MultipleSelect";
import { PillButton } from "components/Other/Pill/PillButton";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { classNames } from "styles/StyleUtils";
import { Language } from "types/Language";
import { fetchYarnMaterials } from "utils/api/fetchYarnMaterials";
import { getLanguage } from "utils/intl/languageUtil";
import FilterYarnSectionStyles from "./FilterYarnSection.module.scss";
import { FilterDataObject } from "./YarnContainer/YarnContainer";
import { materialMapper } from "./materialMapper";

export type filterDataFields = keyof FilterDataObject;

interface FilterYarnSectionProps {
  show: boolean;
  filterData: FilterDataObject;
  setFilterData: (value: FilterDataObject) => void;
  handleGaugeWidthChange: (value: number[]) => void;
  allSuppliers: string[];
  setShowFilterYarnDrawer: (show: boolean) => void;
}

const FilterYarnSection = ({
  filterData,
  handleGaugeWidthChange,
  show,
  allSuppliers,
  setShowFilterYarnDrawer,
  setFilterData,
}: FilterYarnSectionProps) => {
  const { t, i18n } = useTranslation();
  const [localGaugeWidth, setLocalGaugeWidth] = useState(filterData.gaugeWidth);
  const [allMaterials, setAllMaterials] = useState<string[]>([]);
  const mappedMaterials = useMemo(
    () => materialMapper(allMaterials, i18n.language),
    [allMaterials, i18n.language],
  );

  useEffect(() => {
    fetchYarnMaterials().then((materials) =>
      setAllMaterials(materials.map((material) => material.name).sort()),
    );
  }, []);

  const handleLocalGaugeWidthChange = (event) =>
    setLocalGaugeWidth(event.target.value);

  const needlesizes = Array.from(
    { length: (14 - 2) / 0.5 + 1 },
    (_, i) => 2 + i * 0.5,
  );

  function updateFilterData(
    field: filterDataFields,
    value: Array<number | string> | boolean,
  ) {
    setFilterData({
      ...filterData,
      [field]: value,
    });
  }

  function handleNeedlesizeChange(value: number) {
    const needlesizeIncludesValue = filterData.needlesizes.includes(value);
    if (needlesizeIncludesValue) {
      const newValue = filterData.needlesizes.filter((size) => size !== value);
      updateFilterData("needlesizes", newValue);
    } else {
      updateFilterData("needlesizes", [...filterData.needlesizes, value]);
    }
  }

  if (!show) {
    return <></>;
  }

  const headerSection = (
    <div className={FilterYarnSectionStyles["header-section"]}>
      <button
        className={classNames(
          FilterYarnSectionStyles["close-button"],
          FilterYarnSectionStyles["mobile_icon"],
        )}
        onClick={() => setShowFilterYarnDrawer(false)}
      >
        <ArrowBackIcon fontSize="large" />
      </button>
      <h2 className={FilterYarnSectionStyles["header_title"]}>
        {t("yarndb.filterSection.filter")}
      </h2>
      <button
        className={classNames(
          FilterYarnSectionStyles["close-button"],
          FilterYarnSectionStyles["desktop_icon"],
        )}
        onClick={() => setShowFilterYarnDrawer(false)}
      >
        <ClearIcon
          fontSize="large"
          className={FilterYarnSectionStyles["desktop_icon"]}
        />
      </button>
    </div>
  );

  return (
    <Drawer
      open={show}
      onClose={() => setShowFilterYarnDrawer(false)}
      anchor="right"
    >
      <div
        id="filter-yarn-section"
        className={FilterYarnSectionStyles["container"]}
      >
        {headerSection}
        <div className={FilterYarnSectionStyles["filter_options_container"]}>
          <div className={FilterYarnSectionStyles["filter_item"]}>
            <p>{t("yarndb.filterSection.needlesize")} (mm)</p>
            <div className={FilterYarnSectionStyles["pillbutton-container"]}>
              <div className={FilterYarnSectionStyles["scrollable-row"]}>
                {needlesizes
                  .filter((_, index) => index % 2 === 0)
                  .map((size) => (
                    <PillButton
                      key={size}
                      className={classNames(
                        FilterYarnSectionStyles["pillbutton"],
                      )}
                      onClick={() => handleNeedlesizeChange(size)}
                      active={filterData.needlesizes.includes(size)}
                      size={"small"}
                    >
                      {size}.0
                    </PillButton>
                  ))}
              </div>
              <div className={FilterYarnSectionStyles["scrollable-row"]}>
                {needlesizes
                  .filter((_, index) => index % 2 !== 0)
                  .map((size) => (
                    <PillButton
                      key={size}
                      className={classNames(
                        FilterYarnSectionStyles["pillbutton"],
                      )}
                      onClick={() => handleNeedlesizeChange(size)}
                      active={filterData.needlesizes.includes(size)}
                      size={"small"}
                    >
                      {size}
                    </PillButton>
                  ))}
              </div>
            </div>
          </div>
          <div className={FilterYarnSectionStyles["filter_item"]}>
            <p>{t("yarndb.filterSection.gauge")}</p>
            <div className={FilterYarnSectionStyles["slider_container"]}>
              <Slider
                name="gaugeWidth"
                sx={{
                  color: "primary.dark",
                }}
                value={localGaugeWidth}
                valueLabelDisplay="on"
                onChange={handleLocalGaugeWidthChange}
                onChangeCommitted={(_, value) => {
                  if (value.constructor.name !== "Array") {
                    return;
                  }

                  handleGaugeWidthChange(value as number[]);
                }}
                step={1}
                min={10}
                max={32}
                size="small"
                data-cy="sliderGaugeWidth"
                slotProps={{
                  valueLabel: {
                    className:
                      FilterYarnSectionStyles["filterControlSliderLabel"],
                    valueLabelDisplay: "on",
                  },
                  track: { style: { height: "4px" } },
                }}
              />
            </div>
          </div>
          <div className={FilterYarnSectionStyles["filter_item"]}>
            <MultipleSelect
              items={allSuppliers}
              label={t("genericTerms.supplier")}
              onSelectionChange={(selectedSuppliers) =>
                updateFilterData("suppliers", selectedSuppliers)
              }
              initialSelectedItems={filterData.suppliers}
            ></MultipleSelect>
          </div>
          <div className={FilterYarnSectionStyles["filter_item"]}>
            <MultipleSelect
              items={mappedMaterials}
              label={t("sorting.material")}
              onSelectionChange={(selectedMaterials) =>
                updateFilterData("materials", selectedMaterials)
              }
              initialSelectedItems={filterData.materials}
            />
          </div>
          {getLanguage() === Language.NO && (
            <div
              className={classNames(
                FilterYarnSectionStyles["filter_item"],
                FilterYarnSectionStyles["checkbox-container"],
              )}
            >
              <Checkbox
                checked={filterData.showYarnPackages}
                onChange={() =>
                  updateFilterData(
                    "showYarnPackages",
                    !filterData.showYarnPackages,
                  )
                }
              ></Checkbox>
              {t("yarndb.filterSection.showYarnPackages")}
            </div>
          )}
          <div
            className={classNames(
              FilterYarnSectionStyles["filter_item"],
              FilterYarnSectionStyles["multi-checkbox-container"],
            )}
          >
            <div className={FilterYarnSectionStyles["row"]}>
              <Checkbox
                checked={filterData.showMainThreadOnlyYarns}
                onChange={() =>
                  updateFilterData(
                    "showMainThreadOnlyYarns",
                    !filterData.showMainThreadOnlyYarns,
                  )
                }
              />
              {t("yarndb.filterSection.showMainThreadOnlyYarns")}
            </div>
            <div className={FilterYarnSectionStyles["row"]}>
              <Checkbox
                checked={filterData.showHeldTogetherWithYarns}
                onChange={() =>
                  updateFilterData(
                    "showHeldTogetherWithYarns",
                    !filterData.showHeldTogetherWithYarns,
                  )
                }
              ></Checkbox>
              {t("yarndb.filterSection.showHeldTogetherWithYarns")}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterYarnSection;
