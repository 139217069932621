import "./SelectionBox.scss";
import SelectionContainerStyles from "./SelectionContainer.module.scss";

interface SelectionBoxProps {
  children: React.ReactNode;
}

export function SelectionContainer({ children }: SelectionBoxProps) {
  return (
    <div className={SelectionContainerStyles["container"]}>{children}</div>
  );
}
