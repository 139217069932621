import { AxiosResponse } from "axios";
import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";
import { PromotionCodeRequest, PromotionCodeResponse } from "types/APITypes";

export const PromotionCodeApi = {
  post: async function (
    data: PromotionCodeRequest,
  ): Promise<AxiosResponse<PromotionCodeResponse>> {
    return api.post(APIPath.USE_PROMOTION_CODE, data).catch((error) => {
      checkStatusAndLogApiError(APIPath.USE_PROMOTION_CODE, error, data);
      throw error;
    });
  },
};
