import { HandleClickSweaterData } from "components/Design/DesignAccordion/SweaterAccordion";
import { useEffect, useState } from "react";
import { useDesign } from "store/DesignContext";
import {
  Sweater,
  SweaterBodyLength,
  SweaterConstruction,
  SweaterKeys,
} from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import "./ConstructionSelectionBox.scss";
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { validConstructions } from "utils/designUtils";

interface ConstructionSelectionBoxProps {
  handleClick: HandleClickSweaterData;
}

const ConstructionSelectionBox = ({
  handleClick,
}: ConstructionSelectionBoxProps) => {
  const { design, updateDesign } = useDesign();

  const [activeConstruction, setActiveConstruction] = useState(
    SweaterKeys.CONSTRUCTION in design.designChoices
      ? design.designChoices.construction
      : "",
  );
  const [activeBodyLength, setActiveBodyLength] = useState(
    SweaterKeys.BODY_LENGTH in design.designChoices
      ? design.designChoices.bodyLength
      : "",
  );

  const useEffectDependencies = [
    "construction" in design.designChoices
      ? design.designChoices.construction
      : undefined,
    "bodyLength" in design.designChoices
      ? design.designChoices.bodyLength
      : undefined,
  ];

  useEffect(() => {
    if (
      design.patternType === ProductType.SWEATERPATTERN &&
      design.designChoices.construction === "raglan" &&
      design.designChoices.sleeve === "nosleeve"
    ) {
      updateDesign<Sweater, SweaterKeys.CONSTRUCTION>(
        SweaterKeys.CONSTRUCTION,
        SweaterConstruction.SETIN,
      );
      setActiveConstruction(SweaterConstruction.SETIN);
      handleClick(SweaterKeys.CONSTRUCTION, SweaterConstruction.SETIN);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, useEffectDependencies);

  useEffect(() => {
    if (design.patternType === ProductType.SWEATERPATTERN) {
      setActiveConstruction(design.designChoices.construction);
      setActiveBodyLength(design.designChoices.bodyLength);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);

  if (design.patternType !== ProductType.SWEATERPATTERN) {
    return <></>;
  }

  const constructionOptions = createSelectionOptions<Sweater>({
    keys: Object.values(SweaterConstruction),
    dispatchFunction: updateDesign,
    designChoiceCategory: SweaterKeys.CONSTRUCTION,
    handleClick: handleClick,
    setActiveClass: setActiveConstruction,
    translationMapper: (key) =>
      mapKeyToTranslationKey(key, SweaterKeys.CONSTRUCTION),
    isInvalid: (key: any) =>
      !validConstructions(design.designChoices).includes(key),
  });

  const bodyLengthOptions = createSelectionOptions<Sweater>({
    keys: Object.values(SweaterBodyLength),
    dispatchFunction: updateDesign,
    designChoiceCategory: SweaterKeys.BODY_LENGTH,
    handleClick: handleClick,
    setActiveClass: setActiveBodyLength,
    translationMapper: (key) =>
      mapKeyToTranslationKey(key, SweaterKeys.BODY_LENGTH),
  });

  return (
    <SelectionContainer>
      <SelectionOptionsBox
        options={constructionOptions}
        activeOption={activeConstruction || ""}
        displayType="row"
      />
      <SelectionOptionsBox
        options={bodyLengthOptions}
        activeOption={activeBodyLength || ""}
        displayType="row"
      />
    </SelectionContainer>
  );
};

export default ConstructionSelectionBox;
