import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@mui/material";
import "components/shoppingcart/ShoppingCartItem.scss";
import { useDesign } from "store/DesignContext";
import { useShoppingCart } from "store/ShoppingCartContext";
import AppPath from "types/AppPath";
import { ProductType } from "types/ShoppingCart";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import {
  findPatternItem,
  loadHatDesignFromShoppingCart,
  loadSweaterDesignFromShoppingCart,
} from "utils/shoppingCartUtils";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

interface ShoppingCartItemProps {
  supplier: string;
  illustration: JSX.Element;
  productDetails: JSX.Element;
  priceSection: JSX.Element;
  shoppingCartItemId: number;
  removeItem: () => void;
  editable?: boolean;
  removable?: boolean;
  warningMessage?: string;
}

const ShoppingCartItem = (props: ShoppingCartItemProps) => {
  const {
    supplier,
    illustration,
    productDetails,
    priceSection,
    shoppingCartItemId,
    removeItem,
    warningMessage,
    editable = false,
    removable = false,
  } = props;
  const { shoppingCart, setIsOpen } = useShoppingCart();
  const pushToHistory = useDreamknitHistory();
  const { loadDesign, setYarn, setYarnIsChosen, setColor } = useDesign();

  const editPattern = () => {
    const item = findPatternItem(shoppingCart.items, shoppingCartItemId);

    const conditionallyRedirect = (pathname: AppPath) => {
      if (!window.location.pathname.includes(pathname)) {
        pushToHistory({ pathname });
      }
    };

    if (item === undefined) return;

    setIsOpen(false);
    switch (item.productTypeId) {
      case ProductType.SWEATERPATTERN:
        conditionallyRedirect(AppPath.SWEATER);

        loadSweaterDesignFromShoppingCart(
          item.productDetails,
          shoppingCartItemId,
          loadDesign,
          setYarn,
          setYarnIsChosen,
          setColor,
        );
        break;
      case ProductType.HATPATTERN:
        conditionallyRedirect(AppPath.HAT);

        loadHatDesignFromShoppingCart(
          item.productDetails,
          shoppingCartItemId,
          loadDesign,
          setYarn,
          setYarnIsChosen,
          setColor,
        );
        break;
    }
    trackEvent(TrackingEvents.EDIT_PATTERN);
  };

  return (
    <Grid container spacing={0} className="shopping-cart-item-container">
      <Grid
        item
        xs={4}
        sx={{ height: "100%" }}
        className="shopping-cart-item-illustration"
      >
        <div>{illustration}</div>
      </Grid>
      <Grid item xs={8} sx={{ height: "100%" }}>
        <div className="shopping-cart-item-information">
          <div>
            <div className="shopping-cart-item-information-horizontal-adjust">
              <p className="text-s" style={{ color: "darkgrey" }}>
                {supplier}
              </p>
              <div className="shopping-cart-item-information-horizontal-adjust shopping-cart-item-information-button-container">
                {editable && (
                  <button
                    className="shopping-cart-item-information-button plain-button"
                    onClick={editPattern}
                  >
                    <EditIcon fontSize="small" />
                  </button>
                )}
                {removable && (
                  <button
                    className="shopping-cart-item-information-button plain-button"
                    onClick={removeItem}
                  >
                    <ClearIcon fontSize="small" />
                  </button>
                )}
              </div>
            </div>
            <div className="shopping-cart-item-information-product-details">
              {productDetails}
            </div>
          </div>
          <div>
            {warningMessage && (
              <p className={"shopping-cart-item-warning"}>{warningMessage}</p>
            )}
          </div>
          <div className="shopping-cart-item-information-horizontal-adjust">
            {priceSection}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ShoppingCartItem;
