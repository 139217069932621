interface ColorCircleProps {
  color: string;
  active: boolean;
  outOfStock: boolean;
  clickable: boolean;
  onClick?: (color: string) => void;
}

const ColorCircle = (props: ColorCircleProps) => {
  const { color, active, outOfStock, clickable, onClick } = props;
  return (
    <div style={{ height: "50px", width: "50px" }}>
      <svg
        className={`color-circle ${active ? "active" : ""} ${
          outOfStock ? "out-of-stock" : ""
        } ${clickable ? "clickable" : ""}`}
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          onClick !== undefined && onClick(color);
        }}
      >
        <ellipse cx="20" cy="20.5" rx="20" ry="20.5" fill={color} />
        {outOfStock && (
          <line
            x1="33.4085"
            y1="3.28834"
            x2="9.40848"
            y2="37.2883"
            stroke="white"
          />
        )}
      </svg>
    </div>
  );
};

export default ColorCircle;
