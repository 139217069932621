import { Alert } from "@mui/material";
import { ReactComponent as Blubb } from "assets/sweaterBuild/Bakgrunn.svg";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { getIllustrationForProductType } from "utils/illustrationUtils";
import { isNorwegianAndIsBuyingYarn } from "utils/requireDeliveryAndShippingForm";
import DegreeOfDifficulty from "./DegreeOfDifficulty/DegreeOfDifficulty";
import "./IllustrationContainer.scss";
import YarnAmount from "./YarnAmount/YarnAmount";
import { useShoppingCart } from "store/ShoppingCartContext";

interface IllustrationContainerProps {
  showYarnErrorMessage: boolean;
}

const IllustrationContainer = (props: IllustrationContainerProps) => {
  const { showYarnErrorMessage } = props;
  const { design, yarnIsChosen, color } = useDesign();
  const { shoppingCart } = useShoppingCart();
  const { t } = useTranslation();

  const Illustration = () => {
    return getIllustrationForProductType(design);
  };

  const shouldShowYarnErrorMessage = () =>
    !yarnIsChosen && showYarnErrorMessage;

  return (
    <div className="illustrationContainer">
      <div className="sweaterIllustration">
        <Blubb
          className="sweaterIllustrationBlubb"
          style={{
            fill: isNorwegianAndIsBuyingYarn(shoppingCart)
              ? "#FFF"
              : `${color}`,
          }}
        />
        <div className="sweaterIllustrationSVGbox">
          <Illustration />
        </div>
      </div>
      <div className="yarnAmountContainer">
        <DegreeOfDifficulty />
        <YarnAmount />
        {shouldShowYarnErrorMessage() && (
          <Alert className="bottom-yarn-not-chosen" severity="error">
            {t("design.illustrationContainer.errorMessage.noYarnChosen")}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default IllustrationContainer;
