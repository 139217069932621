import Navbar from "components/Other/Navbar/NavBar";
import ShoppingCart from "components/shoppingcart/ShoppingCart";
import { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { DesignProvider } from "store/DesignContext";
import { useShoppingCart } from "store/ShoppingCartContext";
import AppPath from "types/AppPath";
import { SessionStoragePatternId } from "utils/shoppingCartUtils";
import { CheckoutProvider } from "components/checkout/CheckoutContext";
import CheckoutView from "views/CheckoutView/CheckoutView";
import SweaterDesignView from "views/DesignView/SweaterDesignView";
import PageNotFoundView from "views/PageNotFoundView/pageNotFoundView";
import { PatternOverview } from "views/PatternOverviewView/PatternOverview";
import StatusPaymentView from "views/SuccessfulPaymentView/StatusPaymentView";
import SuccessfulPaymentView from "views/SuccessfulPaymentView/SuccessfulPaymentView";
import DetailedYarnView from "views/YarnView/DetailedYarnVeiw/DetailedYarnView";
import YarnView from "views/YarnView/yarnView";
import CancelView from "./views/CancelView/cancelView";
import HatDesignView from "views/DesignView/HatDesignView";

const Routes = () => {
  const location = useLocation();
  const { isOpen, setIsOpen } = useShoppingCart();

  useEffect(() => {
    if (!location.pathname.includes(AppPath.YARN)) {
      sessionStorage.removeItem("dreamKnitFilterData");
    }
  }, [location]);

  window.onbeforeunload = function () {
    SessionStoragePatternId.remove();
    if (process.env.REACT_APP_ENV !== "development") {
      return "You have unsaved changes!";
    }
  };

  return (
    <DesignProvider>
      <CheckoutProvider>
        <Navbar />
        <Switch>
          <Redirect from="/no/design" to={`/no${AppPath.BASE}`} />
          <Redirect from="/design" to={AppPath.BASE} />
          <Route exact path={AppPath.BASE} component={PatternOverview} />
          <Route
            exact
            path={`/no${AppPath.BASE}`}
            component={PatternOverview}
          />
          <Route path={`/no${AppPath.SWEATER}`} component={SweaterDesignView} />
          <Route
            exact
            strict
            path={AppPath.SWEATER}
            component={SweaterDesignView}
          />
          <Route path={`/no${AppPath.HAT}`} component={HatDesignView} />
          <Route exact strict path={AppPath.HAT} component={HatDesignView} />
          <Route exact path={`/no${AppPath.YARN}`} component={YarnView} />
          <Route
            exact
            path={`/no${AppPath.YARN}/:id`}
            component={DetailedYarnView}
          />
          <Route
            exact
            path={`/no${AppPath.CHECKOUT}`}
            component={CheckoutViewWithCheckoutProvider}
          />
          <Route path={`/no${AppPath.STATUS}`} component={StatusPaymentView} />
          <Route
            path={`/no${AppPath.SUCCESS}`}
            component={SuccessfulPaymentView}
          />
          <Route path={`/no${AppPath.CANCEL}`} component={CancelView} />
          <Route exact path={AppPath.YARN} component={YarnView} />
          <Route
            exact
            path={`${AppPath.YARN}/:id`}
            component={DetailedYarnView}
          />
          <Route
            exact
            path={AppPath.CHECKOUT}
            component={CheckoutViewWithCheckoutProvider}
          />
          <Route path={AppPath.STATUS} component={StatusPaymentView} />
          <Route path={AppPath.SUCCESS} component={SuccessfulPaymentView} />
          <Route path={AppPath.CANCEL} component={CancelView} />
          {
            <Route
              strict
              path="/no/:something_not_specified"
              component={PageNotFoundView}
            />
          }
          <Route component={PageNotFoundView} />
        </Switch>
        <ShoppingCart open={isOpen} setIsOpen={setIsOpen} />
      </CheckoutProvider>
    </DesignProvider>
  );
};

function CheckoutViewWithCheckoutProvider() {
  return (
    <CheckoutProvider>
      <CheckoutView />
    </CheckoutProvider>
  );
}

export default Routes;
