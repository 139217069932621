import { Language } from "types/Language";

export const languageCodes = {
  [Language.NO]: "nb",
  [Language.EN]: "en",
};

export const pathLanguageCodes = {
  [Language.NO]: "no",
  [Language.EN]: "",
};

export const updatePathnameForLanguage = (currentPath, newLang) => {
  // Build a regex pattern that matches any of the language codes at the start of the path
  const langCodesRegexPattern = Object.values(pathLanguageCodes)
    .filter((code) => code)
    .join("|");
  const langPathRegex = new RegExp(`^/(${langCodesRegexPattern})(/.*)?$`);

  // Remove the current language prefix if it exists
  const pathWithoutLang = currentPath.replace(langPathRegex, "$2");

  // If switching to English, return the path without language code
  if (newLang === pathLanguageCodes[Language.EN]) {
    return pathWithoutLang || "/"; // Ensure that the root path is '/' and not an empty string
  }

  // For other languages, add the new language prefix
  return `/${newLang}${pathWithoutLang}`;
};
export function handleLanguageCookie(
  setLanguageAndGetPathname,
  setRedirectPath,
) {
  const cookies = document.cookie;
  const languageCookie = cookies
    .split("; ")
    .find((row) => row.startsWith("language="));

  if (languageCookie) {
    const value = decodeURIComponent(languageCookie.split("=")[1]);

    // Set the language based on the cookie value
    const lang = value === "nb" ? Language.NO : Language.EN;
    const pathname = setLanguageAndGetPathname(lang);

    setRedirectPath(pathname);

    // Remove the cookie after processing
    document.cookie =
      "language=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return value;
  }
  return undefined;
}
