import { Design } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import HatIllustration from "../components/Design/IllustrationContainer/HatIllustration";
import SweaterIllustration from "../components/Design/IllustrationContainer/SweaterIllustration/SweaterIllustration";

export function getIllustrationForProductType(design: Design) {
  switch (design.patternType) {
    case ProductType.SWEATERPATTERN:
      return <SweaterIllustration sweater={design.designChoices} />;
    case ProductType.HATPATTERN:
      return <HatIllustration hat={design.designChoices} />;
    default:
      return <></>;
  }
}
