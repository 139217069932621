import { Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import { APIPath } from "types/APIPath";
import { DeliveryMethodOptions } from "types/Checkout";
import { PromotionCodeApi } from "utils/api/PromotionCodeApi";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { getSelectedDeliveryMethod } from "utils/shippingInformationFormUtils";
import { useCheckout } from "./CheckoutContext";
import "./PromotionCodeForm.scss";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

export default function PromotionCodeForm() {
  const [collapse, setCollapse] = useState<boolean>(true);
  const {
    orderId,
    clientSecret,
    updateCurrentValue,
    updatePromoCode,
    updateShippingPrice,
    amountCommitted,
  } = useCheckout();
  const { updateShoppingCartPrices } = useShoppingCart();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function applyPromotionCode() {
    const promotionCode = (
      document.getElementById("promotionCode") as HTMLInputElement
    )?.value;

    const data: {
      promotionCode: string;
      orderId: string;
      deliveryMethod: DeliveryMethodOptions;
      clientSecret?: string;
    } = {
      promotionCode: promotionCode.toUpperCase(),
      clientSecret,
      orderId,
      deliveryMethod: getSelectedDeliveryMethod(),
    };
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    PromotionCodeApi.post(data)
      .then(async (res) => {
        const resData = res.data;
        if (res.status === 200) {
          updateCurrentValue(resData.totalPrice);
          updateShippingPrice(resData.shippingPrice);
          setSuccessMessage(
            t("checkout.checkoutForm.discountCode.success") ?? "",
          );
          const shoppingCartItems = Object.values(resData.shoppingCartItems);
          updateShoppingCartPrices(shoppingCartItems);
          updatePromoCode(promotionCode.toUpperCase());
          trackEvent(TrackingEvents.ADD_PROMOTION_CODE, {
            promotionCode: promotionCode.toUpperCase(),
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // Logging client secret is not allowed https://stripe.com/docs/payments/payment-intents
        delete data.clientSecret;

        checkStatusAndLogApiError(
          `${process.env.REACT_APP_API_URL}${APIPath.USE_PROMOTION_CODE}`,
          error,
          data,
        );

        setErrorMessage(t("checkout.errorMessage.discountCodeInvalid") ?? "");
        updatePromoCode("");
        setIsLoading(false);
      });
  }

  return (
    <div style={{ padding: "0 1rem" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          cursor: "pointer",
          fontSize: "14px",
        }}
        onClick={() => setCollapse(!collapse)}
      >
        <p style={{ textDecoration: "underline", marginBottom: "0.5rem" }}>
          {t("checkout.checkoutForm.discountCode.title")}
        </p>
      </div>
      {collapse && (
        <div className="payment-section-section">
          <div style={{ display: "flex" }}>
            <input
              type="text"
              id="promotionCode"
              style={{
                height: "35px",
                margin: "0px",
              }}
            />
            <Button
              color="primary"
              variant="contained"
              style={{
                width: "fit-content",
                minWidth: "100px",
                height: "35px",
                marginLeft: "10px",
                padding: "10px",
                fontSize: "14px",
                display: "flex",
              }}
              disabled={amountCommitted > 0}
              onClick={applyPromotionCode}
            >
              {isLoading ? (
                <CircularProgress
                  color="secondary"
                  className="promotion-spinnter"
                />
              ) : (
                t("checkout.checkoutForm.discountCode.activate")
              )}
            </Button>
          </div>

          {successMessage && (
            <p style={{ color: "green", fontSize: "10px" }}>{successMessage}</p>
          )}
          {errorMessage && (
            <p style={{ color: "red", fontSize: "10px" }}>{errorMessage}</p>
          )}
        </div>
      )}
    </div>
  );
}
