import CollapsibleContainer from "components/Other/CollapsibleContainer/CollapsibleContainer";
import calculateNeedlesizeRibFromNeedlesize from "utils/calculateNeedlesizeRibFromNeedlesize";
import { useTranslation } from "react-i18next";
import { Language } from "types/Language";
import { getLanguage } from "utils/intl/languageUtil";

export default function OrderSummaryFurtherInformation() {
  const { t } = useTranslation();
  return (
    <div
      id="order-summary-further-information"
      className={getLanguage() === Language.NO ? "norwegian" : ""}
      style={{ fontSize: "14px", paddingLeft: "1rem" }}
    >
      <CollapsibleContainer
        collapsedText={t("checkout.orderSummary.furtherInformation.title")}
      >
        <p
          className={
            getLanguage() === Language.NO
              ? " norwegian order-summary-pdf-info"
              : "order-summary-pdf-info"
          }
          style={{ fontSize: "12px" }}
        >
          {t("checkout.orderSummary.deliveryInformation")}{" "}
        </p>
        <p style={{ fontSize: "12px" }}>
          {/* {t("checkout.orderSummary.furtherInformation.description", {
            needlesizeOne: calculateNeedlesizeRibFromNeedlesize(
              globalState.yarn.needlesize,
            ).toFixed(1),
            needlesizeTwo: globalState.yarn.needlesize.toFixed(1),
          })} */}
          {t("checkout.orderSummary.furtherInformation.descriptionTmp")}
        </p>
      </CollapsibleContainer>
    </div>
  );
}
