export const COLORS = [
  "#FFFFFF",
  "#E2DFDF",
  "#E6C490",
  "#EAE0C3",
  "#BBDCBA",
  "#BADBDC",
  "#D6C9EE",
  "#F1D9F0",
  "#F1D9DB",
];
