export enum DeliveryMethodOptions {
  MAIL = "MAIL",
  PICKUP = "PICKUP",
  NO_DELIVERY = "NO_DELIVERY",
}

export interface EmailCustomerInfo {
  email: string;
  wantsNewsletter: boolean;
}

export interface PickupCustomerInfo extends EmailCustomerInfo {
  firstName: string;
  lastName: string;
  phone: string;
}

export interface DeliveryCustomerInfo extends PickupCustomerInfo {
  address: string;
  city: string;
  zipcode: string;
  country: string;
  note?: string;
}
