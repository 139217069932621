export enum APIPath {
  GET_YARNS = "/yarn",
  GET_VARIANTS = "/variantyarn",
  CALCULATE_YARDAGE = "/calculate/yardage",
  CALCULATE_DIFFICULTY = "/calculate/difficulty",
  CALCULATE_GAUGEWIDTH = "/calculate/gauge-width",
  REDEEM_GIFTCARD = "/giftcard/redeem",
  GET_GIFTCARD_BALANCE = "/giftcard",
  CHECKOUT_INITIATE = "/checkout/initiate",
  CHECKOUT_ADD_CUSTOMER = "/checkout/customer",
  USE_PROMOTION_CODE = "/checkout/promotion-code",
  INITIATE_VIPPS_PAYMENT = "/vipps/initiate-payment",
  GET_VIPPS_PAYMENT_DETAILS = "/vipps/payment-details",
  GET_CONVERSION_RATES = "/conversion-rates",
  GET_YARN_SUPPLIERS = "/yarn/suppliers",
  GET_YARN_MATERIALS = "/yarn/materials",
}

export enum Folders {
  YARNS = "/yarns",
}
