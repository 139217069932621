import { CircularProgress, Grid } from "@mui/material";
import YarnCard from "components/Yarn/YarnCard/YarnCard";
import i18n from "i18n";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Yarn } from "types/Yarn";
import { YarnsApi } from "utils/api/YarnsApi";

export interface SimilarYarnsContainerProps {
  gaugeWidth: number;
  ownYarnId: string;
}

export default function SimilarYarnsContainer(
  props: SimilarYarnsContainerProps,
) {
  const { gaugeWidth, ownYarnId } = props;
  const [similarYarns, setSimilarYarns] = useState<Yarn[]>([]);
  const [loadingSimilarYarns, setLoadingSimilarYarns] = useState(false);

  const fetchSimilarYarns = () => {
    setLoadingSimilarYarns(true);

    const params = { gaugeWidth: gaugeWidth.toString() };

    YarnsApi.queryAll(i18n.language, params)
      .then((yarns) => {
        setSimilarYarns(yarns.filter((yarn) => yarn.yarnId !== ownYarnId));
      })
      .finally(() => setLoadingSimilarYarns(false));
  };

  useEffect(() => {
    if (gaugeWidth !== 0) {
      fetchSimilarYarns();
    }
  }, [ownYarnId]);

  const cardDataToComponents = (similarYarns: Yarn[]) =>
    similarYarns.map((yarn) => (
      <Grid item key={yarn.yarnId} className="yarn-card-container">
        <YarnCard yarnData={yarn} />
      </Grid>
    ));

  const showSimilarYarnData = (similarYarns: Yarn[]) => {
    if (loadingSimilarYarns) {
      return (
        <Grid
          item
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
      );
    } else {
      if (similarYarns.length == 0) {
        return (
          <Grid mt={2} item>
            <p>{t("yarndb.detailedYarnView.noSimilarYarns")}</p>
          </Grid>
        );
      } else {
        return cardDataToComponents(similarYarns);
      }
    }
  };

  return (
    <div className="similar-yarns-container">
      <p className="similar-yarns-container-heading">
        <span>{t("yarndb.detailedYarnView.similarYarns")}</span>
      </p>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        justifyContent="flex-start"
        className="similar-yarns-container-yarns"
      >
        {showSimilarYarnData(similarYarns)}
      </Grid>
    </div>
  );
}
