export default function mapSweaterSizeAndOverwidthToCm(
  size: string,
  overwidth: string,
) {
  if (overwidth === "regular") {
    switch (size) {
      case "xs":
        return 90;
      case "s":
        return 95;
      case "m":
        return 100;
      case "l":
        return 110;
      case "xl":
        return 120;
      case "xxl":
        return 130;
    }
  } else if (overwidth === "oversized") {
    switch (size) {
      case "xs":
        return 100;
      case "s":
        return 105;
      case "m":
        return 110;
      case "l":
        return 120;
      case "xl":
        return 130;
      case "xxl":
        return 140;
    }
  }
}
