import { PropsWithChildren, createContext, useContext, useState } from "react";
import { DesignChoices, Hat, Mittens, Sweater } from "types/DesignChoices";
import { PatternProductType } from "types/DesignChoices/DesignChoices";
import { DEFAULT_HAT, urlParamsHat } from "types/DesignChoices/Hat";
import { DEFAULT_SWEATER, urlParamsSweater } from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import { DesignYarn, urlParamsDesignYarn } from "types/Yarn";

export type Design =
  | {
      designChoices: Sweater;
      patternType: ProductType.SWEATERPATTERN;
    }
  | {
      designChoices: Mittens;
      patternType: ProductType.MITTENSPATTERN;
    }
  | {
      designChoices: Hat;
      patternType: ProductType.HATPATTERN;
    };

export type UpdateDesignFunction = <T extends DesignChoices, K extends keyof T>(
  key: K,
  value: T[K],
  patternProductType?: PatternProductType,
) => void;

export type SetYarnFunction = React.Dispatch<React.SetStateAction<DesignYarn>>;
export type SetYarnIsChosenFunction = React.Dispatch<
  React.SetStateAction<boolean>
>;
export type SetColorFunction = React.Dispatch<React.SetStateAction<string>>;

interface IDesignContext {
  design: Design;
  updateDesign: UpdateDesignFunction;
  updatePatternType: (patternType: PatternProductType) => void;
  loadDesign: (design: Design) => void;
  yarn: DesignYarn;
  setYarn: SetYarnFunction;
  yarnIsChosen: boolean;
  setYarnIsChosen: SetYarnIsChosenFunction;
  color: string;
  setColor: SetColorFunction;
}

const DesignContext = createContext<IDesignContext>({} as IDesignContext);

export function useDesign(): IDesignContext {
  return useContext(DesignContext);
}

export function DesignProvider({
  children,
}: PropsWithChildren<unknown>): React.ReactElement {
  const [design, setDesign] = useState<Design>({
    designChoices: urlParamsSweater,
    patternType: ProductType.SWEATERPATTERN,
  });
  const [yarn, setYarn] = useState<DesignYarn>(urlParamsDesignYarn);
  const [yarnIsChosen, setYarnIsChosen] = useState(false);
  const [color, setColor] = useState("#FFF");

  const updateDesign: UpdateDesignFunction = <
    T extends DesignChoices,
    K extends keyof T,
  >(
    key: K,
    value: T[K],
    patternProductType = design.patternType,
  ) => {
    if (patternProductType != design.patternType) {
      throw new Error(
        `updateDesign with parameter 'patternType'=${patternProductType} cannot be called when design pattern type is ${design.patternType}`,
      );
    }

    switch (patternProductType) {
      case ProductType.SWEATERPATTERN:
        if (!Object.keys(DEFAULT_SWEATER).includes(String(key))) {
          throw ReferenceError(
            `Property ${String(
              key,
            )} does not exist on product type SWEATERPATTERN`,
          );
        }
        setDesign((prevDesign) => ({
          patternType: ProductType.SWEATERPATTERN,
          designChoices: {
            ...(prevDesign.designChoices as Sweater),
            [key]: value,
          },
        }));
        break;
      case ProductType.HATPATTERN:
        if (!Object.keys(DEFAULT_HAT).includes(String(key))) {
          throw ReferenceError(
            `Property ${String(key)} does not exist on product type HATPATTERN`,
          );
        }
        setDesign((prevDesign) => ({
          patternType: ProductType.HATPATTERN,
          designChoices: {
            ...(prevDesign.designChoices as Hat),
            [key]: value,
          },
        }));
        break;
      case ProductType.MITTENSPATTERN:
        // TODO: Implement check for key
        setDesign({
          patternType: ProductType.MITTENSPATTERN,
          designChoices: { ...(design.designChoices as Mittens), [key]: value },
        });
        break;
    }
  };

  const updatePatternType = (patternType: PatternProductType) => {
    if (patternType === design.patternType) return;
    switch (patternType) {
      case ProductType.SWEATERPATTERN:
        setDesign({ designChoices: DEFAULT_SWEATER, patternType });
        break;
      case ProductType.HATPATTERN:
        setDesign({ designChoices: urlParamsHat, patternType });
        break;
      case ProductType.MITTENSPATTERN:
        // TODO: Implement
        break;
    }
  };

  const loadDesign = (design: Design) => {
    setDesign(design);
  };

  return (
    <DesignContext.Provider
      value={{
        design,
        updateDesign,
        updatePatternType,
        loadDesign,
        yarn,
        setYarn,
        yarnIsChosen,
        setYarnIsChosen,
        color,
        setColor,
      }}
    >
      {children}
    </DesignContext.Provider>
  );
}
