import { AxiosResponse } from "axios";
import { APIPath } from "types/APIPath";
import {
  DeliveryCustomerInfo,
  DeliveryMethodOptions,
  PickupCustomerInfo,
} from "types/Checkout";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";

export const addCustomerToOrder = async (
  orderId: string,
  customerInfo: PickupCustomerInfo | DeliveryCustomerInfo,
  deliveryMethod: DeliveryMethodOptions,
  clientSecret: string,
): Promise<AxiosResponse> => {
  // Find out what to handle the deliveryMethod as there might be new logic?
  const data = {
    orderId: orderId,
    clientSecret: clientSecret,
    country: "Norway",
    deliveryMethod: deliveryMethod,
    ...customerInfo,
  };
  if (
    deliveryMethod !== DeliveryMethodOptions.NO_DELIVERY &&
    data.phone.length === 8
  )
    data.phone = `+47${data.phone}`;

  return api.post(APIPath.CHECKOUT_ADD_CUSTOMER, data).catch((error) => {
    checkStatusAndLogApiError(APIPath.CHECKOUT_ADD_CUSTOMER, error, data);
    throw error;
  });
};
