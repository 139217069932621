import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import ColorCircle from "./ColorCircle";
import { ProductType } from "types/ShoppingCart";
import { mapProductTypeToPrice } from "utils/shoppingCartUtils";

interface PriceAndStockContainerProps {
  supplier: string;
  outOfStock: boolean;
  yarnName: string;
  yarnAmount: number;
  yarnPrice: number;
  yarnTwoName?: string;
  yarnTwoAmount?: number;
  yarnTwoPrice?: number;
  color?: string;
  patternType: ProductType;
}

const PriceAndStockContainer = (props: PriceAndStockContainerProps) => {
  const {
    supplier,
    color,
    outOfStock,
    yarnName,
    yarnAmount,
    yarnPrice,
    yarnTwoName,
    yarnTwoAmount,
    yarnTwoPrice,
    patternType,
  } = props;
  const { t } = useTranslation();

  const patternPrice = mapProductTypeToPrice(patternType);

  const calculateTotalPrice = (): string => {
    let price = yarnPrice * yarnAmount;
    price += patternPrice; // Pattern
    if (yarnTwoAmount !== undefined && yarnTwoPrice !== undefined) {
      price += yarnTwoPrice * yarnTwoAmount;
    }
    return `${price}`;
  };

  const calculateYarnTwoPrice = (): string => {
    if (yarnTwoAmount !== undefined && yarnTwoPrice !== undefined)
      return `${yarnTwoAmount * yarnTwoPrice}`;
    return "-";
  };

  const calculateYarnPrice = () => {
    return `${yarnAmount * yarnPrice}`;
  };

  return (
    <Container className="price-and-stock-container">
      <div className="color-column">
        <ColorCircle
          color={color ?? "#fff"}
          active={true}
          outOfStock={outOfStock}
          clickable={false}
        />
      </div>
      <div
        className="purchasable-yarn-section-information-column"
        data-cy="purchasable-yarn-section-information"
      >
        <div className="supplier-and-title-section">
          <p>{supplier}</p>
          <p>
            {yarnName}
            {"\u00A0"}
            {yarnTwoName && (
              <>
                + <br />
                {yarnTwoName}
              </>
            )}
          </p>
          <p
            className={`stock-information ${outOfStock ? "out-of-stock" : ""}`}
          >
            {outOfStock
              ? t("yarndb.detailedYarnView.priceBox.notInStock")
              : t("yarndb.detailedYarnView.priceBox.inStock")}
          </p>
        </div>
        <div data-cy="price-section">
          <p className="price-row">
            <span>
              {yarnAmount} {t("genericTerms.skein_other").toLowerCase()}{" "}
              {yarnName}
            </span>
            <span>{calculateYarnPrice()} kr</span>
          </p>
          {yarnTwoName && (
            <p className="price-row">
              <span>
                {yarnTwoAmount} {t("genericTerms.skein_other").toLowerCase()}{" "}
                {yarnTwoName}
              </span>
              <span>{calculateYarnTwoPrice()} kr</span>
            </p>
          )}
          <p className="price-row">
            <span>{t("yarndb.detailedYarnView.priceBox.pattern")}</span>
            <span>{patternPrice} kr</span>
          </p>
        </div>
        <div data-cy="total-price-section">
          <p className="total-price-row">
            <span>{t("yarndb.detailedYarnView.priceBox.total")}</span>
            <span>{calculateTotalPrice()} kr</span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PriceAndStockContainer;
