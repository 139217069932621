import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ShoppingCartItemResponse } from "types/APITypes";
import {
  PatternCartItem,
  PatternItemBeforeCommit,
  ProductType,
  ShoppingCart,
  ShoppingCartItem,
  YarnCartItem,
  YarnItemBeforeCommit,
  YarnItemDetails,
} from "types/ShoppingCart";
import { MainAndSecondarySkeins } from "types/Yarn";
import {
  SWEATER_PATTERN_PRICE_WITHOUT_YARN,
  SWEATER_PATTERN_PRICE_WITH_YARN,
} from "utils/constants/ShoppingCartConstants";
import { findYarnItem, sortShoppingCartItems } from "utils/shoppingCartUtils";

interface ShoppingCartContextProps {
  shoppingCart: ShoppingCart;
  addPatternToShoppingCart: (item: PatternItemBeforeCommit) => number;
  removePatternFromShoppingCart: (shoppingCartItemId: number) => void;
  modifyPattern: (item: PatternCartItem) => void;
  addYarnToShoppingCart: (yarnItem: YarnCartItem) => void;
  removeYarnFromShoppingCart: (shoppingCartItemId: number) => void;
  modifyYarn: (yarnItem: YarnCartItem) => void;
  addPatternAndYarnItemToShoppingCart: (
    patternItem: PatternItemBeforeCommit,
    yarnItem: YarnItemBeforeCommit,
  ) => number;
  modifySkeinQuantity: (
    skeins: MainAndSecondarySkeins,
    shoppingCartItemId: number,
    shouldUpdateOriginal: boolean,
  ) => void;
  getShoppingCartSize: () => number;
  updateShoppingCartPrices: (
    shoppingCartItems: ShoppingCartItemResponse[],
  ) => void;
  totalCartPrice: number;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShoppingCartContext = createContext<ShoppingCartContextProps>(
  {} as ShoppingCartContextProps,
);

export function useShoppingCart(): ShoppingCartContextProps {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const [shoppingCart, setShoppingCart] = useState<ShoppingCart>({ items: [] });
  const [nextId, setNextId] = useState<number>(0);
  const [totalCartPrice, setTotalCartPrice] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let newTotalCartPrice = 0;

    shoppingCart.items.forEach((shoppingCartItem) => {
      newTotalCartPrice += shoppingCartItem.price;
    });

    setTotalCartPrice(newTotalCartPrice);
  }, [shoppingCart]);

  /**
   * Modifies the price of a shopping cart item.
   * @param shoppingCartItemId The ID of the item to modify.
   * @param newPrice The new price of the item.
   * @param productType The type of the product that will get a new price.
   * @throws RangeError if newPrice is negative
   */
  const modifyItemPrice = (
    shoppingCartItemId: number,
    newPrice: number,
    productType: ProductType,
  ) => {
    if (newPrice < 0) {
      throw new RangeError(`Price ${newPrice} is illegal`);
    }

    const shoppingCartItems = shoppingCart.items;
    shoppingCartItems.forEach((shoppingCartItem) => {
      const idMatches =
        shoppingCartItem.shoppingCartItemId === shoppingCartItemId;
      const productTypeMatches = shoppingCartItem.productTypeId === productType;

      if (idMatches && productTypeMatches) {
        shoppingCartItem.price = newPrice;
      }
    });
  };

  /**
   * Modifies the price of a shopping cart pattern item.
   * @param shoppingCartItemId The ID of the item to modify.
   * @param newPrice The new price of the item.
   *
   * @throws RangeError if newPrice is negative
   */
  const modifyPatternPrice = (
    shoppingCartItemId: number,
    newPrice: number,
    productTypeId: ProductType,
  ) => {
    modifyItemPrice(shoppingCartItemId, newPrice, productTypeId);
  };

  /**
   * Modifies the price of a shopping cart yarn package item.
   * @param shoppingCartItemId The ID of the item to modify.
   * @param newPrice The new price of the item.
   * @param productId Optional parameter to be used to separate between
   * the variantYarn or the secondVariantYarn when updating the price
   * of a yarn.
   *
   * @throws RangeError if newPrice is negative
   */
  const modifyYarnPrice = (
    shoppingCartItemId: number,
    newPrice: number,
    productId: number,
  ) => {
    let totalYarnPrice = 0;

    const yarn: ShoppingCartItem | undefined = findYarnItem(
      shoppingCart.items,
      shoppingCartItemId,
    );

    if (yarn !== undefined) {
      const yarnItem = yarn.productDetails;
      if (productId === yarnItem.variantYarn.productId) {
        yarnItem.variantYarn.price = newPrice;
        totalYarnPrice = newPrice * yarnItem.variantYarn.quantity;

        if (yarnItem.secondVariantYarn !== undefined) {
          totalYarnPrice +=
            yarnItem.secondVariantYarn.price *
            yarnItem.secondVariantYarn.quantity;
        }
      }
      if (productId === yarnItem.secondVariantYarn?.productId) {
        yarnItem.secondVariantYarn.price = newPrice;
        totalYarnPrice =
          newPrice * yarnItem.secondVariantYarn.quantity +
          yarnItem.variantYarn.price * yarnItem.variantYarn.quantity;
      }

      modifyItemPrice(shoppingCartItemId, totalYarnPrice, ProductType.YARN);
    }
  };

  /**
   * Creates a new pattern in the list of items in the shopping cart.
   *
   * @param shoppingCartItemId An object following the ShoppingCartItem interface, omitting
   * the "shoppingCartItemId"
   *
   * * @returns shoppingCartItemId of the newly added pattern
   */
  const addPatternToShoppingCart = (item: PatternItemBeforeCommit): number => {
    const newShoppingCartItem = {
      ...item,
      shoppingCartItemId: nextId,
    };

    setNextId((currentId) => currentId + 1);

    const shoppingCartItems = shoppingCart.items;
    shoppingCartItems.push(newShoppingCartItem);

    const sortedShoppingCartItems = sortShoppingCartItems(shoppingCartItems);

    setShoppingCart({ items: sortedShoppingCartItems });

    return newShoppingCartItem.shoppingCartItemId;
  };

  /**
   * Removes a pattern from the list of shopping cart items. If a yarn package is
   * connected to the pattern, this will also be deleted.
   * @param shoppingCartItemId The ID of the item to delete
   */
  const removePatternFromShoppingCart = (shoppingCartItemId: number): void => {
    const shoppingCartItems = shoppingCart.items;
    const updatedShoppingCartItems = [] as ShoppingCartItem[];

    shoppingCartItems.forEach((shoppingCartItem) => {
      const shouldKeepItem =
        shoppingCartItem.shoppingCartItemId !== shoppingCartItemId;

      if (shouldKeepItem) updatedShoppingCartItems.push(shoppingCartItem);
    });

    if (updatedShoppingCartItems.length === 0) {
      setIsOpen(false);
    }

    setShoppingCart({ items: updatedShoppingCartItems });
  };

  /**
   * Modifies an existing pattern. The function deletes the previous item
   * before inserting a new version with the modifications. If no previous
   * version exists in the list of items, the function creates a new shopping
   * cart item.
   * @param patternItem The item to be updated, with the wanted updates.
   */
  const modifyPattern = (patternItem: PatternCartItem): void => {
    const shoppingCartItems = shoppingCart.items;
    const updatedShoppingCartItems = [] as ShoppingCartItem[];

    shoppingCartItems.forEach((shoppingCartItem) => {
      const idMatches =
        shoppingCartItem.shoppingCartItemId === patternItem.shoppingCartItemId;
      const isYarn = shoppingCartItem.productTypeId === ProductType.YARN;
      const shouldKeepItem = !idMatches || isYarn;

      if (shouldKeepItem) updatedShoppingCartItems.push(shoppingCartItem);
    });

    updatedShoppingCartItems.push(patternItem);

    const sortedShoppingCartItems = sortShoppingCartItems(
      updatedShoppingCartItems,
    );

    setShoppingCart({ items: sortedShoppingCartItems });
  };

  /**
   * Updates the prices of all items in the shoppingCart based on
   * the response from the backend. Also updates the nested prices
   * of a yarn with a secondVariant.
   * @param shoppingCartItems the response from the backend in the
   * type defined as ShoppingCartItemResponse
   */
  const updateShoppingCartPrices = (
    shoppingCartItems: ShoppingCartItemResponse[],
  ) => {
    let newTotalPrice = 0;
    shoppingCartItems.forEach((item: ShoppingCartItemResponse) => {
      switch (item.productTypeId) {
        case ProductType.YARN:
          modifyYarnPrice(item.shoppingCartItemId, item.price, item.productId);
          newTotalPrice += item.price * item.quantity;
          break;
        case ProductType.SWEATERPATTERN:
        case ProductType.HATPATTERN:
        default:
          modifyPatternPrice(
            item.shoppingCartItemId,
            item.price,
            item.productTypeId,
          );
          newTotalPrice += item.price;
          break;
      }
    });
    setTotalCartPrice(newTotalPrice);
  };

  /**
   * Adds a yarn package to the shopping cart. The yarn package
   * is linked to a pattern, and will be deleted from the shopping
   * cart if the pattern is deleted.
   * The function modifies the price of the pattern it is connected
   * to according to the policy.
   * @param yarnItem The yarn item to be added to the shopping cart.
   *
   * @throws Error if the pattern already has a yarn package connected
   * to it.
   */
  const addYarnToShoppingCart = (yarnItem: YarnCartItem): void => {
    // remove old yarn if it exists
    const shoppingCartWithoutOldYarn = removeYarnFromShoppingCart(
      yarnItem.shoppingCartItemId,
    );
    shoppingCartWithoutOldYarn.items.forEach((shoppingCartItem) => {
      const isYarn = shoppingCartItem.productTypeId === ProductType.YARN;
      const idMatches =
        shoppingCartItem.shoppingCartItemId === yarnItem.shoppingCartItemId;

      if (!isYarn && idMatches) {
        modifyPatternPrice(
          yarnItem.shoppingCartItemId,
          SWEATER_PATTERN_PRICE_WITH_YARN,
          shoppingCartItem.productTypeId,
        );

        const shoppingCartItems = shoppingCartWithoutOldYarn.items;

        shoppingCartItems.push(yarnItem);

        const sortedShoppingCartItems =
          sortShoppingCartItems(shoppingCartItems);

        setShoppingCart({ items: sortedShoppingCartItems });
      }
    });
  };

  /**
   * Creates a new pattern in the list of items in the shopping cart and
   * instantly connects a yarn item to it.
   *
   * @param patternItem An object following the ShoppingCartItem interface, omitting
   * the "shoppingCartItemId"
   * @param yarnItem the yarn item to connect to the pattern item
   *
   * * @returns shoppingCartItemId of the newly added pattern
   */
  const addPatternAndYarnItemToShoppingCart = (
    patternItem: PatternItemBeforeCommit,
    yarnItem: YarnItemBeforeCommit,
  ) => {
    const newPatternItem = {
      ...patternItem,
      shoppingCartItemId: nextId,
    };
    const newYarnItem = {
      ...yarnItem,
      shoppingCartItemId: nextId,
    };

    setNextId((currentId) => currentId + 1);

    const shoppingCartItems = shoppingCart.items;
    shoppingCartItems.push(newPatternItem);
    shoppingCartItems.push(newYarnItem);

    const sortedShoppingCartItems = sortShoppingCartItems(shoppingCartItems);

    setShoppingCart({ items: sortedShoppingCartItems });

    return newPatternItem.shoppingCartItemId;
  };

  /**
   * Removes a yarn from the shopping cart. The function also modifies
   * the price of the pattern it is connected to according to the policy.
   * @param shoppingCartItemId The ID of the yarn to be removed from the shopping cart.
   */
  const removeYarnFromShoppingCart = (
    shoppingCartItemId: number,
  ): ShoppingCart => {
    const shoppingCartItems = shoppingCart.items;
    const updatedShoppingCartItems = [] as ShoppingCartItem[];

    shoppingCartItems.forEach((shoppingCartItem) => {
      const idMatches =
        shoppingCartItem.shoppingCartItemId === shoppingCartItemId;
      const isPatternItem = shoppingCartItem.productTypeId !== ProductType.YARN;
      const shouldKeepItem = !idMatches || isPatternItem;

      if (shouldKeepItem) updatedShoppingCartItems.push(shoppingCartItem);

      if (isPatternItem && idMatches)
        modifyPatternPrice(
          shoppingCartItemId,
          SWEATER_PATTERN_PRICE_WITHOUT_YARN,
          shoppingCartItem.productTypeId,
        );
    });
    const updatedShoppingCart = { items: updatedShoppingCartItems };
    setShoppingCart(updatedShoppingCart);
    return updatedShoppingCart;
  };

  /**
   * Modifies a yarn package. The function deletes the previous item
   * before inserting a new version with the modifications. If no previous
   * version exists in the list of items, the function creates a new shopping
   * cart item.
   * @param yarnItem The yarn package to be modified.
   */
  const modifyYarn = (yarnItem: YarnCartItem): void => {
    const shoppingCartItems = shoppingCart.items;
    const updatedShoppingCartItems = [] as ShoppingCartItem[];

    shoppingCartItems.forEach((shoppingCartItem) => {
      const idMatches =
        shoppingCartItem.shoppingCartItemId === yarnItem.shoppingCartItemId;
      const isPattern =
        shoppingCartItem.productTypeId === ProductType.SWEATERPATTERN ||
        shoppingCartItem.productTypeId === ProductType.HATPATTERN;
      const shouldKeepItem = !idMatches || isPattern;

      if (shouldKeepItem) updatedShoppingCartItems.push(shoppingCartItem);
    });

    updatedShoppingCartItems.push(yarnItem);

    const sortedShoppingCartItems = sortShoppingCartItems(
      updatedShoppingCartItems,
    );

    setShoppingCart({ items: sortedShoppingCartItems });
  };

  /**
   * Modifies the skein quantity for the given yarn. This updates
   * the shopping cart item list and triggers rerenders of any
   * component subscribing to the shopping cart context.
   * The total price of the yarn item is also updated.
   *
   * @param skeins an object containing the number of skeins
   * in the main yarn and in the optional secondary yarn.
   * If secondary yarn is undefined, the secondary skein is 0.
   * @param shoppingCartItemId the ID of the shopping cart item
   * to modify. If ID doesn't exist or doesn't point to a yarn
   * item the function returns
   * @param shouldUpdateOriginal a boolean indicating whether the original amount
   * of skeins should be updated.
   */

  // Currently shouldUpdateOriginal is only used when it is re-calculated,
  // opposed to when the user manually updates the skein quantity in the SkeinSelector
  const modifySkeinQuantity = (
    skeins: MainAndSecondarySkeins,
    shoppingCartItemId: number,
    shouldUpdateOriginal: boolean,
  ) => {
    const yarnItem = findYarnItem(shoppingCart.items, shoppingCartItemId);

    if (yarnItem === undefined) return;

    const yarnItemDetails = yarnItem.productDetails;
    const newYarnProductDetails: YarnItemDetails = {
      variantYarn: {
        ...yarnItemDetails.variantYarn,
        quantity: skeins.main,
        originalQuantity: shouldUpdateOriginal
          ? skeins.main
          : yarnItemDetails.variantYarn.originalQuantity,
      },
    };
    let totalPrice =
      newYarnProductDetails.variantYarn.price *
      newYarnProductDetails.variantYarn.quantity;

    if (yarnItemDetails.secondVariantYarn !== undefined) {
      newYarnProductDetails.secondVariantYarn = {
        ...yarnItemDetails.secondVariantYarn,
        quantity: skeins.secondary,
        originalQuantity: shouldUpdateOriginal
          ? skeins.secondary
          : yarnItemDetails.secondVariantYarn.originalQuantity,
      };
      totalPrice +=
        newYarnProductDetails.secondVariantYarn.price *
        newYarnProductDetails.secondVariantYarn.quantity;
    }

    const newYarnItem = {
      ...yarnItem,
      price: totalPrice,
      productDetails: newYarnProductDetails,
    };

    modifyYarn(newYarnItem);
  };

  const getShoppingCartSize = () => shoppingCart.items.length;

  return (
    <ShoppingCartContext.Provider
      value={{
        shoppingCart,
        addPatternToShoppingCart,
        removePatternFromShoppingCart,
        modifyPattern,
        addYarnToShoppingCart,
        removeYarnFromShoppingCart,
        modifyYarn,
        addPatternAndYarnItemToShoppingCart,
        modifySkeinQuantity,
        getShoppingCartSize,
        totalCartPrice,
        isOpen,
        setIsOpen,
        updateShoppingCartPrices,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
