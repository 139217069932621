import { HandleClickSweaterData } from "components/Design/DesignAccordion/SweaterAccordion";
import { useEffect, useState } from "react";
import { useDesign } from "store/DesignContext";
import {
  Sweater,
  SweaterKeys,
  SweaterNeckline,
} from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import "./NecklineSelectionBox.scss";
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { validNecklines } from "utils/designUtils";

interface NecklineSelectionBoxProps {
  handleClick: HandleClickSweaterData;
}

const NecklineSelectionBox = (props: NecklineSelectionBoxProps) => {
  const { design, updateDesign } = useDesign();
  const useEffectDependencies = [
    SweaterKeys.NECKLINE in design.designChoices
      ? design.designChoices.neckline
      : "",
  ];

  const [activeClassNeckline, setActiveClassNeckline] = useState(
    SweaterKeys.NECKLINE in design.designChoices
      ? design.designChoices.neckline
      : "",
  );

  useEffect(() => {
    if (design.patternType === ProductType.SWEATERPATTERN)
      setActiveClassNeckline(design.designChoices.neckline);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);

  if (design.patternType !== ProductType.SWEATERPATTERN) return <></>;

  const necklineOptions = createSelectionOptions<Sweater>({
    keys: Object.values(SweaterNeckline),
    dispatchFunction: updateDesign,
    designChoiceCategory: SweaterKeys.NECKLINE,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassNeckline,
    translationMapper: (key) =>
      mapKeyToTranslationKey(key, SweaterKeys.NECKLINE),
    isInvalid: (key: any) =>
      !validNecklines(design.designChoices).includes(key),
  });

  return (
    <SelectionContainer>
      <SelectionOptionsBox
        options={necklineOptions}
        activeOption={activeClassNeckline}
        displayType="column"
      />
    </SelectionContainer>
  );
};

export default NecklineSelectionBox;
