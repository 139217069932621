import { useState } from "react";
import { TextField } from "@mui/material";
import "./SkeinSelector.scss";

interface SkeinSelectorProps {
  skeins: number;
  setSkeins: (skeins: number) => void;
}

const SkeinSelector = (props: SkeinSelectorProps) => {
  const { skeins, setSkeins } = props;
  const [skeinString, setSkeinString] = useState(skeins.toString());

  const MAX_SKEINS = 99;
  const MIN_SKEINS = 1;

  function updateTextField(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    const inputSkeins = event.target.value;
    const regexp = new RegExp(/^([1-9][0-9]?)?$/g);

    if (regexp.test(inputSkeins)) {
      setSkeinString(inputSkeins);

      if (Number.isNaN(parseInt(inputSkeins))) {
        setSkeins(0);
      } else {
        setSkeins(parseInt(inputSkeins));
      }
    }
  }

  function onBlur(
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) {
    if (event.target.value === "") {
      setSkeins(1);
      setSkeinString("1");
    }
  }

  function decrementSkeins() {
    const newVal = Math.max(skeins - 1, 1);
    setSkeins(newVal);
    setSkeinString(newVal.toString());
  }

  function incrementSkeins() {
    const newVal = Math.min(skeins + 1, 99);
    setSkeins(newVal);
    setSkeinString(newVal.toString());
  }
  // Called on re-render. In case skeins are restored, set the skeinString to the skeins value.
  function resetSkeins() {
    if (skeins.toString() !== skeinString) {
      setSkeinString(skeins.toString());
    }
  }

  resetSkeins();

  return (
    <div className="skeinselector-container">
      <button
        className="skeinselector-button"
        disabled={skeins === MIN_SKEINS}
        onClick={() => decrementSkeins()}
      >
        -
      </button>
      <TextField
        className="skeinselector-input"
        sx={{ margin: "0 3px" }}
        value={skeinString}
        onChange={updateTextField}
        onBlur={onBlur}
      />
      <button
        className="skeinselector-button"
        disabled={skeins === MAX_SKEINS}
        onClick={() => incrementSkeins()}
      >
        +
      </button>
    </div>
  );
};

export default SkeinSelector;
