export const getGaLinkerUrlParam = () => {
  /**
   * Gets the _gl linker parameter that is needed for cross domain tracking
   * @returns null if no _gl parameter in url. If _gl parameter in url
   * then it returns _gl=gl_value.
   */
  const urlParams = new URLSearchParams(window.location.search);
  const ga_linker = urlParams.get("_ga");
  if (ga_linker !== null) {
    const ga_linker_string = `_ga=${ga_linker}`;
    return ga_linker_string;
  }
  return null;
};

export const getOnlyGaLinkerString = () => {
  /**
   * Gets the _ga linker parameter that is needed for cross domain tracking
   * @returns an empty string if there is no ga linker parameter or a string starting with ? and then _gl=xxx if
   * there is a ga linker parameter.
   */
  const ga_linker_string = getGaLinkerUrlParam();
  if (ga_linker_string === null) return "";
  return "?" + ga_linker_string;
};

export const getSearchStringWithGaLinker = (search: string | undefined) => {
  /**
   *  This function is used to append the gl linker parameter to the search string.
   * Useful for the designviews.
   * @param search is the search string in the url.
   * @returns the search string if no _gl parameter in url. If _gl parameter in url
   * then it returns the search string with appended _gl=gl_value.
   */
  let searchString = search ?? "";
  const ga_linker_string = getGaLinkerUrlParam();
  //Done to avoid adding the _gl param if it is already a part of the search string
  if (!searchString.includes("_ga=") && ga_linker_string !== null) {
    if (searchString.length === 0) {
      searchString = "?" + ga_linker_string;
    } else {
      searchString = searchString + "&" + ga_linker_string;
    }
  }
  return searchString;
};
