import React from "react";
import { Link } from "react-router-dom";
import ColorButton from "components/Other/ColorButton/ColorButton";
import "./cancelView.scss";
import { useTranslation } from "react-i18next";

const CancelView = () => {
  const { t } = useTranslation();

  return (
    <div className="cancelView">
      <h1>{t("cancel.title")}</h1>
      <p>
        {t("cancel.content")}{" "}
        <a href="mailto:hello@dreamknit.no">hello@dreamknit.no</a>{" "}
      </p>
      <Link to="/payment">
        <ColorButton>{t("cancel.button")}</ColorButton>
      </Link>
    </div>
  );
};

export default CancelView;
