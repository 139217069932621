export enum GloveSize {
  BABY = "baby",
  KIDS = "kids",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
}

export enum GloveDecreases {
  REGULAR = "regular",
  SPIRAL = "spiral",
}

export enum GloveCuff {
  NO = "no",
  SINGLE = "single",
  DOUBLE = "double",
}

export enum GloveBodyStructure {
  STOCKINETTE = "stockinette",
  ONE_BY_ONE = "1x1",
  TWO_BY_TWO = "2x2",
}

export enum GloveCuffStructure {
  STOCKINETTE = "stockinette",
  ONE_BY_ONE = "1x1",
  TWO_BY_TWO = "2x2",
}

export enum HatSize {
  BABY = "baby",
  KIDS = "kids",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
}

export enum HatDecreases {
  SPIRAL = "spiral",
  CROSS = "cross",
  SIDE = "side",
}

export enum HatBrim {
  NO = "no",
  SINGLE = "single",
  DOUBLE = "double",
}

export enum HatBrimStructure {
  STOCKINETTE = "stockinette",
  ONE_BY_ONE = "1x1",
  TWO_BY_TWO = "2x2",
}

export enum HatBodyStructure {
  STOCKINETTE = "stockinette",
  ONE_BY_ONE = "1x1",
  TWO_BY_TWO = "2x2",
}
