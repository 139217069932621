import { HatAccordion } from "components/Design/DesignAccordion/HatAccordion";
import { SweaterAccordion } from "components/Design/DesignAccordion/SweaterAccordion";
import { Design } from "store/DesignContext";
import { CalculateYardageRequest } from "types/APITypes";
import {
  Hat,
  HatBodyStructure,
  HatBrim,
  HatBrimStructure,
} from "types/DesignChoices/Hat";
import {
  Sweater,
  SweaterConstruction,
  SweaterNeckline,
  SweaterSleeve,
} from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import { DesignYarn } from "types/Yarn";

const validHatBrims = (design: Hat): HatBrim[] => {
  if (
    design.bodyStructure === HatBodyStructure.STOCKINETTE &&
    design.brimStructure === HatBrimStructure.STOCKINETTE
  ) {
    return [HatBrim.NO, HatBrim.DOUBLE];
  }
  return Object.values(HatBrim);
};

const validHatBrimStructures = (design: Hat): HatBrimStructure[] => {
  if (design.bodyStructure === HatBodyStructure.STOCKINETTE)
    return Object.values(HatBrimStructure);
  return [];
};

const validNecklines = (design: Sweater): SweaterNeckline[] => {
  if (design.construction === SweaterConstruction.RAGLAN)
    return [
      SweaterNeckline.MOCKNECK,
      SweaterNeckline.REGULAR,
      SweaterNeckline.TURTLENECK,
    ];
  return Object.values(SweaterNeckline);
};

const validSleeves = (design: Sweater): SweaterSleeve[] => {
  if (design.construction === SweaterConstruction.RAGLAN)
    return [
      SweaterSleeve.REGULAR,
      SweaterSleeve.BALLOON,
      SweaterSleeve.BOXY,
      SweaterSleeve.SHORT,
    ];
  return Object.values(SweaterSleeve);
};

const validConstructions = (design: Sweater): SweaterConstruction[] => {
  if (
    design.neckline === SweaterNeckline.VNECK ||
    design.sleeve === SweaterSleeve.NOSLEEVE
  )
    return [SweaterConstruction.SETIN];
  return Object.values(SweaterConstruction);
};

const createAccordion = (
  design: Design,
  setShowYarnErrorMessage: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  switch (design.patternType) {
    case ProductType.SWEATERPATTERN:
      return (
        <SweaterAccordion setShowYarnErrorMessage={setShowYarnErrorMessage} />
      );
    case ProductType.HATPATTERN:
    default:
      return <HatAccordion setShowYarnErrorMessage={setShowYarnErrorMessage} />;
  }
};

const createCalculateYardageData = (
  design: Design,
  yarn: DesignYarn,
): CalculateYardageRequest | undefined => {
  switch (design.patternType) {
    case ProductType.SWEATERPATTERN: {
      const { designChoices } = design;

      return {
        size: designChoices.size,
        construction: designChoices.construction,
        bodyLength: designChoices.bodyLength,
        bodyWidth: designChoices.bodyWidth,
        neckline: designChoices.neckline,
        sleeve: designChoices.sleeve,
        yardage: yarn.yardage,
        secondYardage: yarn.secondYardage,
        gaugeWidth: yarn.gaugeWidth,
        needlesize: yarn.needlesize,
        productTypeId: ProductType.SWEATERPATTERN,
      };
    }
    case ProductType.HATPATTERN: {
      const { designChoices } = design;

      return {
        size: designChoices.size,
        decreases: designChoices.decreases,
        brim: designChoices.brim,
        brimStructure: designChoices.brimStructure,
        bodyStructure: designChoices.bodyStructure,
        yardage: yarn.yardage,
        secondYardage: yarn.secondYardage,
        gaugeWidth: yarn.gaugeWidth,
        needlesize: yarn.needlesize,
        productTypeId: ProductType.HATPATTERN,
      };
    }
  }
};

const createUpdateYardageDependencies = (design: Design) => {
  switch (design.patternType) {
    case ProductType.SWEATERPATTERN: {
      const { designChoices } = design;

      return [
        designChoices.size,
        designChoices.bodyLength,
        designChoices.bodyWidth,
        designChoices.construction,
        designChoices.neckline,
        designChoices.sleeve,
      ];
    }
    case ProductType.HATPATTERN: {
      const { designChoices } = design;

      return [
        designChoices.size,
        designChoices.bodyStructure,
        designChoices.brim,
        designChoices.brimStructure,
        designChoices.decreases,
      ];
    }
    default:
      return [];
  }
};

export {
  validHatBrims,
  validHatBrimStructures,
  validNecklines,
  validSleeves,
  validConstructions,
  createAccordion,
  createCalculateYardageData,
  createUpdateYardageDependencies,
};
