import { useTranslation } from "react-i18next";
import CollapsibleInformationContainer from "./CollapsibleInformationContainer";
import { Container } from "@mui/material";

interface CollapsibleLogicProps {
  isOpen: boolean;
  toggleOpen: () => void;
}

const CollapsibleShippingInformation = (props: CollapsibleLogicProps) => {
  const { isOpen, toggleOpen } = props;
  const { t } = useTranslation();
  return (
    <CollapsibleInformationContainer
      title={t("yarndb.detailedYarnView.shippingInformation")}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
    >
      <Container
        className="collapsed-content"
        data-cy="collapsed-content-shipping"
      >
        <p>
          Garnet leveres i samarbeid med Un-told, og sendes direkte fra deres
          lager.
        </p>
        <h4 className="shipping-subtitle">FRAKTKOSTNAD</h4>
        <ul>
          <li>Pakke i postkassen 89 kr</li>
          <li>Gratis frakt over 1000 kr</li>
          <li>Gratis pick-up</li>
        </ul>
        <h4 className="shipping-subtitle">LEVERINGSTID</h4>
        <p>
          Forventet leveringstid er 1-5 virkedager, så lenge alle varer er
          tilgjengelig på lager. Bestillingern leveres med Posten/Bring, og er
          tilgjengelig for bostedsadresser i Norge. Vi sender dessverre ikke til
          Svalbard og Jan Mayen.
        </p>
        <h4 className="shipping-subtitle">LEVERING VIA POSTEN, BRING</h4>
        <p>
          Du mottar en kvittering på kjøpet med et ordrenummer fra Un-told. Når
          pakken er klar for henting vil du motta en SMS eller e-post fra
          Posten/Bring med informasjon om utleveringssted og hentekode.
        </p>
        <h4 className="shipping-subtitle">RETUR OG REKLAMASJON</h4>
        <p>
          Les mer om retur og reklamasjon{" "}
          <a
            href={`${process.env.PUBLIC_URL}/Salgsbetingelser.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            her
          </a>
          .
        </p>
      </Container>
    </CollapsibleInformationContainer>
  );
};

export default CollapsibleShippingInformation;
