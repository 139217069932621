import "./SizeGuide.scss";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function createData(size: string, headCircumference: string) {
  return { size, headCircumference };
}

const HatSizeGuide = () => {
  const { t } = useTranslation();
  const StyledTableCell = styled(TableCell)({
    padding: 5,
    width: "25%",
  });
  const StyledTableCellSmall = styled(TableCell)({
    padding: 5,
    width: "15%",
  });

  const rows = [
    createData(t("design.designAccordion.hatSize.baby"), "46-48 cm"),
    createData(t("design.designAccordion.hatSize.kids"), "49-52 cm"),
    createData(t("design.designAccordion.hatSize.s"), "53-54 cm"),
    createData(t("design.designAccordion.hatSize.m"), "55-57 cm"),
    createData(t("design.designAccordion.hatSize.l"), "58-59 cm"),
    createData(t("design.designAccordion.hatSize.xl"), "60-62 cm"),
  ];

  return (
    <div className="sizeGuideContainer">
      <TableContainer className="table">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCellSmall>
                {t("design.designAccordion.hatSize.sizeGuideTable.size")}
              </StyledTableCellSmall>
              <StyledTableCell>
                {t(
                  "design.designAccordion.hatSize.sizeGuideTable.headCircumference",
                )}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.size}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCellSmall>{row.size}</StyledTableCellSmall>
                <StyledTableCell>{row.headCircumference}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HatSizeGuide;
