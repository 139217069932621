import { useState } from "react";
import { Redirect } from "react-router-dom";
import { Language } from "types/Language";
import { FormControl, MenuItem, Select } from "@mui/material";
import { ReactComponent as NoFlag } from "assets/flags/no.svg";
import { ReactComponent as UsFlag } from "assets/flags/us.svg";
import "./LanguageDropdown.scss";

interface LanguageDropdownProps {
  languages: Language[];
  activeLanguage: Language;
  setLanguageAndGetPathname: (lang: Language) => string;
}

const LanguageDropdown = (props: LanguageDropdownProps) => {
  const { languages, activeLanguage, setLanguageAndGetPathname } = props;
  const [redirectPath, setRedirectPath] = useState<string>();
  const lanugagesInDropDown = languages.filter(
    (language) => language !== activeLanguage,
  );

  function renderRedirect() {
    if (redirectPath === undefined) return;
    return <Redirect to={redirectPath} />;
  }

  return (
    <FormControl>
      <Select
        value={activeLanguage}
        onChange={(event) => {
          const pathname = setLanguageAndGetPathname(
            event.target.value as Language,
          );
          setRedirectPath(pathname);
        }}
        renderValue={(selected) => {
          return <LanguageOption language={activeLanguage} />;
        }}
        sx={{
          "& .MuiInputBase-input": {
            borderRadius: "0",
            height: "39px !important",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            alignItems: "center",
          },
          border: "none",
          outline: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          fontSize: "13px",
          fontFamily: "Brut Grotesque Book",
        }}
      >
        {lanugagesInDropDown.map((language) => (
          <MenuItem
            key={language}
            value={language}
            sx={{
              fontSize: "13px",
              height: "23px",
              borderRadius: 0,
            }}
          >
            {<LanguageOption language={language} />}
          </MenuItem>
        ))}
      </Select>
      {redirectPath !== undefined && renderRedirect()}
    </FormControl>
  );
};

const LanguageOption = (props: { language: Language }) => {
  const { language } = props;

  function getFlagSvg() {
    switch (language) {
      case Language.NO:
        return <NoFlag className="flag language-picker-flag" />;
      case Language.EN:
      default:
        return <UsFlag className="flag language-picker-flag" />;
    }
  }

  function getLanguageText(language: Language) {
    switch (language) {
      case Language.NO:
        return "Norsk (bokmål)";
      case Language.EN:
      default:
        return "English";
    }
  }

  return (
    <div className="language-option-container">{getLanguageText(language)}</div>
  );
};

export default LanguageDropdown;
