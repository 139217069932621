import { AxiosError } from "axios";
import { appInsights } from "utils/applicationInsights";

function logApiError(
  endpoint: string,
  requestBody: any,
  error: Error,
  status?: number,
): void {
  appInsights.trackException(
    { exception: error },
    { endpoint, request: requestBody, status },
  );
}

function logError(error: Error): void {
  appInsights.trackException({ exception: error });
}

function checkStatusAndLogApiError(
  endpoint: string,
  error: AxiosError,
  request: any,
): void {
  const status = error.response?.status;

  logApiError(endpoint, request, error, status);
}

export { checkStatusAndLogApiError, logError };
