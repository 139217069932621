import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";
import { YarnMaterialResponse } from "types/APITypes";

export async function fetchYarnMaterials(): Promise<YarnMaterialResponse[]> {
  return api
    .get<YarnMaterialResponse[]>(APIPath.GET_YARN_MATERIALS)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatusAndLogApiError(
        APIPath.GET_YARN_MATERIALS,
        error,
        error.config,
      );
      throw error;
    });
}
