import { useState } from "react";
import { useCheckout } from "./CheckoutContext";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getEmailShippingInformationFormValues,
  getMailShippingInformationFormValues,
  getPickupShippingInformationFormValues,
  getSelectedDeliveryMethod,
  hasAcceptedTerms,
  isEmailAddressValid,
} from "utils/shippingInformationFormUtils";
import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";
import { addCustomerToOrder } from "utils/api/updateOrderCustomer";
import { getCustomerInfo } from "utils/checkoutUtils";
import Spinner from "components/Other/Loading/Spinner";
import { DeliveryMethodOptions } from "types/Checkout";
import { api } from "utils/api/api";

export default function GiftcardCheckoutForm() {
  const { orderId, currentValue, clientSecret, updateAmountCommitted } =
    useCheckout();
  const { t } = useTranslation();
  const [displayFormError, setDisplayFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorMessageLink, setErrorMessageLink] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();
  const [giftcardCode, setGiftcardCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const GIFTUP_PURCHASE_URL =
    "https://giftup.app/place-order/0fc1d24c-f8ba-4174-b040-08d9f05bd3da?platform=Hosted&productId=0ff038e2-9ca1-47f0-98b5-08dabacbc9e4&language=no-NO";

  const getGiftcardUnits = async () => {
    const code = (document.getElementById("giftcard_code") as HTMLInputElement)
      ?.value;

    api
      .get(`${APIPath.GET_GIFTCARD_BALANCE}/${code.toUpperCase()}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (res) => {
        const response = await res.data;
        if (res.status === 200) {
          const value: number = response.remainingValue;
          if (value === 0) {
            setSuccessMessage("");
            setErrorMessage(
              t("checkout.checkoutForm.giftcard.balanceEmpty") ?? "",
            );
          } else {
            setErrorMessage("");
            setSuccessMessage(
              t("checkout.checkoutForm.giftcard.balanceNotEmpty", {
                remainingValue: value,
              }) ?? "",
            );
          }
        }
      })
      .catch((error) => {
        const status = error.response.status;
        checkStatusAndLogApiError(
          `${process.env.REACT_APP_API_URL}${
            APIPath.GET_GIFTCARD_BALANCE
          }/${code.toUpperCase()}`,
          error,
          undefined,
        );

        if (status === 404) {
          if (error.response.data.error === "Giftcard invalid") {
            setErrorMessage(t("checkout.errorMessage.giftCardApiError") ?? "");
            setErrorMessageLink(GIFTUP_PURCHASE_URL);
            setSuccessMessage("");
          } else if (error.response.data.error === "Giftcard does not exist") {
            setErrorMessage(t("checkout.errorMessage.giftCardNotExist") ?? "");
            setErrorMessageLink("");
            setSuccessMessage("");
          }
        } else {
          setErrorMessage(t("checkout.errorMessage.giftCardApiError") ?? "");
          setErrorMessageLink(GIFTUP_PURCHASE_URL);
          setSuccessMessage("");
        }
      });
  };

  const payWithGiftCard = async () => {
    const code = (document.getElementById("giftcard_code") as HTMLInputElement)
      ?.value;

    let userEmail;

    switch (getSelectedDeliveryMethod()) {
      case DeliveryMethodOptions.PICKUP:
        userEmail = getPickupShippingInformationFormValues().email;
        break;
      case DeliveryMethodOptions.MAIL:
        userEmail = getMailShippingInformationFormValues().email;
        break;
      case DeliveryMethodOptions.NO_DELIVERY:
        userEmail = getEmailShippingInformationFormValues().email;
        break;
      default:
        throw new Error(
          `Unknown delivery method: ${getSelectedDeliveryMethod()}`,
        );
    }

    const isValid = isEmailAddressValid(userEmail) && hasAcceptedTerms();

    if (!isValid) {
      setDisplayFormError(true);
      setFormErrorMessage(
        t("checkout.checkoutForm.shipping.errorMessage") ?? "",
      );
      return;
    } else if (isValid && displayFormError) {
      setDisplayFormError(false);
      setFormErrorMessage("");
    }

    setIsLoading(true);

    const selectedDeliveryMethod = getSelectedDeliveryMethod();
    const customerInfo = getCustomerInfo(selectedDeliveryMethod);

    const res = await addCustomerToOrder(
      orderId,
      customerInfo,
      selectedDeliveryMethod,
      clientSecret,
    );

    if (res.status === 200) {
      const data = {
        giftcardCode: code.toUpperCase(),
        orderId: orderId,
        wantsNewsletter: "False",
        clientSecret: clientSecret,
      };

      api
        .post(`${APIPath.REDEEM_GIFTCARD}`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.status === 422) {
            setErrorMessage(t("checkout.errorMessage.giftCardApiError") ?? "");
            setErrorMessageLink(GIFTUP_PURCHASE_URL);
            setSuccessMessage("");
          }
          if (res.status === 200) {
            updateAmountCommitted(res.data.amountCommitted);
            trackEvent(TrackingEvents.PURCHASE, {
              paymentMethod: "giftcard",
              value: currentValue,
            });
            if (
              //Stripe has a minimum charge of 3 NOK. Thus anything within 3 NOK will be considered adequate
              res.data.totalPrice - 3 <= res.data.amountCommitted &&
              res.data.totalPrice >= res.data.amountCommitted
            ) {
              const redirect_url = window.location.href.replace(
                "checkout",
                "success?id=",
              );
              window.location.href = redirect_url + orderId;
            }
          }
        })
        .catch((error) => {
          checkStatusAndLogApiError(
            `${process.env.REACT_APP_API_URL}${APIPath.REDEEM_GIFTCARD}`,
            error,
            data,
          );
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="giftcard-checkout-form"
      >
        <div className="giftcard-checkout-form-giftcard-code-container">
          <div>
            <label className="text-s">
              {t("checkout.checkoutForm.giftcard.addGiftCard")}
            </label>
            <input
              type="text"
              id="giftcard_code"
              required
              value={giftcardCode}
              onInvalid={(e) =>
                (e.target as HTMLInputElement).setCustomValidity(
                  t("checkout.checkoutForm.emailAddress"),
                )
              }
              onInput={(e) =>
                (e.target as HTMLInputElement).setCustomValidity("")
              }
              onChange={(e) => {
                setGiftcardCode(e.target.value);
              }}
            />
            {successMessage && (
              <p style={{ color: "green", fontSize: "0.75rem" }}>
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p style={{ color: "red", fontSize: "0.75rem" }}>
                {errorMessage}
                {"\u00A0"}
                {errorMessageLink && (
                  <a
                    href={errorMessageLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "red",
                      textDecoration: "underline",
                      fontSize: "0.75rem",
                    }}
                  >
                    {t("checkout.checkoutForm.giftcard.here")}
                  </a>
                )}
              </p>
            )}
          </div>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            fullWidth
            sx={{ color: "black", borderColor: "primary.dark" }}
            style={{ marginLeft: "0px" }}
            onClick={getGiftcardUnits}
          >
            {t("checkout.checkoutForm.giftcard.checkBalance")}
          </Button>
        </div>
        <div className="giftcard-checkout-form-giftcard-price-and-payment-container">
          <Button
            size="small"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ "&:hover": { backgroundColor: "primary.light" } }}
            onClick={payWithGiftCard}
          >
            <span id="button-text">
              {isLoading ? (
                <Spinner color="black" />
              ) : (
                t("checkout.checkoutForm.giftcard.redeem")
              )}
            </span>
          </Button>
          {formErrorMessage && (
            <div className="payment-error-message text-s">
              {formErrorMessage}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
