import { Language } from "types/Language";
import { getLanguage } from "./intl/languageUtil";
import { ProductType, ShoppingCart } from "types/ShoppingCart";

const isBuyingYarn = (shoppingCart: ShoppingCart) =>
  shoppingCart.items.some((item) => item.productTypeId === ProductType.YARN);

export const isNorwegianAndIsBuyingYarn = (shoppingCart: ShoppingCart) =>
  getLanguage() === Language.NO && isBuyingYarn(shoppingCart);

export const requiresDeliveryAndShippingForm = (shoppingCart: ShoppingCart) =>
  isBuyingYarn(shoppingCart);
