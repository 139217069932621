import ClearIcon from "@mui/icons-material/Clear";
import PatternItem from "components/shoppingcart/PatternItem";
import YarnItem from "components/shoppingcart/YarnItem";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import { shoppingCartItemHeight } from "styles/constants";
import { Language } from "types/Language";
import { ProductType, ShoppingCartItem } from "types/ShoppingCart";
import { convertFromNOKToCurrency, getConversionRateUSD } from "utils/currency";
import { useParentElementHeight } from "utils/hooks";
import { getLanguage } from "utils/intl/languageUtil";
import { requiresDeliveryAndShippingForm } from "utils/requireDeliveryAndShippingForm";

function fit_highest_multiplum_within_parent(
  parentHeight,
  multiplum,
  numItems,
) {
  if (parentHeight < multiplum) return "auto";
  if (parentHeight > multiplum * numItems) return parentHeight;
  const one_third =
    Math.floor((parentHeight - multiplum / 3) / multiplum) * multiplum +
    multiplum / 3;
  const two_thirds =
    Math.floor((parentHeight - (multiplum * 1) / 2) / multiplum) * multiplum +
    (multiplum * 1) / 2;
  return Math.max(one_third, two_thirds);
}

/**
 * @returns JSX.Element - A component rendering
 * - the title of the shopping cart
 * - all shopping cart items
 * - the total price of the shopping cart
 *
 */
export const ShoppingCartItemsAndTotalPrice = ({
  inCheckout,
  editable,
  removable,
  amountCommitted,
  shippingPrice,
}: {
  inCheckout: boolean;
  editable: boolean;
  removable: boolean;
  amountCommitted?: number;
  shippingPrice?: number;
}): JSX.Element => {
  const { shoppingCart, totalCartPrice, setIsOpen } = useShoppingCart();
  const { t } = useTranslation();
  const itemSectionRef = useRef(null);

  const renderShoppingCartItem = (item: ShoppingCartItem) => {
    const reactKey = `${item.shoppingCartItemId}-${item.productTypeId}`;
    switch (item.productTypeId) {
      case ProductType.YARN:
        return (
          <div className="shopping-cart-item-outer-container" key={reactKey}>
            <YarnItem
              key={reactKey}
              shoppingCartItemId={item.shoppingCartItemId}
              removable={removable}
              editSkeins={editable}
            />
          </div>
        );
      case ProductType.SWEATERPATTERN:
      case ProductType.HATPATTERN:
      default:
        return (
          <div className="shopping-cart-item-outer-container" key={reactKey}>
            <PatternItem
              key={reactKey}
              unit="metric"
              shoppingCartItemId={item.shoppingCartItemId}
              editable={editable}
              removable={removable}
            />
          </div>
        );
    }
  };

  return (
    <div
      id="item-section"
      className={`${inCheckout && "checkout-item-section"}`}
    >
      <div id="shopping-cart-top-section">
        <h2 className="shopping-cart-title">{t("shoppingCart.title")}</h2>
        {editable && (
          <button
            className="close-shopping-cart-button"
            onClick={() => setIsOpen(false)}
          >
            <ClearIcon fontSize="large" />
          </button>
        )}
      </div>
      <div
        className="shopping-cart-item-section"
        style={{
          maxHeight: `${fit_highest_multiplum_within_parent(
            useParentElementHeight(itemSectionRef) - 90,
            shoppingCartItemHeight,
            shoppingCart.items.length,
          )}px`,
        }}
        ref={itemSectionRef}
      >
        {shoppingCart.items.map(renderShoppingCartItem)}
      </div>
      <TotalPrice
        shoppingCartPrice={totalCartPrice}
        inCheckout={inCheckout}
        shippingPrice={shippingPrice}
        amountCommitted={amountCommitted}
      />
    </div>
  );
};

interface TotalPriceProps {
  shoppingCartPrice: number;
  inCheckout: boolean;
  amountCommitted?: number;
  shippingPrice?: number;
}

const TotalPrice = (props: TotalPriceProps) => {
  const { shoppingCartPrice, inCheckout, shippingPrice, amountCommitted } =
    props;
  const { shoppingCart } = useShoppingCart();
  const { t } = useTranslation();
  const [conversionRateUSD, setConversionRateUSD] = useState<number>();
  const [renderShippingPrice, setRenderShippingPrice] = useState<boolean>();

  useEffect(() => {
    getConversionRateUSD().then(setConversionRateUSD);
  }, []);

  useEffect(() => {
    if (inCheckout && requiresDeliveryAndShippingForm(shoppingCart)) {
      setRenderShippingPrice(true);
    }
  }, [inCheckout, shoppingCart]);

  const displayAmountNok = t("checkout.orderSummary.currentPrice", {
    price: Math.round(
      renderShippingPrice
        ? shoppingCartPrice + (shippingPrice ?? 0) - (amountCommitted ?? 0)
        : shoppingCartPrice - (amountCommitted ?? 0),
    ),
    currency: t("checkout.currency"),
  });

  const displayAmountForeign =
    conversionRateUSD !== undefined
      ? t("checkout.orderSummary.equivalentPrice") +
        " " +
        convertFromNOKToCurrency(
          renderShippingPrice
            ? shoppingCartPrice + (shippingPrice ?? 0) - (amountCommitted ?? 0)
            : shoppingCartPrice - (amountCommitted ?? 0),
          conversionRateUSD,
          1,
        )
      : "";

  const shouldRenderGiftcardReduction =
    amountCommitted !== undefined && amountCommitted > 0;

  const displayAmountGiftcard = shouldRenderGiftcardReduction
    ? t("checkout.orderSummary.currentPrice", {
        price: -amountCommitted,
        currency: t("checkout.currency"),
      })
    : "";

  const displayAmountGiftcardForeign =
    shouldRenderGiftcardReduction && conversionRateUSD !== undefined
      ? t("checkout.orderSummary.equivalentNegativePrice") +
        convertFromNOKToCurrency(amountCommitted, conversionRateUSD, 1)
      : "";

  return (
    <div className="total-price-section-container">
      {renderShippingPrice && (
        <div className="total-price-shipping-price-section">
          <p>{t("checkout.orderSummary.shipping")} </p>{" "}
          <p>
            {shippingPrice ?? 0} {t("checkout.currency")}{" "}
          </p>
        </div>
      )}
      {/* {inCheckout && amountCommitted !== undefined && amountCommitted > 0 && (
        <div className="total-price-shipping-price-section">
          <p>{t("checkout.orderSummary.giftcard")} </p>{" "}
          <p>
            - {amountCommitted} {t("checkout.currency")}{" "}
          </p>
        </div>
      )} */}
      {shouldRenderGiftcardReduction && (
        <div className="total-price-section">
          <p>{t("checkout.checkoutForm.giftcard.title")}</p>
          <p>
            {displayAmountGiftcard}{" "}
            {conversionRateUSD &&
              getLanguage() === Language.EN &&
              displayAmountGiftcardForeign}
          </p>
        </div>
      )}
      <div className="total-price-section">
        <p>
          {t("checkout.orderSummary.total")} {t("checkout.orderSummary.vat")}
        </p>
        <p>
          {displayAmountNok}{" "}
          {conversionRateUSD &&
            getLanguage() === Language.EN &&
            displayAmountForeign}
        </p>
      </div>
    </div>
  );
};
