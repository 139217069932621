import { t } from "i18next";
import ShoppingCartItem from "components/shoppingcart/ShoppingCartItem";
import mapSweaterSizeAndOverwidthToCm from "utils/mapSweaterSizeAndOverwidthToCm";
import { SweaterBodyWidth } from "types/DesignChoices/Sweater";
import { useShoppingCart } from "store/ShoppingCartContext";
import {
  SessionStoragePatternId,
  findPatternItem,
} from "utils/shoppingCartUtils";
import { mapLanguageEnumToVerbose, sizeCasing } from "utils/intl/languageUtil";
import { ProductType } from "types/ShoppingCart";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

interface PatternItemProps {
  unit: string;
  shoppingCartItemId: number;
  editable?: boolean;
  removable?: boolean;
}

const PatternItem = (props: PatternItemProps) => {
  const { shoppingCartItemId, editable = false, removable = false } = props;
  const { removePatternFromShoppingCart, shoppingCart } = useShoppingCart();

  const removePatternItemFromShoppingCart = (shoppingCartItemId: number) => {
    const patternId = SessionStoragePatternId.get();
    if (patternId !== null && patternId === shoppingCartItemId) {
      SessionStoragePatternId.remove();
    }
    removePatternFromShoppingCart(shoppingCartItemId);
  };

  const patternItem = findPatternItem(shoppingCart.items, shoppingCartItemId);
  if (patternItem === undefined) {
    return null;
  }
  const patternItemDetails = patternItem.productDetails;
  const { illustration, price } = patternItem;
  const { yarn, language } = patternItemDetails;

  const getPatternItemDescription = () => {
    switch (patternItem.productTypeId) {
      case ProductType.SWEATERPATTERN:
        const { pattern: sweaterPattern } = patternItem.productDetails;
        return (
          <p className="text-s">
            {sweaterPattern.bodyWidth === SweaterBodyWidth.REGULAR
              ? t("design.designAccordion.fit.regularFit")
              : t("design.designAccordion.fit.oversizedFit")}{" "}
            ({t("checkout.orderSummary.sweaterInformation.bustCircumference")}{" "}
            {mapSweaterSizeAndOverwidthToCm(
              sweaterPattern.size,
              sweaterPattern.bodyWidth,
            )}{" "}
            cm)
          </p>
        );
      case ProductType.HATPATTERN:
        return <></>;
    }
  };

  const ProductDetails = (
    <>
      <p className="text-s">
        {t("checkout.orderSummary.sweaterInformation.size", {
          size: sizeCasing(patternItemDetails.pattern.size),
        })}
      </p>

      <p className="text-s">
        {t("shoppingCart.language")}: {mapLanguageEnumToVerbose(language)}
      </p>
      <p className="text-s">
        {t("design.designAccordion.yarn.title")}: {yarn.name}
        {yarn.secondName ? `+ ${yarn.secondName}` : ""}
      </p>
      {getPatternItemDescription()}
    </>
  );
  const PriceSection = (
    <>
      <p className="text-s">{t("checkout.orderSummary.pattern")}</p>
      <p className="text-s">
        {Math.round(price)} {t("checkout.currency")}
      </p>
    </>
  );

  return (
    <ShoppingCartItem
      supplier="Dreamknit"
      illustration={illustration}
      productDetails={ProductDetails}
      priceSection={PriceSection}
      shoppingCartItemId={shoppingCartItemId}
      removeItem={() => {
        trackEvent(TrackingEvents.REMOVE_FROM_SHOPPING_CART);
        removePatternItemFromShoppingCart(shoppingCartItemId);
      }}
      editable={editable}
      removable={removable}
    />
  );
};

export default PatternItem;
