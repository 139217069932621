import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDesign } from "store/DesignContext";
import { useShoppingCart } from "store/ShoppingCartContext";
import AppPath from "types/AppPath";
import { Sweater } from "types/DesignChoices/Sweater";
import { SWEATER_PATTERN_PRICE_WITHOUT_YARN } from "utils/constants/ShoppingCartConstants";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import {
  SessionStoragePatternId,
  getCurrentPrice,
} from "utils/shoppingCartUtils";
import ConstructionSelectionBox from "../DesignContainer/SelectionComponents/ConstructionSelectionBox";
import FitSelectionBox from "../DesignContainer/SelectionComponents/FitSelectionBox";
import NecklineSelectionBox from "../DesignContainer/SelectionComponents/NecklineSelectionBox";
import SleeveSelectionBox from "../DesignContainer/SelectionComponents/SleeveSelectionBox";
import YarnSelectionBox from "../DesignContainer/SelectionComponents/YarnSelectionBox";
import { AddToShoppingCartButton } from "./AddToShoppingCartButton";
import GenericAccordion from "./GenericAccordion";
import MobileDesignTab from "./MobileTab/MobileDesignTab";
import "./designAccordion.scss";
import AccordionPrice from "./AccordionPrice";

interface DesignAccordionProps {
  setShowYarnErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export type HandleClickSweaterData = <K extends keyof Sweater>(
  key: K,
  value: Sweater[K],
) => void;

export const SweaterAccordion = (props: DesignAccordionProps) => {
  const { setShowYarnErrorMessage } = props;
  const { design, yarn, yarnIsChosen } = useDesign();
  const { shoppingCart, setIsOpen } = useShoppingCart();
  const { t } = useTranslation();
  const [itemPrice, setItemPrice] = useState(
    SWEATER_PATTERN_PRICE_WITHOUT_YARN,
  );
  const pushToHistory = useDreamknitHistory();
  const location = useLocation();
  const openShoppingCart = () => setIsOpen(true);

  useEffect(() => {
    const patternId = SessionStoragePatternId.get();
    if (patternId === null) {
      setItemPrice(SWEATER_PATTERN_PRICE_WITHOUT_YARN);
      return;
    }

    setItemPrice(getCurrentPrice(shoppingCart.items, patternId));
  }, [design, yarn, shoppingCart]);

  const addQuery = <K extends keyof Sweater>(key: K, value: Sweater[K]) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value.toString());
    pushToHistory({
      pathname: AppPath.SWEATER,
      search: searchParams.toString(),
    });
  };

  const handleSelectOption: HandleClickSweaterData = <K extends keyof Sweater>(
    key: K,
    value: Sweater[K],
  ) => {
    addQuery(key, value);
  };

  const designAccordionContent = [
    {
      component: <FitSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.fit.title"),
    },
    {
      component: <ConstructionSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.construction.title"),
    },
    {
      component: <NecklineSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.neckline.title"),
    },
    {
      component: <SleeveSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.sleeves.title"),
    },
    {
      component: <YarnSelectionBox />,
      title: t("design.designAccordion.yarn.title"),
    },
  ];

  return (
    <div className="designAccordion">
      <GenericAccordion accordionSections={designAccordionContent} />
      <div className="mobileAccordion">
        <MobileDesignTab tabSections={designAccordionContent} />
      </div>
      <div className="add-to-shopping-cart-section">
        <div className="add-to-shopping-cart-section-price">
          <AccordionPrice itemPrice={itemPrice} />
        </div>
        <div
          onClick={() => !yarnIsChosen && setShowYarnErrorMessage(true)}
          className={"add-to-shopping-cart-section-button"}
        >
          <AddToShoppingCartButton
            openShoppingCart={openShoppingCart}
            yarnIsChosen={yarnIsChosen}
          />
        </div>
      </div>
    </div>
  );
};
