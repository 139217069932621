import {
  Collapse,
  Radio,
  useRadioGroup,
  FormControlLabel,
  FormControlLabelProps,
  Paper,
} from "@mui/material";
import "./DkRadioButton.scss";
import { classNames } from "styles/StyleUtils";

interface DkRadioButtonProps
  extends Omit<FormControlLabelProps, "control" | "label" | "title"> {
  label: JSX.Element | string;
  value: string;
  boldLabel?: boolean;
  labelTitle?: JSX.Element;
  inactive?: boolean;
}

/**
 * Component acting as a radio button
 * option in a form.
 * Optionally, will render children when selected.
 */
const DkRadioButton = (props: React.PropsWithChildren<DkRadioButtonProps>) => {
  const {
    label,
    boldLabel = false,
    value,
    children,
    labelTitle,
    inactive,
    ...otherRadioProps
  } = props;
  const radioGroup = useRadioGroup();
  const selectedValue = radioGroup?.value;

  const getLabel = () => {
    const isSelected = selectedValue === value;
    if (!isSelected && labelTitle !== undefined) return labelTitle;

    if (typeof label === "string") {
      return boldLabel ? (
        <p>
          <strong>{label}</strong>
        </p>
      ) : (
        <p>{label}</p>
      );
    }
    return label;
  };

  return (
    <Paper elevation={2} className="dreamknit-radio-button-container">
      <div className={classNames(inactive && "inactive")}>
        <FormControlLabel
          {...otherRadioProps}
          label={getLabel()}
          value={value}
          sx={{
            width: "100%",
            ".MuiFormControlLabel-label": {
              width: "100%",
            },
          }}
          control={
            <Radio
              sx={{ "&.Mui-checked": { color: "black" } }}
              disabled={inactive}
            />
          }
        />
      </div>
      <Collapse in={selectedValue === value} unmountOnExit>
        <div>{children}</div>
      </Collapse>
    </Paper>
  );
};

export default DkRadioButton;
