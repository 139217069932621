import { AxiosResponse } from "axios";
import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";
import {
  CheckoutInitiateRequest,
  CheckoutInitiateResponse,
} from "types/APITypes";

export const CheckoutInitiateApi = {
  post: async function (
    data: CheckoutInitiateRequest,
  ): Promise<AxiosResponse<CheckoutInitiateResponse>> {
    return api.post(APIPath.CHECKOUT_INITIATE, data).catch((error) => {
      checkStatusAndLogApiError(APIPath.CHECKOUT_INITIATE, error, data);
      throw error;
    });
  },
};
