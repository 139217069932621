  /**
   * Pushes an event to the dataLayer to be used for tracking
   * in Google Tag Manager. 
   * @param eventName the name to be given to the event. Must correspond
   * to the name of an exisiting event in GTM.
   * @param eventProps The event props to be associated with the event.
   * if there are no eventProps just do not pass in annything
   */
  export function trackEvent(eventName: string, eventProps?: object): void {
    //Used to flush the eventProps between events with different eventProps
    window.dataLayer.push({ eventProps: undefined });

    window.dataLayer.push({
        event: eventName,
        eventProps: {
            eventProps
            },
    });
}

export enum TrackingEvents {
  PURCHASE = "purchase",
  ADD_PROMOTION_CODE = "addPromotionCode",
  CHOOSE_PRODUCT = "chooseProduct",
  DESIGN_INTERACTION = "designInteraction",
  INITIATE_VIPPS_TRANSACTION = "initiateVippsTransaction",
  SHOW_SIZE_GUIDE = "showSizeGuide",
  CLICK_ON_COLOR = "clickOnColor",
  OPEN_SHOW_COLOR_PICKER = "openShowColorPicker",
  REMOVE_FROM_SHOPPING_CART = "removePatternFromShoppingCart",
  DESIGN_NEW_PATTERN = "designNewPattern",
  EDIT_PATTERN = "editPattern",
  REMOVE_YARN = "removeYarn",
  ADD_TO_SHOPPING_CART = "addToShoppingCart",
  ADD_CUSTOM_YARN = "addCustomYarn",
  VIPPS_PAYMENT_FAILED = "vippsPaymentFailed",
  CHOOSE_YARN = "chooseYarn",
  MORE_YARN_INFO = "moreYarnInfo"
}