import { useEffect } from "react";
import "./successfulPaymentView.scss";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const SuccessfulPaymentView = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf("/no") === -1) {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <div className="successfulPayment">
      <div className="text">
        <h1>{t("status.successful.title")}</h1>
        <Trans i18nKey="status.successful.contentTop">
          <p>
            Du har mottatt en ordrebekreftelse på e-post med link til
            oppskriften. Mailen kan ha havnet i søppelpost.
          </p>
          <p>
            Del gjerne det ferdige resultatet med oss på Instagram under
            #dreamknit.
          </p>
          <br />
        </Trans>
      </div>
    </div>
  );
};

export default SuccessfulPaymentView;
