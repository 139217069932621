import SkeinSelector from "components/Other/Form/SkeinSelector";
import ShoppingCartItem from "components/shoppingcart/ShoppingCartItem";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import { Language } from "types/Language";
import { getLanguage } from "utils/intl/languageUtil";
import { findYarnItem } from "utils/shoppingCartUtils";
import "./ShoppingCartItem.scss";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

interface YarnItemProps {
  shoppingCartItemId: number;
  editSkeins?: boolean;
  removable?: boolean;
}

const YarnItem = (props: YarnItemProps) => {
  const { shoppingCartItemId, editSkeins = false, removable = false } = props;

  const { shoppingCart, modifySkeinQuantity, removeYarnFromShoppingCart } =
    useShoppingCart();
  const { t } = useTranslation();

  const yarn = findYarnItem(shoppingCart.items, shoppingCartItemId);

  const mainYarn = yarn?.productDetails.variantYarn;
  const secondaryYarn = yarn?.productDetails.secondVariantYarn;

  const [skeinsMain, setSkeinsMain] = useState(mainYarn?.quantity ?? 0);
  const [skeinsSecond, setSkeinsSecond] = useState(
    secondaryYarn?.quantity ?? 0,
  );

  if (yarn === undefined || mainYarn === undefined) return null;

  const originalSkeinsMain = mainYarn.originalQuantity;
  const originalSkeinsSecond = secondaryYarn?.originalQuantity;

  const warningMessage =
    getLanguage() === Language.EN
      ? t("design.designAccordion.yarn.yarnSelectionInfo.norwegianShippingOnly")
      : undefined;

  const skeinsAdjustedFromOriginal = () => {
    if (originalSkeinsSecond === undefined || skeinsSecond === 0) {
      return originalSkeinsMain !== skeinsMain;
    } else {
      return (
        originalSkeinsMain !== skeinsMain ||
        originalSkeinsSecond !== skeinsSecond
      );
    }
  };

  const resetSkeinsToOriginal = () => {
    modifySkeinQuantity(
      {
        main: originalSkeinsMain!,
        secondary: originalSkeinsSecond ?? 0,
      },
      shoppingCartItemId,
      true,
    );
    setSkeinsMain(originalSkeinsMain!);
    setSkeinsSecond(originalSkeinsSecond ?? 0);
  };

  const mainPrice = skeinsMain * mainYarn.price;
  const secondPrice = skeinsSecond * (secondaryYarn?.price || 0);

  const totalPrice = secondaryYarn ? mainPrice + secondPrice : mainPrice;

  const updateSkeinsMain = (skeins: number) => {
    modifySkeinQuantity(
      {
        main: skeins,
        secondary: skeinsSecond,
      },
      shoppingCartItemId,
      false,
    );
    setSkeinsMain(skeins);
  };

  const updateSkeinsSecond = (skeins: number) => {
    modifySkeinQuantity(
      {
        main: skeinsMain,
        secondary: skeins,
      },
      shoppingCartItemId,
      false,
    );
    setSkeinsSecond(skeins);
  };

  const getYarnItemName = () => {
    const mainYarnName = `${mainYarn.name}${
      !editSkeins ? ` (${skeinsMain})` : ""
    }`;

    if (secondaryYarn === undefined) {
      return mainYarnName;
    }

    return `${mainYarnName} + ${secondaryYarn.name}${
      !editSkeins ? ` (${skeinsSecond})` : ""
    }`;
  };

  const ProductDetails = (
    <>
      <p className="yarn-information text-s">{getYarnItemName()}</p>
      <div className="yarn-quantity text-s">
        {editSkeins && (
          <>
            <div className="yarn-quantity-main">
              {t("design.designAccordion.yarn.yarnSelectionInfo.main")}
              <SkeinSelector skeins={skeinsMain} setSkeins={updateSkeinsMain} />
            </div>

            {secondaryYarn && (
              <div className="yarn-quantity-second">
                {t(
                  "design.designAccordion.yarn.yarnSelectionInfo.heldTogetherWith",
                )}
                <SkeinSelector
                  skeins={skeinsSecond}
                  setSkeins={updateSkeinsSecond}
                />
              </div>
            )}
          </>
        )}
      </div>
      {editSkeins && skeinsAdjustedFromOriginal() && (
        <p
          className="yarn-quantity-adjustment"
          onClick={resetSkeinsToOriginal}
          style={{ textDecoration: "underline", marginBottom: "0.5rem" }}
        >
          {t("design.designAccordion.yarn.yarnSelectionInfo.adjustmentMessage")}
        </p>
      )}
    </>
  );
  const PriceSection = (
    <>
      <p className="text-s">{t("design.designAccordion.yarn.title")}</p>
      <p className="text-s">
        {Math.round(totalPrice)} {t("checkout.currency")}
      </p>
    </>
  );

  return (
    <ShoppingCartItem
      supplier={mainYarn.supplier}
      illustration={yarn.illustration}
      productDetails={ProductDetails}
      priceSection={PriceSection}
      shoppingCartItemId={shoppingCartItemId}
      removeItem={() => {
        trackEvent(TrackingEvents.REMOVE_YARN);
        removeYarnFromShoppingCart(shoppingCartItemId);
      }}
      editable={false}
      removable={removable}
      warningMessage={warningMessage}
    />
  );
};

export default YarnItem;
