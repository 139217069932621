export const range = (start: number, end: number) => {
  return Array<number>(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
};

export const GAUGE_WIDTHS = range(10, 32).map((gaugewidth) =>
  gaugewidth.toString(),
);

export const YARDAGES = range(50, 500).map((yardage) => yardage.toString());
