import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import {
  CalculateHatDifficulty,
  CalculateSweaterDifficulty,
} from "types/APITypes";
import { ProductType } from "types/ShoppingCart";
import { CalculateDifficultyApi } from "utils/api/CalculateDifficulty";
import "./../IllustrationContainer.scss";
import DegreeOfDifficultyDescription from "./DegreeOfDifficultyDescription";

const DegreeOfDifficulty = () => {
  const { design, yarn } = useDesign();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [difficulty, setDifficulty] = useState<number>();
  const useEffectDependencies = () => {
    switch (design.patternType) {
      case ProductType.SWEATERPATTERN:
        return [
          design.designChoices.bodyWidth,
          design.designChoices.neckline,
          design.designChoices.construction,
          design.designChoices.sleeve,
          yarn.gaugeWidth,
          yarn.needlesize,
        ];
      case ProductType.HATPATTERN:
        return [
          design.designChoices.decreases,
          design.designChoices.bodyStructure,
          design.designChoices.brim,
        ];
      default:
        throw new Error("Invalid pattern type");
    }
  };

  useEffect(() => {
    calculateAndUpdateDifficulty();
  }, useEffectDependencies());

  const calculateAndUpdateDifficulty = () => {
    let data: CalculateSweaterDifficulty | CalculateHatDifficulty;
    if (design.patternType === ProductType.SWEATERPATTERN) {
      data = {
        construction: design.designChoices.construction,
        bodyWidth: design.designChoices.bodyWidth,
        neckline: design.designChoices.neckline,
        sleeve: design.designChoices.sleeve,
        gaugeWidth: yarn.gaugeWidth,
        productTypeId: design.patternType,
      };
    } else if (design.patternType === ProductType.HATPATTERN) {
      data = {
        decreases: design.designChoices.decreases,
        bodyStructure: design.designChoices.bodyStructure,
        brim: design.designChoices.brim,
        productTypeId: design.patternType,
      };
    } else {
      throw new Error("Invalid pattern type");
    }
    CalculateDifficultyApi.post(data)
      .then((res) => res.data.difficulty)
      .then(setDifficulty);
  };
  const openDifficultyDescription = Boolean(anchorEl);
  const id = openDifficultyDescription ? "simple-popover" : undefined;

  const handleOpenDifficultyDescription = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDifficultyDescription = () => {
    setAnchorEl(null);
  };

  return (
    <div className="degree-of-difficulty-container text-s">
      <p style={{ marginRight: "5px", alignSelf: "center" }}>
        {t("design.illustrationContainer.difficulty.difficultyTitle")}:{" "}
      </p>
      <p style={{ alignSelf: "center" }}>
        {t("design.illustrationContainer.difficulty.difficultyNumber", {
          difficulty: difficulty,
        })}
      </p>
      <IconButton color="primary" onClick={handleOpenDifficultyDescription}>
        {" "}
        <HelpIcon />{" "}
      </IconButton>
      <Popover
        className="text-s"
        id={id}
        open={openDifficultyDescription}
        onClose={handleCloseDifficultyDescription}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <DegreeOfDifficultyDescription difficulty={difficulty} />
      </Popover>
    </div>
  );
};

export default DegreeOfDifficulty;
