import { useState, useEffect } from "react";
import "./successfulPaymentView.scss";
import { api } from "utils/api/api";
import { CircularProgress } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { APIPath } from "types/APIPath";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

const StatusPaymentView = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const orderId = urlParams.get("id");
    api
      .get(`${APIPath.GET_VIPPS_PAYMENT_DETAILS}/${orderId}`)
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data.orderId === orderId &&
            (res.data.state === "CAPTURE" || res.data.state === "RESERVE")
          ) {
            setIsSuccess(true);
            if (orderId) {
              let localOrderId = sessionStorage.getItem("orderId");
              if (localOrderId == null) {
                sessionStorage.setItem("orderId", orderId);
                trackEvent(TrackingEvents.PURCHASE, { paymentMethod: "vipps" });
              }
            }
          }
        } else {
          trackEvent(TrackingEvents.VIPPS_PAYMENT_FAILED);
        }
      })
      .catch((error) => {
        checkStatusAndLogApiError(
          `${APIPath.GET_VIPPS_PAYMENT_DETAILS}/${orderId}`,
          error,
          undefined,
        );
      })
      .finally(() => setIsLoading(false));
  });

  const renderLoader = () => {
    return <CircularProgress size={65} />;
  };

  const renderContent = () => {
    return isSuccess ? (
      <div className="text">
        <h1>{t("status.successful.title")}</h1>
        <Trans i18nKey="status.successful.contentTop">
          <p>
            Du har mottatt en ordrebekreftelse på e-post med link til
            oppskriften. Mailen kan ha havnet i søppelpost.
          </p>
          <p>
            Del gjerne det ferdige resultatet med oss på Instagram under
            #dreamknit.
          </p>
          <br />
        </Trans>
      </div>
    ) : (
      <div className="text">
        <h1>{t("status.unsuccessful.title")}</h1>
        <Trans i18nKey="status.unsuccessful.content">
          <p>
            Noe gikk galt. Vennlighst prøv på nytt. Kontakt oss gjerne på{" "}
            <a href="mailto:hello@dreamknit.no">hello@dreamknit.no</a> dersom du
            står fast.
          </p>
        </Trans>
      </div>
    );
  };

  return (
    <div className="successfulPayment">
      {isLoading ? renderLoader() : renderContent()}
    </div>
  );
};

export default StatusPaymentView;
