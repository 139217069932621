import { getI18n } from "react-i18next";
import { Language } from "types/Language";

export const getLanguage = (): Language => {
  const i18n = getI18n();

  if (i18n.language === "nb") {
    return Language.NO;
  } else if (i18n.language === "en") {
    return Language.EN;
  } else {
    return Language.EN;
  }
};

export function getUrlLanguage(pathname: string) {
  switch (pathname.substring(1, 3).toUpperCase()) {
    case Language.NO:
      return Language.NO;
    case Language.EN:
    default:
      return Language.EN;
  }
}

export const mapLanguageEnumToVerbose = (language: Language) => {
  const { t } = getI18n();
  switch (language) {
    case Language.EN:
      return t("language.english");
    case Language.NO:
    default:
      return t("language.norwegian");
  }
};

export const sizeCasing = (size: string) => {
  if (size === "baby" || size === "kids") {
    return size.charAt(0).toUpperCase() + size.slice(1);
  }
  return size.toUpperCase();
};
