/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";

const LOCAL_API_URL = "http://localhost:8000/api";
const DEV_API_URL = "https://dreamknit-dev.azurewebsites.net/api";
const PROD_API_URL = "https://dreamknit.azurewebsites.net/api";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
