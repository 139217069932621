import GoBackButton from "components/Other/GoBackButton/GoBackButton";
import YarnContainer from "components/Yarn/YarnContainer/YarnContainer";
import "styles/fonts.scss";
import AppPath from "types/AppPath";
import "./yarnView.scss";

const YarnView = () => {
  return (
    <div className="yarnview-container">
      <div className="goBackButtonContainer">
        <GoBackButton fallbackPath={AppPath.SWEATER} />
      </div>
      <YarnContainer />
    </div>
  );
};

export default YarnView;
