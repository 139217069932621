import { Link, useLocation } from "react-router-dom";
import { getGaLinkerUrlParam } from "utils/crossDomainTracking";

interface DreamknitLinkProps {
  to: string;
  className?: string;
}

export default function DreamknitLink(
  props: React.PropsWithChildren<DreamknitLinkProps>,
) {
  const pathname = useLocation().pathname;
  const { to, className, children } = props;
  let destinationString = pathname.includes("/no") ? `/no${to}` : to;

  const ga_linker_string = getGaLinkerUrlParam();
  if (ga_linker_string !== null) {
    destinationString = destinationString + "?" + ga_linker_string;
  }

  const handleClick = () => {
    //Used to keep track of the previous page visited
    sessionStorage.setItem("dkLastPage", window.location.hostname);
  };

  return (
    <Link
      onClick={handleClick}
      to={destinationString}
      className={className ?? ""}
    >
      {children}
    </Link>
  );
}
