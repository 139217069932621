import { UpdateDesignFunction } from "store/DesignContext";
import { DesignChoices } from "types/DesignChoices";
import {
  SweaterBodyLength,
  SweaterBodyWidth,
  SweaterConstruction,
  SweaterKeys,
  SweaterNeckline,
  SweaterSleeve,
  Sweater,
} from "types/DesignChoices/Sweater";
import { SelectionOptions } from "components/Design/DesignContainer/SelectionComponents/SelectionOptionsBox";
import { Dispatch, SetStateAction } from "react";
import {
  HatBodyStructure,
  HatBrim,
  HatDecreases,
  HatKeys,
  Hat,
} from "types/DesignChoices/Hat";
import { HatSize } from "types/Accessories";

export function mapKeyToTranslationKey(
  key: string,
  designChoiceCategory: keyof DesignChoices | string = "", // Denne burde strammes inn, keyof DesignChoices gir bare "size" | "yardageMeter" | "skeins"
): string {
  switch (designChoiceCategory) {
    case SweaterKeys.CONSTRUCTION:
      switch (key) {
        case SweaterConstruction.SETIN:
          return "design.designAccordion.construction.setin";
        case SweaterConstruction.RAGLAN:
          return "design.designAccordion.construction.raglan";
        default:
          return key;
      }
    case SweaterKeys.BODY_LENGTH:
      switch (key) {
        case SweaterBodyLength.REGULAR:
          return "design.designAccordion.construction.regularLength";
        case SweaterBodyLength.CROPPED:
          return "design.designAccordion.construction.cropped";
        default:
          return key;
      }
    case SweaterKeys.BODY_WIDTH:
      switch (key) {
        case SweaterBodyWidth.REGULAR:
          return "design.designAccordion.fit.regularFit";
        case SweaterBodyWidth.OVERSIZED:
          return "design.designAccordion.fit.oversizedFit";
        default:
          return key;
      }
    case SweaterKeys.NECKLINE:
      switch (key) {
        case SweaterNeckline.REGULAR:
          return "design.designAccordion.neckline.regular";
        case SweaterNeckline.VNECK:
          return "design.designAccordion.neckline.vneck";
        case SweaterNeckline.MOCKNECK:
          return "design.designAccordion.neckline.mockneck";
        case SweaterNeckline.TURTLENECK:
          return "design.designAccordion.neckline.turtleneck";
        default:
          return key;
      }
    case SweaterKeys.SLEEVE:
      switch (key) {
        case SweaterSleeve.REGULAR:
          return "design.designAccordion.sleeve.regular";
        case SweaterSleeve.BALLOON:
          return "design.designAccordion.sleeve.balloon";
        case SweaterSleeve.BOXY:
          return "design.designAccordion.sleeve.boxy";
        case SweaterSleeve.NOSLEEVE:
          return "design.designAccordion.sleeve.nosleeve";
        case SweaterSleeve.SHORT:
          return "design.designAccordion.sleeve.tshirt";
        default:
          return key;
      }
    case HatKeys.BODY_STRUCTURE:
      switch (key) {
        case HatBodyStructure.ONE_BY_ONE:
          return "design.designAccordion.hatStructure.1x1";
        case HatBodyStructure.TWO_BY_TWO:
          return "design.designAccordion.hatStructure.2x2";
        case HatBodyStructure.STOCKINETTE:
          return "design.designAccordion.hatStructure.stockinette";
        default:
          return key;
      }
    case HatKeys.BRIM:
      switch (key) {
        case HatBrim.NO:
          return "design.designAccordion.hatBrim.no";
        case HatBrim.SINGLE:
          return "design.designAccordion.hatBrim.single";
        case HatBrim.DOUBLE:
          return "design.designAccordion.hatBrim.double";
        default:
          return key;
      }
    case HatKeys.BRIM_STRUCTURE:
      switch (key) {
        case HatBodyStructure.ONE_BY_ONE:
          return "design.designAccordion.hatBrim.1x1";
        case HatBodyStructure.TWO_BY_TWO:
          return "design.designAccordion.hatBrim.2x2";
        case HatBodyStructure.STOCKINETTE:
          return "design.designAccordion.hatBrim.stockinette";
        default:
          return key;
      }
    case HatKeys.SIZE:
      switch (key) {
        case HatSize.BABY:
          return "design.designAccordion.hatSize.baby";
        case HatSize.KIDS:
          return "design.designAccordion.hatSize.kids";
        case HatSize.S:
          return "design.designAccordion.hatSize.s";
        case HatSize.M:
          return "design.designAccordion.hatSize.m";
        case HatSize.L:
          return "design.designAccordion.hatSize.l";
        case HatSize.XL:
          return "design.designAccordion.hatSize.xl";
        default:
          return key;
      }
    case HatKeys.DECREASES:
      switch (key) {
        case HatDecreases.CROSS:
          return "design.designAccordion.hatDecreases.cross";
        case HatDecreases.SIDE:
          return "design.designAccordion.hatDecreases.side";
        case HatDecreases.SPIRAL:
          return "design.designAccordion.hatDecreases.spiral";
        default:
          return key;
      }
    default:
      return key;
  }
}

export function mapActionToUrlKey(
  designChoiceCategory: string,
): keyof Sweater | keyof Hat {
  switch (designChoiceCategory) {
    case SweaterKeys.SIZE:
      return "size";
    case SweaterKeys.BODY_WIDTH:
      return "bodyWidth";
    case SweaterKeys.CONSTRUCTION:
      return "construction";
    case SweaterKeys.BODY_LENGTH:
      return "bodyLength";
    case SweaterKeys.NECKLINE:
      return "neckline";
    case SweaterKeys.SLEEVE:
      return "sleeve";
    case HatKeys.BRIM:
      return "brim";
    case HatKeys.BRIM_STRUCTURE:
      return "brimStructure";
    case HatKeys.BODY_STRUCTURE:
      return "bodyStructure";
    case HatKeys.DECREASES:
    default:
      return "decreases";
  }
}

interface createSelectionOptionsProps<T extends DesignChoices> {
  keys: T[keyof T][];
  dispatchFunction: UpdateDesignFunction;
  designChoiceCategory: keyof T;
  handleClick: (key: any, value: any) => void;
  setActiveClass: Dispatch<SetStateAction<string>>;
  translationMapper?: (key: string, designChoiceCategory?: string) => string;
  isInvalid?: (key: T[keyof T]) => boolean;
}

export function createSelectionOptions<T extends DesignChoices>({
  keys,
  dispatchFunction,
  designChoiceCategory,
  handleClick,
  setActiveClass,
  translationMapper = mapKeyToTranslationKey,
  isInvalid = (_) => false,
}: createSelectionOptionsProps<T>): SelectionOptions[] {
  return keys.map((key) => ({
    key: key as string,
    translationKey: translationMapper(key as string),
    handleSelectOption: (selectedOption) => {
      dispatchFunction(designChoiceCategory, selectedOption as T[keyof T]);
      handleClick(
        mapActionToUrlKey(designChoiceCategory as string),
        selectedOption,
      );
      setActiveClass(selectedOption);
    },
    invalid: isInvalid(key),
  }));
}
