import { StandardTextFieldProps, TextField } from "@mui/material";
import { useState } from "react";

export interface DkTextFieldProps extends StandardTextFieldProps {
  value: string;
  errorCheck?: boolean;
  validator?: (value: string) => boolean;
  isOutlined?: boolean;
  backgroundColor?: string;
}

/**
 * An unconditionally controlled text input field
 */
const DkTextField = (props: DkTextFieldProps) => {
  const {
    value,
    label,
    errorCheck = false,
    isOutlined = true,
    backgroundColor,
    validator,
    ...otherProps
  } = props;
  const [showError, setShowError] = useState(false);

  const getErrorProps = () => {
    if (errorCheck && validator !== undefined)
      return {
        error: !validator(value) && showError,
        onBlur: (
          e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
        ) => {
          if (otherProps.onBlur !== undefined) {
            otherProps.onBlur(e);
          }

          !validator(value) ? setShowError(true) : setShowError(false);
        },
      };
  };

  const getStyleProps = () => {
    let sx = {};
    if (!isOutlined) sx["& fieldset"] = { border: "none" };

    if (backgroundColor !== undefined)
      sx["& .MuiInputBase-input"] = {
        background: backgroundColor,
      };

    return { sx };
  };

  return (
    <TextField
      {...otherProps}
      label={label}
      {...getErrorProps()}
      {...getStyleProps()}
    />
  );
};

export default DkTextField;
