import { useTranslation } from "react-i18next";
import { classNames } from "styles/StyleUtils";
import InfoTag, { InfoTagColors } from "./InfoTag";
import selectionOptionBoxStyles from "./SelectionOptionsBox.module.scss";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

export interface SelectionOptions {
  key: string;
  translationKey: string;
  handleSelectOption: (option: string) => void;
  information?: boolean;
  invalid: boolean;
}

interface SelectionOptionsBoxProps {
  options: SelectionOptions[];
  activeOption?: string;
  displayType: "column" | "row";
}

export function SelectionOptionsBox({
  options,
  activeOption,
  displayType,
}: SelectionOptionsBoxProps) {
  const { t } = useTranslation();

  const containerClass = `${selectionOptionBoxStyles["container"]} ${
    displayType === "column"
      ? selectionOptionBoxStyles["column-container"]
      : selectionOptionBoxStyles["row"]
  }`;

  return (
    <div className={containerClass}>
      {options.map((option) => {
        const buttonClass = classNames(
          selectionOptionBoxStyles["selection-option"],
          activeOption === option.key &&
            selectionOptionBoxStyles["activeButton"],
          option.invalid && selectionOptionBoxStyles["invalid-option"],
        );
        return (
          <div
            className={classNames(
              selectionOptionBoxStyles["item-container"],
              option.information &&
                selectionOptionBoxStyles["item-container-with-info"],
            )}
            key={option.key}
            onClick={() =>
              trackEvent(TrackingEvents.DESIGN_INTERACTION, {
                designInteraction: option.key,
              })
            }
          >
            <button
              key={option.key}
              className={buttonClass}
              onClick={() => {
                if (option.invalid) return;
                option.handleSelectOption(option.key);
              }}
            >
              {t(option.translationKey)}
            </button>
            {option.information && (
              <div className={selectionOptionBoxStyles["info-tag-container"]}>
                <InfoTag
                  color={InfoTagColors.BLACK}
                  textContent={t("design.designAccordion.sleeves.tshirtInfo")}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
