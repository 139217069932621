import { AxiosResponse } from "axios";
import { APIPath } from "types/APIPath";
import {
  CalculateDifficultyApiResponse,
  CalculateHatDifficulty,
  CalculateSweaterDifficulty,
} from "types/APITypes";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";

export const CalculateDifficultyApi = {
  post: async function (
    data: CalculateSweaterDifficulty | CalculateHatDifficulty,
  ): Promise<AxiosResponse<CalculateDifficultyApiResponse>> {
    return api.post(APIPath.CALCULATE_DIFFICULTY, data).catch((error) => {
      checkStatusAndLogApiError(APIPath.CALCULATE_DIFFICULTY, error, data);
      throw error;
    });
  },
};
