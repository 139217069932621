import { CircularProgress, Grid, Typography } from "@mui/material";
import YarnCard from "components/Yarn/YarnCard/YarnCard";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Language } from "types/Language";
import { Yarn } from "types/Yarn";
import { YarnsApi } from "utils/api/YarnsApi";
import { fetchYarnSuppliers } from "utils/api/fetchYarnSuppliers";
import debounce from "utils/debounce";
import { getLanguage } from "utils/intl/languageUtil";
import AddYarnCard from "../AddYarn/AddYarnCard/AddYarnCard";
import { reverseMaterialMapper } from "../materialMapper";
import NeedleSizeGuide from "./NeedleSizeGuide";
import NoMatchesFound from "./NoMatchesFound";
import SearchSection from "./SearchSection";
import { AllowedSortingOrders } from "./SortButton";
import "./YarnContainer.scss";

function getSearchString(name, supplier) {
  name = name.toLowerCase();
  supplier = supplier.toLowerCase();
  return [name + " " + supplier, supplier + " " + name];
}

function matchesSearchCriteria(yarn, filterData) {
  // Convert search string to lowercase for case-insensitive comparison
  const searchStringLower = filterData.searchString.toLowerCase();

  // Generate search strings based on yarn name and supplier
  const searchStrings = getSearchString(yarn.name, yarn.supplier);

  // Check if either generated search string includes the search string from filterData
  return searchStrings.some((searchStr) =>
    searchStr.includes(searchStringLower),
  );
}

export interface FilterDataObject {
  needlesizes: number[];
  gaugeWidth: number[];
  searchString: string;
  suppliers: string[];
  materials: string[];
  showYarnPackages: boolean;
  showMainThreadOnlyYarns: boolean;
  showHeldTogetherWithYarns: boolean;
}

const YarnContainer = () => {
  const [cardData, setCardData] = useState<Yarn[]>([]);
  const [loadingYarnPackages, setLoadingYarnPackages] = useState(false);
  const [loadingYarns, setLoadingYarns] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [sortType, setSortType] = useState<AllowedSortingOrders>(
    AllowedSortingOrders.SUPPLIER_ASCENDING,
  );

  const [isSearching, setIsSearching] = useState(false);
  const [showFilterControl, setShowFilterControl] = useState(false);
  const cardDataRef = useRef<Yarn[]>();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [yarnPackages, setYarnPackages] = useState<Yarn[]>([]);
  const [allSuppliers, setAllSuppliers] = useState<string[]>([]);

  // Controller in case multiple calls are made in quick succession
  const yarnFetchControllerRef = useRef(new AbortController());

  const getFilterDataSessionStorage = () => {
    let dreamKnitFilterData: FilterDataObject = {
      needlesizes: [],
      gaugeWidth: [10, 32],
      searchString: "",
      suppliers: [],
      materials: [],
      showYarnPackages: getLanguage() === Language.NO ? true : false,
      showMainThreadOnlyYarns: true,
      showHeldTogetherWithYarns: true,
    };

    const dreamKnitFilterDataSessionStorage = sessionStorage.getItem(
      "dreamKnitFilterData",
    );
    if (dreamKnitFilterDataSessionStorage !== null) {
      dreamKnitFilterData = JSON.parse(dreamKnitFilterDataSessionStorage);
    }
    return dreamKnitFilterData;
  };

  const [filterData, setFilterData] = useState<FilterDataObject>(
    getFilterDataSessionStorage(),
  );
  const [searchString, setSearchString] = useState(
    getFilterDataSessionStorage().searchString,
  );

  const fetchAllYarns = () => {
    // Cancel the previous request
    if (yarnFetchControllerRef.current) {
      yarnFetchControllerRef.current.abort();
    }

    yarnFetchControllerRef.current = new AbortController();
    const signal = yarnFetchControllerRef.current.signal;

    setLoadingYarns(true);
    setLoadingYarnPackages(true);
    const queryParams = {
      orderBy: sortType,
      showMainThreadOnlyYarns: filterData.showMainThreadOnlyYarns,
      showHeldTogetherWithYarns: filterData.showHeldTogetherWithYarns,
    };
    filterData.suppliers.length > 0 &&
      (queryParams["suppliers"] = filterData.suppliers);
    filterData.needlesizes.length > 0 &&
      (queryParams["needlesizes"] = filterData.needlesizes);
    filterData.materials.length > 0 &&
      (queryParams["materials"] = reverseMaterialMapper(
        filterData.materials,
        getLanguage(),
      ));
    YarnsApi.queryAll(i18n.language, {
      ...queryParams,
    })
      .then((yarns) => {
        if (signal.aborted) {
          return;
        }
        setCardData(yarns);
        cardDataRef.current = yarns;
        setYarnPackages(yarns.filter((yarn) => yarn.purchasable));
      })
      .finally(() => {
        if (signal.aborted) {
          return;
        }
        setLoadingYarns(false);
        setLoadingYarnPackages(false);
        filter();
      });
  };

  useEffect(() => {
    fetchYarnSuppliers().then((suppliers) =>
      setAllSuppliers(suppliers.map((supplier) => supplier.supplier).sort()),
    );
  }, []);

  useEffect(() => {
    setFilterData({
      ...filterData,
      showYarnPackages: getLanguage() === Language.NO ? true : false,
    });
  }, [i18n.language]);

  useEffect(() => {
    fetchAllYarns();
  }, [
    location,
    sortType,
    filterData.suppliers,
    filterData.showHeldTogetherWithYarns,
    filterData.showMainThreadOnlyYarns,
    filterData.needlesizes,
    filterData.materials,
  ]);

  const loadFilterData = () => {
    const dreamKnitFilterData = getFilterDataSessionStorage();
    setFilterData(dreamKnitFilterData);
  };

  useEffect(() => {
    loadFilterData();
  }, []);

  const setFilterDataSessionStorage = () => {
    const serializedData = JSON.stringify(filterData);
    sessionStorage.setItem("dreamKnitFilterData", serializedData);
  };

  useEffect(() => {
    setFilterData({ ...filterData, searchString });
  }, [searchString]);

  useEffect(() => {
    filter();
    setFilterDataSessionStorage();
  }, [filterData]);

  useEffect(() => {
    if (getLanguage() === Language.NO) {
      setFilterData((prev) => {
        return {
          ...prev,
          showYarnPackages: true,
        };
      });
    } else {
      setFilterData((prev) => {
        return {
          ...prev,
          showYarnPackages: false,
        };
      });
    }
  }, [i18n.language]);

  const handleSearchStringChange = (event) => {
    if (event.target.name === "search") {
      setSearchString(event.target.value);
    }
  };

  const debouncedHandleSearchStringChange = useCallback(
    debounce((event) => {
      handleSearchStringChange(event);
      setIsSearching(false);
    }, 400),
    [],
  );

  const handleGaugeWidthChange = (value: number[]) => {
    setFilterData((prev) => {
      return {
        ...prev,
        gaugeWidth: value,
      };
    });
  };

  const filter = () => {
    if (cardDataRef.current) {
      setCardData(
        cardDataRef.current.filter(
          (yarn) =>
            matchesGaugeWidth(yarn) && matchesSearchCriteria(yarn, filterData),
        ),
      );
      setYarnPackages(
        cardDataRef.current.filter(
          (yarn) =>
            yarn.purchasable &&
            matchesGaugeWidth(yarn) &&
            matchesSearchCriteria(yarn, filterData),
        ),
      );
    }
  };

  const matchesGaugeWidth = (yarn) => {
    return (
      yarn.gaugeWidth !== null &&
      yarn.gaugeWidth !== undefined &&
      yarn.gaugeWidth >= filterData.gaugeWidth[0] &&
      yarn.gaugeWidth <= filterData.gaugeWidth[1]
    );
  };

  //Pinnestørrlse tabell
  const handleOpenNeedleSizeTable = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseNeedleSizeTable = () => {
    setAnchorEl(null);
  };

  const toggleShowFilterControl = () => setShowFilterControl((prev) => !prev);

  const cardDataToComponents = (cardData: Yarn[]) =>
    cardData.map((yarn) => (
      <Grid item key={yarn.yarnId} className="yarn-card-container">
        <YarnCard yarnData={yarn} />
      </Grid>
    ));

  const showCardData = (cardData: Yarn[]) => {
    if (loadingYarns || isSearching) {
      return (
        <Grid
          item
          alignSelf="center"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
      );
    } else {
      return cardDataToComponents(cardData);
    }
  };

  const shouldRenderCardData = (cardData: Yarn[]) => {
    const yarnArrayIsNotEmpty = cardData.length > 0;
    const isLoadingOrSearching = loadingYarns || isSearching;
    return yarnArrayIsNotEmpty || isLoadingOrSearching;
  };

  function cardDataOrNoMatchesFound() {
    const yarns = yarnPackages.filter((yarn) =>
      matchesSearchCriteria(yarn, filterData),
    );
    if (yarns.length === 0) {
      return (
        <div>
          <p>{t("yarndb.noSearchMatch")}</p>
        </div>
      );
    } else {
      return cardDataToComponents(yarns);
    }
  }

  return (
    <div className="yarnContainer">
      <SearchSection
        isSearching={isSearching}
        setFilterData={setFilterData}
        showFilterControl={showFilterControl}
        setIsSearching={setIsSearching}
        debouncedHandleSearchStringChange={debouncedHandleSearchStringChange}
        searchString={searchString}
        setSearchString={setSearchString}
        setShowFilterControl={setShowFilterControl}
        toggleShowFilterControl={toggleShowFilterControl}
        handleGaugeWidthChange={handleGaugeWidthChange}
        filterData={filterData}
        setSortType={setSortType}
        allSuppliers={allSuppliers}
      />
      <div
        className={`yarnContainerYarns ${showFilterControl ? "active" : ""}`}
      >
        {filterData.showYarnPackages && (
          <Grid container justifyContent="flex-start">
            <Typography
              component="h2"
              mt={2}
              fontSize="1.75rem"
              textTransform="uppercase"
            >
              {t("yarndb.yarnPackages")}
            </Typography>
            <Grid container justifyContent="center">
              <Grid
                gap={1}
                container
                justifyContent="flex-start"
                data-cy="yarnPackagesContainer"
              >
                {loadingYarnPackages ? (
                  <Grid item alignItems="center" justifyContent="center">
                    <CircularProgress />
                  </Grid>
                ) : (
                  cardDataOrNoMatchesFound()
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {filterData.showYarnPackages && (
          <Typography
            component="h2"
            mt={3}
            fontSize="1.75rem"
            textTransform="uppercase"
          >
            {t("yarndb.onlyPattern")}
          </Typography>
        )}
        {getLanguage() === Language.EN && (
          <div className="needlesizeGuideContainer">
            <NeedleSizeGuide
              handleOpenNeedleSizeTable={handleOpenNeedleSizeTable}
              handleCloseNeedleSizeTable={handleCloseNeedleSizeTable}
              anchorEl={anchorEl}
            />
          </div>
        )}
        <Grid
          container
          gap={2}
          justifyContent="flex-start"
          data-cy="yarnContainer"
        >
          {shouldRenderCardData(cardData) ? (
            <>
              <AddYarnCard />
              {showCardData(cardData)}
            </>
          ) : (
            <NoMatchesFound />
          )}
        </Grid>
      </div>
    </div>
  );
};

export default YarnContainer;
