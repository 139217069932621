import { useEffect, useState } from "react";
import { Design, useDesign } from "store/DesignContext";
import { CalculateYardageRequest } from "types/APITypes";
import AppPath from "types/AppPath";
import { PatternProductType } from "types/DesignChoices/DesignChoices";
import { SweaterKeys } from "types/DesignChoices/Sweater";
import { CalculateYardageApi } from "utils/api/CalculateYardageApi";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import {
  extractCustomYarnFromUrl,
  setUrlParamsFromYarnAndDesign,
} from "utils/setUrlParams";
import designViewStyles from "./designView.module.scss";
import GoBackButton from "components/Other/GoBackButton/GoBackButton";
import IllustrationContainer from "components/Design/IllustrationContainer/IllustrationContainer";
import "./designView.scss";
import { SessionStoragePatternId, findYarnItem } from "utils/shoppingCartUtils";
import { useShoppingCart } from "store/ShoppingCartContext";
import { DesignYarn, MainAndSecondarySkeins } from "types/Yarn";
import {
  createAccordion,
  createCalculateYardageData,
  createUpdateYardageDependencies,
} from "utils/designUtils";
import { YarnsApi } from "utils/api/YarnsApi";

interface DesignViewProps<ProductTypeId extends PatternProductType> {
  productTypeId: ProductTypeId;
  createCalculateYardageData: (
    design: Design,
    yarn: DesignYarn,
  ) => CalculateYardageRequest | undefined;
  createUpdateYardageDependencies: (design: Design) => any[]; // TODO: Remove any type
  createAccordion: (
    design: Design,
    setShowYarnErrorMessage: React.Dispatch<React.SetStateAction<boolean>>,
  ) => React.ReactNode;
}

const DesignView = <ProductTypeId extends PatternProductType>({
  productTypeId,
  createCalculateYardageData,
  createUpdateYardageDependencies,
  createAccordion,
}: DesignViewProps<ProductTypeId>) => {
  const { design, updateDesign, yarn, setYarn, yarnIsChosen, setYarnIsChosen } =
    useDesign();
  const { shoppingCart, modifySkeinQuantity } = useShoppingCart();
  const pushToHistory = useDreamknitHistory();
  const [showYarnErrorMessage, setShowYarnErrorMessage] = useState(false);
  const updateYardageEffectDependencies = [
    ...createUpdateYardageDependencies(design),
    yarn,
    yarnIsChosen,
  ];

  const calculateAndUpdateYardage = async (data: CalculateYardageRequest) =>
    CalculateYardageApi.post(data).then((res) => {
      const { yardageMeter, skeins } = res.data;
      /** SweaterKeys.YARDAGE_METER and SweaterKeys.SKEINS are common among
       * Sweater, Hat and Mittens types - thus, the following two statements
       * work across pattern types */
      updateDesign(SweaterKeys.YARDAGE_METER, yardageMeter);
      updateDesign(SweaterKeys.SKEINS, skeins);

      return { yardageMeter, skeins };
    });

  useEffect(() => {
    if (design.patternType !== productTypeId) return;

    const customYarn = extractCustomYarnFromUrl(
      new URLSearchParams(window.location.search),
    );
    setUrlParamsFromYarnAndDesign({
      yarn,
      pushToHistory,
      design,
      customYarn,
    });
    if (customYarn !== undefined) setYarnIsChosen(true);
    else if (yarn.yarnId !== undefined) {
      YarnsApi.getById(parseInt(yarn.yarnId))
        .then((yarn) => {
          return {
            yarnId: yarn.yarnId,
            name: yarn.name,
            secondName: yarn.secondName,
            supplier: yarn.supplier,
            needlesize: yarn.needlesize,
            yardage: yarn.yardage,
            secondYardage: yarn.secondYardage,
            gaugeWidth: yarn.gaugeWidth,
          };
        })
        .then(setYarn)
        .then(() => setYarnIsChosen(true))
        .catch((error) => {
          console.error("Error fetching yarn data:", error);
        });
    }
  }, [design.patternType]);

  useEffect(() => {
    if (!yarnIsChosen || design === undefined) return;

    const performYardageUpdate = (yarnData: DesignYarn) => {
      const calculateYardageData = createCalculateYardageData(design, yarnData);

      if (calculateYardageData === undefined) return;

      calculateAndUpdateYardage(calculateYardageData).then(({ skeins }) => {
        const patternId = SessionStoragePatternId.get();

        if (patternId === null) return;

        const yarn = findYarnItem(shoppingCart.items, patternId);

        if (yarn === undefined) return;

        const skeinsObject: MainAndSecondarySkeins = {
          main: skeins[0],
          secondary: skeins.length > 1 ? skeins[1] : 0,
        };
        modifySkeinQuantity(skeinsObject, yarn.shoppingCartItemId, true);
      });
    };

    if (yarn.gaugeWidth !== 0 && yarn.needlesize !== 0 && yarn.yardage !== 0)
      performYardageUpdate(yarn);
  }, updateYardageEffectDependencies);

  return (
    <div className="designContainer">
      <div className={designViewStyles["left-side-container"]}>
        <div className={designViewStyles["back-button-container"]}>
          <GoBackButton to={AppPath.BASE} />
        </div>
        <IllustrationContainer showYarnErrorMessage={showYarnErrorMessage} />
      </div>{" "}
      {createAccordion(design, setShowYarnErrorMessage)}
    </div>
  );
};

/**
 * Factory method creating a design view for a given design object.
 * Uses the general DesignView-component to tailor to different pattern types.
 * @param design an instance of type Design
 * @returns a DesignView-component that renders the design view UI
 */
const createDesignView = (design: Design) => {
  return (
    <DesignView
      productTypeId={design.patternType}
      createCalculateYardageData={createCalculateYardageData}
      createUpdateYardageDependencies={createUpdateYardageDependencies}
      createAccordion={createAccordion}
    />
  );
};

export { createDesignView };
