import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Routes from "Routes";
import ScrollToTop from "utils/ScrollToTop";
import "./index.scss";
import TagManager from "react-gtm-module";
import "i18n";
import { ShoppingCartProvider } from "store/ShoppingCartContext";

const theme = createTheme({
  palette: {
    primary: {
      main: "#87A5FF",
    },
    secondary: {
      main: "#BFD0EA", // Sky
    },
    action: {
      disabledBackground: "#A4A8A9", // Cloud dark
    },
  },
});

//Allow for window.dataLayer for tracking
declare global {
  interface Window {
    dataLayer: any;
  }
}

const tagManagerArgs = {
  gtmId:
    process.env.REACT_APP_ENV === "production" ? "GTM-WFTWVMR" : "GTM-MRLXTTC",
};

TagManager.initialize(tagManagerArgs);

const domNode = document.getElementById("root");

if (domNode === null) throw new Error("Root container missing in index.html");

const root = createRoot(domNode);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ShoppingCartProvider>
        <Router>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </Router>
      </ShoppingCartProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
