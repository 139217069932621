import { CircularProgress } from "@mui/material";
import GoBackButton from "components/Other/GoBackButton/GoBackButton";
import "components/shoppingcart/ShoppingCart.scss";
import { ShoppingCartItemsAndTotalPrice } from "components/shoppingcart/ShoppingCartItemsAndTotalPrice";
import { useCheckout } from "./CheckoutContext";
import AppPath from "types/AppPath";
import { Language } from "types/Language";
import { getLanguage } from "utils/intl/languageUtil";
import "./OrderSummary.scss";
import OrderSummaryFurtherInformation from "./OrderSummaryFurtherInformation";
import PromotionCodeForm from "./PromotionCodeForm";

interface OrderSummaryProps {
  renderSpinner: boolean;
}

export default function OrderSummary(props: OrderSummaryProps) {
  const { amountCommitted, shippingPrice } = useCheckout();
  const renderOrdersummary = () => {
    return (
      <div>
        <div id="shopping-cart">
          <ShoppingCartItemsAndTotalPrice
            inCheckout
            editable={false}
            removable={false}
            amountCommitted={amountCommitted}
            shippingPrice={shippingPrice}
          />
        </div>
        <PromotionCodeForm />
        <OrderSummaryFurtherInformation />
      </div>
    );
  };

  const renderCircularProgress = () => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: "2rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  };

  return (
    <div
      className={
        getLanguage() === Language.NO
          ? "norwegian order-summary"
          : "order-summary"
      }
    >
      <GoBackButton fallbackPath={AppPath.SWEATER} />
      {props.renderSpinner ? renderCircularProgress() : renderOrdersummary()}

      {/* Temporary removal due to  */}
      {/* {getLanguage() === Language.EN && (
        <div className="toggleUnitContainer">
          <span className="toggleText">
            I want the units in the pattern to be:
          </span>
          <ToggleButtonGroup
            className="toggleGroup"
            value={unit}
            exclusive
            onChange={handleUnit}
            aria-label="unit"
            color="standard"
          >
            <ToggleButton
              value={"metric"}
              className="toggleButton"
            >
              Metric
            </ToggleButton>
            <ToggleButton
              value={"imperial"}
              className="toggleButton"
            >
              Imperial
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      )} */}
    </div>
  );
}
