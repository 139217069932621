import { PartialBy } from "types/typeUtils";
// Body
import { ReactComponent as BodyOneByOne } from "assets/hatBuild/body/1x1.svg";
import { ReactComponent as BodyTwoByTwo } from "assets/hatBuild/body/2x2.svg";
import { ReactComponent as BodyStockinette } from "assets/hatBuild/body/stockinette.svg";
// Brim
import { ReactComponent as BrimDoubleOneByOne } from "assets/hatBuild/brim/double1x1.svg";
import { ReactComponent as BrimDoubleTwoByTwo } from "assets/hatBuild/brim/double2x2.svg";
import { ReactComponent as BrimDoubleStockinette } from "assets/hatBuild/brim/doublestockinette.svg";
import { ReactComponent as BrimNoOneByOne } from "assets/hatBuild/brim/no1x1.svg";
import { ReactComponent as BrimNoTwoByTwo } from "assets/hatBuild/brim/no2x2.svg";
import { ReactComponent as BrimSingleOneByOne } from "assets/hatBuild/brim/single1x1.svg";
import { ReactComponent as BrimSingleTwoByTwo } from "assets/hatBuild/brim/single2x2.svg";
import { ReactComponent as BrimSingleStockinette } from "assets/hatBuild/brim/singlestockinette.svg";
// Decreases
import { ReactComponent as DecreasesCross } from "assets/hatBuild/decreases/cross.svg";
import { ReactComponent as DecreasesSideOneByOne } from "assets/hatBuild/decreases/side1x1.svg";
import { ReactComponent as DecreasesSideTwoByTwo } from "assets/hatBuild/decreases/side2x2.svg";
import { ReactComponent as DecreasesSideStockinette } from "assets/hatBuild/decreases/sidestockinette.svg";
import { ReactComponent as DecreasesSpiral } from "assets/hatBuild/decreases/spiral.svg";
import { Hat } from "types/DesignChoices";
import {
  HatBodyStructure,
  HatBrim,
  HatBrimStructure,
  HatDecreases,
} from "types/DesignChoices/Hat";

interface HatIllustrationProps {
  hat: PartialBy<Hat, "size" | "yardageMeter" | "skeins">;
  className?: string;
}

const HatIllustration = ({ hat, className }: HatIllustrationProps) => {
  const { bodyStructure, brim, brimStructure, decreases } = hat;

  const BodyStructureIllustration = () => {
    switch (bodyStructure) {
      case HatBodyStructure.ONE_BY_ONE:
        return <BodyOneByOne />;
      case HatBodyStructure.TWO_BY_TWO:
        return <BodyTwoByTwo />;
      case HatBodyStructure.STOCKINETTE:
      default:
        return <BodyStockinette />;
    }
  };

  const BrimIllustration = () => {
    switch (brim) {
      case HatBrim.SINGLE:
        switch (brimStructure) {
          case HatBrimStructure.ONE_BY_ONE:
            return <BrimSingleOneByOne />;
          case HatBrimStructure.STOCKINETTE:
            return <BrimSingleStockinette />;
          case HatBrimStructure.TWO_BY_TWO:
            return <BrimSingleTwoByTwo />;
          default:
            return <></>;
        }
      case HatBrim.DOUBLE:
        switch (brimStructure) {
          case HatBrimStructure.ONE_BY_ONE:
            return <BrimDoubleOneByOne />;
          case HatBrimStructure.STOCKINETTE:
            return <BrimDoubleStockinette />;
          case HatBrimStructure.TWO_BY_TWO:
            return <BrimDoubleTwoByTwo />;
          default:
            return <></>;
        }
      case HatBrim.NO:
        if (
          bodyStructure === HatBodyStructure.STOCKINETTE &&
          brimStructure !== HatBrimStructure.STOCKINETTE
        ) {
          if (brimStructure === HatBrimStructure.ONE_BY_ONE) {
            return <BrimNoOneByOne />;
          } else if (brimStructure === HatBrimStructure.TWO_BY_TWO) {
            return <BrimNoTwoByTwo />;
          }
        }
        return <></>;
      default:
        return <></>;
    }
  };

  const DecreasesIllustration = () => {
    if (
      decreases === HatDecreases.SPIRAL &&
      (bodyStructure === HatBodyStructure.ONE_BY_ONE ||
        bodyStructure === HatBodyStructure.TWO_BY_TWO)
    )
      return <></>;

    switch (decreases) {
      case HatDecreases.SIDE:
        if (bodyStructure === HatBodyStructure.ONE_BY_ONE) {
          return <DecreasesSideOneByOne />;
        } else if (bodyStructure === HatBodyStructure.TWO_BY_TWO) {
          return <DecreasesSideTwoByTwo />;
        } else if (bodyStructure === HatBodyStructure.STOCKINETTE) {
          return <DecreasesSideStockinette />;
        }
        return <></>;
      case HatDecreases.CROSS:
        return <DecreasesCross />;
      case HatDecreases.SPIRAL:
      default:
        return <DecreasesSpiral />;
    }
  };

  return (
    <svg className={`hatIllustrationSVG ${className}`.trim()}>
      <BodyStructureIllustration />
      <BrimIllustration />
      <DecreasesIllustration />
    </svg>
  );
};

export default HatIllustration;
