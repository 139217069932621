import { Button } from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import { DeliveryMethodOptions } from "types/Checkout";
import { addCustomerToOrder } from "utils/api/updateOrderCustomer";
import { requiresDeliveryAndShippingForm } from "utils/requireDeliveryAndShippingForm";
import {
  getEmailShippingInformationFormValues,
  getMailShippingInformationFormValues,
  getNoYarnEmailValue,
  getPickupShippingInformationFormValues,
  getSelectedDeliveryMethod,
  hasAcceptedTerms,
  isEmailAddressValid,
  isFormValuesValid,
  isShippingFormValid,
} from "utils/shippingInformationFormUtils";
import { useCheckout } from "../../components/checkout/CheckoutContext";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayFormError, setDisplayFormError] = useState(false);
  const { t } = useTranslation();
  const { orderId, currentValue, clientSecret } = useCheckout();
  const { shoppingCart } = useShoppingCart();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret",
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage(
              t("checkout.checkoutForm.stripe.paymentSuccessful") ?? "",
            );
            break;
          case "processing":
            setMessage(
              t("checkout.checkoutForm.stripe.paymentProcessing") ?? "",
            );
            break;
          case "requires_payment_method":
            setMessage(
              t("checkout.errorMessage.stripePaymentUnsuccessful") ?? "",
            );
            break;
          default:
            setMessage(t("checkout.errorMessage.stripeGenericError") ?? "");
            break;
        }
      }
    });
  }, [stripe]);

  const completePayment = async () => {
    const selectedDeliveryMethod =
      getSelectedDeliveryMethod() as DeliveryMethodOptions;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const redirect_url = window.location.href.replace(
      "checkout",
      "success?id=",
    );

    let customerInfo;

    switch (selectedDeliveryMethod) {
      case DeliveryMethodOptions.PICKUP:
        customerInfo = getPickupShippingInformationFormValues();
        break;
      case DeliveryMethodOptions.MAIL:
        customerInfo = getMailShippingInformationFormValues();
        break;
      case DeliveryMethodOptions.NO_DELIVERY:
        customerInfo = getEmailShippingInformationFormValues();
        break;
      default:
        throw new Error(`Unknown delivery method: ${selectedDeliveryMethod}`);
    }
    const isFormValid =
      requiresDeliveryAndShippingForm(shoppingCart) &&
      isFormValuesValid(selectedDeliveryMethod, customerInfo);

    const isValid = isShippingFormValid(
      requiresDeliveryAndShippingForm(shoppingCart),
      isFormValid,
      isEmailAddressValid(getNoYarnEmailValue()),
      hasAcceptedTerms(),
    );

    if (!isValid) {
      setDisplayFormError(true);
      setMessage(t("checkout.checkoutForm.shipping.errorMessage") ?? "");
      return;
    } else if (isValid && displayFormError) {
      setDisplayFormError(false);
      setMessage("");
    }

    setIsLoading(true);
    const res = await addCustomerToOrder(
      orderId,
      customerInfo,
      selectedDeliveryMethod,
      clientSecret,
    );
    if (res.status === 200) {
      trackEvent(TrackingEvents.PURCHASE, {
        paymentMethod: "stripe",
        value: currentValue,
      });

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: redirect_url + orderId,
          payment_method_data: {
            billing_details: {
              email: customerInfo.email,
            },
          },
        },
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }

      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <form
        className="stripe-checkout-form"
        id="payment-form"
        onSubmit={(e) => e.preventDefault()}
      >
        <PaymentElement
          id="payment-element"
          options={
            {
              //billingDetails: { name: "auto", email: "auto" },
              //layout: { type: "accordion" },
            }
          }
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ "&:hover": { backgroundColor: "primary.light" } }}
          disabled={isLoading || !stripe || !elements}
          onClick={completePayment}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : (
              t("checkout.checkoutForm.complete")
            )}
          </span>
        </Button>
        {/* Show any error or success messages */}
        {message && (
          <div className="payment-error-message text-s">{message}</div>
        )}
      </form>
    </div>
  );
}
