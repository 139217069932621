import {
  Design,
  SetColorFunction,
  SetYarnFunction,
  SetYarnIsChosenFunction,
} from "store/DesignContext";
import { DesignChoices, Hat, Sweater } from "types/DesignChoices";
import {
  PatternCartItem,
  PatternItemDetails,
  ProductType,
  ShoppingCartItem,
  YarnCartItem,
} from "types/ShoppingCart";
import {
  HAT_PATTERN_PRICE_WITHOUT_YARN,
  HAT_PATTERN_PRICE_WITH_YARN,
  MITTENS_PATTERN_PRICE_WITHOUT_YARN,
  MITTENS_PATTERN_PRICE_WITH_YARN,
  SWEATER_PATTERN_PRICE_WITHOUT_YARN,
  SWEATER_PATTERN_PRICE_WITH_YARN,
} from "./constants/ShoppingCartConstants";

const loadSweaterPatternItemIntoDesignContext = (
  patternItem: PatternItemDetails<Sweater>,
  loadDesign: (design: Design) => void,
  setYarn: SetYarnFunction,
  setYarnIsChosen: SetYarnIsChosenFunction,
  setColor: SetColorFunction,
) => {
  const { yarn } = patternItem;

  setYarn(yarn);
  setYarnIsChosen(true);

  loadDesign({
    designChoices: patternItem.pattern,
    patternType: ProductType.SWEATERPATTERN,
  });

  setColor("#FFF");
};

export const loadSweaterDesignFromShoppingCart = (
  patternItem: PatternItemDetails<Sweater>,
  patternId: number,
  loadDesign: (design: Design) => void,
  setYarn: SetYarnFunction,
  setYarnIsChosen: SetYarnIsChosenFunction,
  setColor: SetColorFunction,
) => {
  loadSweaterPatternItemIntoDesignContext(
    patternItem,
    loadDesign,
    setYarn,
    setYarnIsChosen,
    setColor,
  );
  SessionStoragePatternId.set(patternId);
};

const loadHatPatternItemIntoDesignContext = (
  patternItem: PatternItemDetails<Hat>,
  loadDesign: (design: Design) => void,
  setYarn: SetYarnFunction,
  setYarnIsChosen: SetYarnIsChosenFunction,
  setColor: SetColorFunction,
) => {
  const { yarn } = patternItem;

  setYarn(yarn);
  setYarnIsChosen(true);

  loadDesign({
    designChoices: patternItem.pattern,
    patternType: ProductType.HATPATTERN,
  });

  setColor("#FFF");
};

export const loadHatDesignFromShoppingCart = (
  patternItem: PatternItemDetails<Hat>,
  patternId: number,
  loadDesign: (design: Design) => void,
  setYarn: SetYarnFunction,
  setYarnIsChosen: SetYarnIsChosenFunction,
  setColor: SetColorFunction,
) => {
  loadHatPatternItemIntoDesignContext(
    patternItem,
    loadDesign,
    setYarn,
    setYarnIsChosen,
    setColor,
  );
  SessionStoragePatternId.set(patternId);
};

/**
 * Sorts an array of shopping cart items according to the following rules
 * 1. Shopping cart item ID is the most important property, sorted in
 * ascending order
 * 2. If tie in shopping cart item ID, use product type ID as tiebreaker,
 * with SWEATERPATTERN coming first
 * @param shoppingCartItems the items to sort
 * @returns a sorted list containing the same items as the input
 */
export const sortShoppingCartItems = (
  shoppingCartItems: ShoppingCartItem[],
): ShoppingCartItem[] => {
  const sortedShoppingCartItems = shoppingCartItems.sort(
    (a: ShoppingCartItem, b: ShoppingCartItem) => {
      if (a.shoppingCartItemId < b.shoppingCartItemId) {
        return -1;
      } else if (a.shoppingCartItemId === b.shoppingCartItemId) {
        if (
          a.productTypeId === ProductType.SWEATERPATTERN ||
          a.productTypeId === ProductType.HATPATTERN
        ) {
          return -1;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    },
  );

  return sortedShoppingCartItems;
};

/**
 * Finds and returns the pattern item from the shopping cart if it exists and is of type pattern
 *
 * @param shoppingCartItems items from the current shoppingCart in context
 * @param shoppingCartItemId the id of the shopping cart item to find
 * @returns the shopping cart item with the given id, if it exists and is of type pattern
 */
export const findPatternItem = (
  shoppingCartItems: ShoppingCartItem[],
  shoppingCartItemId: number,
): PatternCartItem | undefined => {
  return shoppingCartItems.find((shoppingCartItem) => {
    const isPattern =
      shoppingCartItem.productTypeId === ProductType.SWEATERPATTERN ||
      shoppingCartItem.productTypeId === ProductType.HATPATTERN ||
      shoppingCartItem.productTypeId === ProductType.MITTENSPATTERN;
    const idMatches =
      shoppingCartItem.shoppingCartItemId === shoppingCartItemId;
    return isPattern && idMatches;
  }) as PatternCartItem | undefined; // TODO: don't typecast here
};

/**
 * Finds and returns the yarn item from the shopping cart if it exists and is of type yarn
 *
 * @param shoppingCartItems
 * @param shoppingCartItemId
 * @returns the shopping cart item with the given id, if it exists and is of type yarn
 */
export const findYarnItem = (
  shoppingCartItems: ShoppingCartItem[],
  shoppingCartItemId: number,
): YarnCartItem | undefined => {
  return shoppingCartItems.find(
    (shoppingCartItem) =>
      shoppingCartItem.shoppingCartItemId === shoppingCartItemId &&
      shoppingCartItem.productTypeId === ProductType.YARN,
  ) as YarnCartItem | undefined; // TODO: don't typecast here
};

/**
 * Calculates the price of the given pattern/pattern+yarn-combination.
 *
 * @param shoppingCartItems the list of items in the shopping cart
 * @param patternId the id of the current item in the shopping cart
 * @returns the price of the pattern (and yarn if applicable) corresponding to patternId
 */
export const getCurrentPrice = (
  shoppingCartItems: ShoppingCartItem[],
  patternId: number,
): number => {
  let itemPrice = 0;
  const patternItem = findPatternItem(shoppingCartItems, patternId);

  if (patternItem !== undefined) {
    itemPrice += patternItem.price;
    const yarnItem = findYarnItem(shoppingCartItems, patternId);
    if (yarnItem !== undefined) {
      itemPrice += yarnItem.price;
    }
  }

  return itemPrice;
};

/**
 * Performs a deep comparison between the sweater in DesignContext and
 * a sweater from the shopping cart.
 *
 * @param designStateContext the design choices from design context
 * @param shoppingCartItem the design choices from the shopping cart
 * @returns true if design choices are equivalent, false otherwise
 */
export const deepCompareDesignContextToShoppingCartPattern = (
  designStateContext: DesignChoices,
  shoppingCartItem: DesignChoices,
) => {
  const designContextKeys = Object.keys(designStateContext);
  const shoppingCartKeys = Object.keys(shoppingCartItem);

  if (designContextKeys.length !== shoppingCartKeys.length) return false;

  for (const designContextKey of designContextKeys) {
    const designContextVal = designStateContext[designContextKey];
    const shoppingCartVal = shoppingCartItem[designContextKey];
    if (designContextVal !== shoppingCartVal) return false;
  }

  return true;
};

export const SessionStoragePatternId = {
  set(patternId: number): void {
    sessionStorage.setItem("patternId", patternId.toString());
  },

  get(): number | null {
    const patternId = sessionStorage.getItem("patternId");
    if (patternId !== null) {
      return parseInt(patternId);
    }
    return patternId;
  },

  remove(): void {
    sessionStorage.removeItem("patternId");
  },
};

/**
 * Checks if a pattern is currently being modified, and if said pattern
 * has any yarn items connected to it. If yes, the yarn package is
 * removed from the shopping cart
 * @param shoppingCartItems the items in the shopping cart
 * @param removeYarnItem (context) function to remove yarn item
 * @return true if yarn item is removed from shopping cart, false otherwise
 */
export const conditionallyRemoveYarnItem = (
  shoppingCartItems: ShoppingCartItem[],
  removeYarnItem: (shoppingCartItemId: number) => void,
): boolean => {
  const patternId = SessionStoragePatternId.get();

  if (patternId !== null) {
    const yarnItem = findYarnItem(shoppingCartItems, patternId);

    if (yarnItem !== undefined) {
      removeYarnItem(patternId);
      return true;
    }
  }

  return false;
};

export function mapProductTypeToPrice(
  productType: ProductType,
  hasYarn = false,
): number {
  if (hasYarn) {
    switch (productType) {
      case ProductType.SWEATERPATTERN:
        return SWEATER_PATTERN_PRICE_WITH_YARN;
      case ProductType.HATPATTERN:
        return HAT_PATTERN_PRICE_WITH_YARN;
      case ProductType.MITTENSPATTERN:
        return MITTENS_PATTERN_PRICE_WITH_YARN;
      default:
        return 0;
    }
  }
  switch (productType) {
    case ProductType.SWEATERPATTERN:
      return SWEATER_PATTERN_PRICE_WITHOUT_YARN;
    case ProductType.HATPATTERN:
      return HAT_PATTERN_PRICE_WITHOUT_YARN;
    case ProductType.MITTENSPATTERN:
      return MITTENS_PATTERN_PRICE_WITHOUT_YARN;
    default:
      return 0;
  }
}
