import { DesignYarn } from "./Yarn";
import { Language } from "./Language";
import { DesignChoices, Sweater, Hat, Mittens } from "types/DesignChoices";

interface ShoppingCartItemBase {
  price: number;
  shoppingCartItemId: number;
  illustration: ShoppingCartItemIllustration;
}

export interface SweaterPatternCartItem extends ShoppingCartItemBase {
  productTypeId: ProductType.SWEATERPATTERN;
  productDetails: PatternItemDetails<Sweater>;
}

export interface HatPatternCartItem extends ShoppingCartItemBase {
  productTypeId: ProductType.HATPATTERN;
  productDetails: PatternItemDetails<Hat>;
}

export interface MittensPatternCartItem extends ShoppingCartItemBase {
  productTypeId: ProductType.MITTENSPATTERN;
  productDetails: PatternItemDetails<Mittens>;
}

export type PatternCartItem =
  | SweaterPatternCartItem
  | HatPatternCartItem
  | MittensPatternCartItem;

export interface YarnCartItem extends ShoppingCartItemBase {
  productTypeId: ProductType.YARN;
  productDetails: YarnItemDetails;
}

export type ShoppingCartItem = PatternCartItem | YarnCartItem;

export type SweaterPatternItemBeforeCommit = Omit<
  SweaterPatternCartItem,
  "shoppingCartItemId"
>;
export type HatPatternItemBeforeCommit = Omit<
  HatPatternCartItem,
  "shoppingCartItemId"
>;
export type MittensPatternItemBeforeCommit = Omit<
  MittensPatternCartItem,
  "shoppingCartItemId"
>;

export type PatternItemBeforeCommit =
  | SweaterPatternItemBeforeCommit
  | HatPatternItemBeforeCommit
  | MittensPatternItemBeforeCommit;

export type YarnItemBeforeCommit = Omit<YarnCartItem, "shoppingCartItemId">;

export interface ShoppingCart {
  items: ShoppingCartItem[];
}

export interface YarnItemDetails {
  variantYarn: YarnItem;
  secondVariantYarn?: YarnItem;
}

export enum ProductType {
  SWEATERPATTERN = 1,
  YARN = 2,
  HATPATTERN = 3,
  MITTENSPATTERN = 4,
}

export type ShoppingCartItemIllustration = JSX.Element;

export interface PatternItemDetails<T extends DesignChoices> {
  yarn: DesignYarn;
  pattern: T;
  language: Language;
}

type YarnItem = {
  productId: number;
  quantity: number;
  originalQuantity?: number;
  supplier: string;
  name: string;
  price: number;
};
