import EastIcon from "@mui/icons-material/East";
import patternCardStyles from "./PatternCard.module.scss";
import DreamknitLink from "components/Other/DreamknitLink";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";
import AppPath from "types/AppPath";

interface PatternCardProps {
  illustration?: string;
  navigateTo: string;
  children: React.ReactNode;
  name: string;
  price: string;
}

export function PatternCard({
  navigateTo,
  children,
  name,
  price,
}: PatternCardProps) {
  return (
    <DreamknitLink to={navigateTo} className={patternCardStyles["container"]}>
      <div
        className={patternCardStyles["left-items"]}
        onClick={() =>
          trackEvent(TrackingEvents.CHOOSE_PRODUCT, {
            product: navigateTo.substring(1),
          })
        }
      >
        <div className={patternCardStyles["illustration-container"]}>
          {children}
        </div>
        <div className={patternCardStyles["type-and-price-section"]}>
          <h2>{name}</h2>
          <p>{price}</p>
        </div>
      </div>
      <div className={patternCardStyles["right-arrow"]}>
        <EastIcon fontSize="large" />
      </div>
    </DreamknitLink>
  );
}
