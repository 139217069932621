import { Button } from "@mui/material";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import { APIPath } from "types/APIPath";
import { DeliveryMethodOptions } from "types/Checkout";
import { addCustomerToOrder } from "utils/api/updateOrderCustomer";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { requiresDeliveryAndShippingForm } from "utils/requireDeliveryAndShippingForm";
import {
  getEmailShippingInformationFormValues,
  getMailShippingInformationFormValues,
  getNoYarnEmailValue,
  getPickupShippingInformationFormValues,
  getSelectedDeliveryMethod,
  hasAcceptedTerms,
  isEmailAddressValid,
  isFormValuesValid,
  isShippingFormValid,
} from "utils/shippingInformationFormUtils";
import { useCheckout } from "./CheckoutContext";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";

interface VippsEcomButtonProps extends React.HTMLProps<HTMLButtonElement> {}

export default function VippsEcomButton({ onClick }: VippsEcomButtonProps) {
  const { t } = useTranslation();
  const { orderId, clientSecret } = useCheckout();
  const { shoppingCart } = useShoppingCart();
  const selectedDeliveryMethod =
    getSelectedDeliveryMethod() as DeliveryMethodOptions;

  const initiatePayment = async () => {
    let customerInfo;
    switch (selectedDeliveryMethod) {
      case DeliveryMethodOptions.PICKUP:
        customerInfo = getPickupShippingInformationFormValues();
        break;
      case DeliveryMethodOptions.MAIL:
        customerInfo = getMailShippingInformationFormValues();
        break;
      case DeliveryMethodOptions.NO_DELIVERY:
        customerInfo = getEmailShippingInformationFormValues();
        break;
      default:
        throw new Error(`Unknown delivery method: ${selectedDeliveryMethod}`);
    }
    const isFormValid =
      requiresDeliveryAndShippingForm(shoppingCart) &&
      isFormValuesValid(selectedDeliveryMethod, customerInfo);

    const isValid = isShippingFormValid(
      requiresDeliveryAndShippingForm(shoppingCart),
      isFormValid,
      isEmailAddressValid(getNoYarnEmailValue()),
      hasAcceptedTerms(),
    );

    if (!isValid) return;

    const addCustomerResponse = await addCustomerToOrder(
      orderId,
      customerInfo,
      selectedDeliveryMethod,
      clientSecret,
    );

    if (addCustomerResponse.status === 200) {
      const config = {
        url: `${process.env.REACT_APP_API_URL}${APIPath.INITIATE_VIPPS_PAYMENT}`,
        data: JSON.stringify({
          email: customerInfo.email,
          clientSecret: clientSecret,
          orderId,
        }),
      };

      const response = await axios
        .post(config.url, config.data)
        .catch((error) => {
          checkStatusAndLogApiError(config.url, error, config.data);
        });

      if (response === undefined || response.status !== 200) return;

      trackEvent(TrackingEvents.INITIATE_VIPPS_TRANSACTION, {
        orderId: orderId,
      });
      const url = response.data.url;
      if (url !== undefined) window.location.href = url;
    }
  };

  return (
    <Button
      color="primary"
      variant="contained"
      sx={{ "&:hover": { backgroundColor: "primary.light" } }}
      onClick={(e) => {
        initiatePayment();
        onClick !== undefined && onClick(e);
      }}
    >
      {t("checkout.checkoutForm.vipps.completeVipps")}
    </Button>
  );
}
