import { useEffect } from "react";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import { createDesignView } from "./DesignView";

const HatDesignView = () => {
  const { design, updatePatternType } = useDesign();

  useEffect(() => {
    updatePatternType(ProductType.HATPATTERN);
  }, []);

  if (design.patternType !== ProductType.HATPATTERN) {
    return <></>;
  }

  return <>{createDesignView(design)}</>;
};

export default HatDesignView;
