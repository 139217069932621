import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionHeader from "../DesignAccordionHeader/AccordionHeader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./AccordionStyles";
import genericAccorianStyles from "./GenericAccordion.module.scss";

interface GenericAccordionProps {
  accordionSections: {
    title: string;
    component: JSX.Element;
  }[];
}

const GenericAccordion = ({ accordionSections }: GenericAccordionProps) => {
  return (
    <div className={genericAccorianStyles["container"]}>
      {accordionSections.map(({ title, component }, index) => (
        <Accordion disableGutters elevation={0} square key={title}>
          <AccordionSummary
            key={`summary-${index}`}
            expandIcon={
              <ExpandMoreIcon
                sx={{ fontSize: "2rem", color: "black", fill: "black" }}
              />
            }
            elevation={0}
          >
            <AccordionHeader title={title} />
          </AccordionSummary>
          <AccordionDetails elevation={0} key={`detail-${index}`}>
            {component}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default GenericAccordion;
