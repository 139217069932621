import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "types/Language";
import { convertFromNOKToCurrency, getConversionRateUSD } from "utils/currency";
import { getLanguage } from "utils/intl/languageUtil";

interface AccordionPriceProps {
  itemPrice: number;
}

const AccordionPrice = (props: AccordionPriceProps) => {
  const { itemPrice } = props;
  const { t } = useTranslation();
  const [conversionRateUSD, setConversionRateUSD] = useState(0);

  const updateConversionRate = () => {
    getConversionRateUSD().then((res) => {
      setConversionRateUSD(res);
    });
  };

  useEffect(() => {
    updateConversionRate();
  }, []);

  const getDisplayPrice = () => {
    if (conversionRateUSD && getLanguage() === Language.EN) {
      const convertedPrice = convertFromNOKToCurrency(
        itemPrice,
        conversionRateUSD,
        1,
      );
      return `${t("design.designAccordion.cartSection.price")} ${t(
        "checkout.orderSummary.currentPrice",
        {
          price: Math.round(itemPrice),
          currency: t("checkout.currency"),
        },
      )} ${t("checkout.orderSummary.equivalentPrice")} ${convertedPrice}`;
    } else {
      // Display the base price
      return `${t(
        "design.designAccordion.cartSection.price",
      )}: ${itemPrice} NOK`;
    }
  };

  return <p>{getDisplayPrice()}</p>;
};

export default AccordionPrice;
