import React from "react";
import "./NeedleSizeMapping.scss";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

function createData(metric: string, us: string, uk: string) {
  return { metric, us, uk };
}

const rows = [
  createData("2.0 mm", "0", "14"),
  createData("2.5 mm", "1.5", "-"),
  createData("3.0 mm", "2.5", "11"),
  createData("3.5 mm", "4", "-"),
  createData("4.0 mm", "6", "8"),
  createData("4.5 mm", "7", "7"),
  createData("5.0 mm", "8", "6"),
  createData("5.5 mm", "9", "5"),
  createData("6.0 mm", "10", "4"),
  createData("7.0 mm", "-", "2"),
  createData("8.0 mm", "11", "0"),
  createData("9.0 mm", "13", "00"),
  createData("10.0 mm", "15", "000"),
];

const NeedleSizeMappingTable = () => {
  const StyledTableCell = styled(TableCell)({
    padding: 5,
    width: "50%",
  });
  const StyledTableCellSmall = styled(TableCell)({
    padding: 5,
    width: "25%",
  });

  return (
    <div className="needlesizeTableContainer">
      <TableContainer className="needlesizeTable">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Metric</StyledTableCell>
              <StyledTableCellSmall>US</StyledTableCellSmall>
              <StyledTableCellSmall>UK</StyledTableCellSmall>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.metric}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell>{row.metric}</StyledTableCell>
                <StyledTableCellSmall>{row.us}</StyledTableCellSmall>
                <StyledTableCellSmall>{row.uk}</StyledTableCellSmall>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NeedleSizeMappingTable;
