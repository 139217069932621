import { PartialBy } from "types/typeUtils";
// Bol
import { ReactComponent as BolOversizedcropped } from "assets/sweaterBuild/bol/oversizedcropped.svg";
import { ReactComponent as BolOversizedregular } from "assets/sweaterBuild/bol/oversizedregular.svg";
import { ReactComponent as BolRaglancropped } from "assets/sweaterBuild/bol/raglancropped.svg";
import { ReactComponent as BolRaglanregular } from "assets/sweaterBuild/bol/raglanregular.svg";
import { ReactComponent as BolRegularcropped } from "assets/sweaterBuild/bol/regularcropped.svg";
import { ReactComponent as BolRegularregular } from "assets/sweaterBuild/bol/regularregular.svg";
import { ReactComponent as BolVestoversizedcropped } from "assets/sweaterBuild/bol/vestoversizedcropped.svg";
import { ReactComponent as BolVestoversizedregular } from "assets/sweaterBuild/bol/vestoversizedregular.svg";
import { ReactComponent as BolVestregularcropped } from "assets/sweaterBuild/bol/vestregularcropped.svg";
import { ReactComponent as BolVestregularregular } from "assets/sweaterBuild/bol/vestregularregular.svg";
// Neckline
import { ReactComponent as NecklineMockneck } from "assets/sweaterBuild/neckline/mockneck.svg";
import { ReactComponent as NecklineRegular } from "assets/sweaterBuild/neckline/regular.svg";
import { ReactComponent as NecklineTurtleneck } from "assets/sweaterBuild/neckline/turtleneck.svg";
import { ReactComponent as NecklineVneck } from "assets/sweaterBuild/neckline/vneck.svg";
// Sleeve
import { ReactComponent as SleeveCroppedballoon } from "assets/sweaterBuild/sleeve/croppedballoon.svg";
import { ReactComponent as SleeveCroppedboxy } from "assets/sweaterBuild/sleeve/croppedboxy.svg";
import { ReactComponent as SleeveCroppedboxyV2 } from "assets/sweaterBuild/sleeve/croppedboxy_v2.svg";
import { ReactComponent as SleeveCroppedregular } from "assets/sweaterBuild/sleeve/croppedregular.svg";
import { ReactComponent as SleeveCroppedregularV2 } from "assets/sweaterBuild/sleeve/croppedregular_v2.svg";
import { ReactComponent as SleeveRegularballoon } from "assets/sweaterBuild/sleeve/regularballoon.svg";
import { ReactComponent as SleeveRegularboxy } from "assets/sweaterBuild/sleeve/regularboxy.svg";
import { ReactComponent as SleeveRegularregular } from "assets/sweaterBuild/sleeve/regularregular.svg";
import { ReactComponent as SleeveShort } from "assets/sweaterBuild/sleeve/shortmedium.svg";
import {
  Sweater,
  SweaterBodyLength,
  SweaterBodyWidth,
  SweaterConstruction,
  SweaterNeckline,
  SweaterSleeve,
} from "types/DesignChoices/Sweater";

interface SweaterIllustrationProps {
  sweater: PartialBy<Sweater, "size" | "yardageMeter" | "skeins">;
  className?: string;
}

const SweaterIllustration = (props: SweaterIllustrationProps) => {
  const { sweater, className } = props;
  const construction = sweater.construction;
  const bodyWidth = sweater.bodyWidth;
  const bodyLength = sweater.bodyLength;
  const neckline = sweater.neckline;
  const sleeve = sweater.sleeve;
  return (
    <svg className={`sweaterIllustrationSVG ${className}`.trim()}>
      {/* Neckline */}
      {neckline === SweaterNeckline.REGULAR ? (
        <NecklineRegular />
      ) : neckline === SweaterNeckline.VNECK ? (
        <NecklineVneck />
      ) : neckline === SweaterNeckline.MOCKNECK ? (
        <NecklineMockneck />
      ) : neckline === SweaterNeckline.TURTLENECK ? (
        <NecklineTurtleneck />
      ) : null}

      {/* Sleeve */}
      {sleeve !== SweaterSleeve.NOSLEEVE &&
      bodyLength === SweaterBodyLength.REGULAR &&
      sleeve === SweaterSleeve.BALLOON ? (
        <SleeveRegularballoon />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyLength === SweaterBodyLength.REGULAR &&
        sleeve === SweaterSleeve.BOXY ? (
        <SleeveRegularboxy />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyLength === SweaterBodyLength.REGULAR &&
        sleeve === SweaterSleeve.REGULAR ? (
        <SleeveRegularregular />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyLength === SweaterBodyLength.CROPPED &&
        sleeve === SweaterSleeve.BALLOON ? (
        <SleeveCroppedballoon />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        neckline === SweaterNeckline.VNECK &&
        bodyLength === SweaterBodyLength.CROPPED &&
        sleeve === SweaterSleeve.BOXY ? (
        <SleeveCroppedboxyV2 />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        neckline === SweaterNeckline.VNECK &&
        bodyLength === SweaterBodyLength.CROPPED &&
        sleeve === SweaterSleeve.REGULAR ? (
        <SleeveCroppedregularV2 />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyLength === SweaterBodyLength.CROPPED &&
        sleeve === SweaterSleeve.BOXY ? (
        <SleeveCroppedboxy />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyLength === SweaterBodyLength.CROPPED &&
        sleeve === SweaterSleeve.REGULAR ? (
        <SleeveCroppedregular />
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        sleeve === SweaterSleeve.SHORT ? (
        <SleeveShort />
      ) : null}

      {/* Bol */}
      {sleeve !== SweaterSleeve.NOSLEEVE &&
      bodyWidth === SweaterBodyWidth.REGULAR &&
      bodyLength === SweaterBodyLength.REGULAR ? (
        construction === SweaterConstruction.RAGLAN ? (
          <BolRaglanregular />
        ) : (
          <BolRegularregular />
        )
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyWidth === SweaterBodyWidth.REGULAR &&
        bodyLength === SweaterBodyLength.CROPPED ? (
        construction === SweaterConstruction.RAGLAN ? (
          <BolRaglancropped />
        ) : (
          <BolRegularcropped />
        )
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyWidth === SweaterBodyWidth.OVERSIZED &&
        bodyLength === SweaterBodyLength.REGULAR ? (
        construction === SweaterConstruction.RAGLAN ? (
          <BolRaglanregular />
        ) : (
          <BolOversizedregular />
        )
      ) : sleeve !== SweaterSleeve.NOSLEEVE &&
        bodyWidth === SweaterBodyWidth.OVERSIZED &&
        bodyLength === SweaterBodyLength.CROPPED ? (
        construction === SweaterConstruction.RAGLAN ? (
          <BolRaglancropped />
        ) : (
          <BolOversizedcropped />
        )
      ) : null}

      {/* Vest (nosleeve) */}
      {sleeve === SweaterSleeve.NOSLEEVE &&
      bodyWidth === SweaterBodyWidth.REGULAR &&
      bodyLength === SweaterBodyLength.REGULAR ? (
        <BolVestregularregular />
      ) : sleeve === SweaterSleeve.NOSLEEVE &&
        bodyWidth === SweaterBodyWidth.REGULAR &&
        bodyLength === SweaterBodyLength.CROPPED ? (
        <BolVestregularcropped />
      ) : sleeve === SweaterSleeve.NOSLEEVE &&
        bodyWidth === SweaterBodyWidth.OVERSIZED &&
        bodyLength === SweaterBodyLength.REGULAR ? (
        <BolVestoversizedregular />
      ) : sleeve === SweaterSleeve.NOSLEEVE &&
        bodyWidth === SweaterBodyWidth.OVERSIZED &&
        bodyLength === SweaterBodyLength.CROPPED ? (
        <BolVestoversizedcropped />
      ) : null}
    </svg>
  );
};

export default SweaterIllustration;
