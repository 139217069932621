import DkRadioButton from "components/Other/Form/DkRadioButton";
import { FormControl, RadioGroup, RadioGroupProps } from "@mui/material";

export type RadioButtonOption<T extends string> = {
  value: T;
  label: JSX.Element | string;
  children?: JSX.Element;
  unmountOnExit?: boolean;
  labelTitle?: JSX.Element;
  inactive?: boolean;
};

interface DkRadioButtonGroupProps<T extends string> extends RadioGroupProps {
  options: RadioButtonOption<T>[];
  setSelectedValue: (value: T) => void;
  defaultValue?: T;
  fullWidth?: boolean;
}

const DkRadioButtonGroup = <T extends string>(
  props: DkRadioButtonGroupProps<T>,
) => {
  const { defaultValue, options, setSelectedValue, ...otherProps } = props;

  const RadioButtons = () => {
    return (
      <>
        {options.map((option) => {
          return (
            <DkRadioButton
              value={option.value}
              label={option.label}
              labelTitle={option.labelTitle}
              key={option.value}
              id={`dk-radio-button-${option.value}`}
              inactive={option.inactive}
            >
              {!option.inactive && option.children}
            </DkRadioButton>
          );
        })}
      </>
    );
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup
        sx={{ alignContent: "center" }}
        onChange={(e) => {
          setSelectedValue(e.target.value as T);
        }}
        defaultValue={defaultValue}
        {...otherProps}
      >
        <RadioButtons />
      </RadioGroup>
    </FormControl>
  );
};

export default DkRadioButtonGroup;
