import { Grid, Link, Popover } from "@mui/material";
import NeedleSizeMappingTable from "components/Other/NeedleSizeMapping/NeedleSizeMappingTable";

interface NeedlesizeGuideProps {
  handleCloseNeedleSizeTable: () => void;
  handleOpenNeedleSizeTable: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  anchorEl: HTMLElement | null;
}

export default function NeedleSizeGuide({
  handleOpenNeedleSizeTable,
  handleCloseNeedleSizeTable,
  anchorEl,
}: NeedlesizeGuideProps) {
  const openNeedleSizeTable = Boolean(anchorEl);
  const id = openNeedleSizeTable ? "simple-popover" : undefined;

  return (
    <Grid item xs={12} className="needlesizeGridContainer">
      <Link
        sx={{
          cursor: "pointer",
          margin: 0,
          alignSelf: "start",
          color: "secondary.dark",
        }}
        onClick={handleOpenNeedleSizeTable}
        underline="always"
      >
        Needle size guide
      </Link>
      <Popover
        className="popover"
        id={id}
        open={openNeedleSizeTable}
        anchorEl={anchorEl}
        onClose={handleCloseNeedleSizeTable}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <NeedleSizeMappingTable />
      </Popover>
    </Grid>
  );
}
