import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Folders } from "types/APIPath";

interface YarnImageContainerProps {
  imgLocation?: string;
}

const YarnImageContainer = (props: YarnImageContainerProps) => {
  const { imgLocation = "" } = props;
  const { t } = useTranslation();

  return (
    <Container
      className="detailed-yarn-view-section"
      style={{ maxWidth: "450px" }}
    >
      <img
        src={`${process.env.PUBLIC_URL}${Folders.YARNS}/${imgLocation}`}
        alt={t("yarndb.detailedYarnView.imageAltText") ?? ""}
        id="detailed-yarn-image"
      />
    </Container>
  );
};

export default YarnImageContainer;
