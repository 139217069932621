export const NEEDLE_SIZES = [
  "3.0",
  "3.5",
  "4.0",
  "4.5",
  "5.0",
  "5.5",
  "6.0",
  "7.0",
  "8.0",
  "9.0",
  "10.0",
  "11.0",
  "12.0",
  "13.0",
  "14.0",
];
