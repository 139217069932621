import React from "react";
import "./BasicCard.scss";

interface BasicCardProps {
  button?: any;
}

const BasicCard = (props: React.PropsWithChildren<BasicCardProps>) => {
  return <div className="basicCard">{props.children}</div>;
};

export default BasicCard;
