import SweaterIllustration from "components/Design/IllustrationContainer/SweaterIllustration/SweaterIllustration";
import { PatternCard } from "components/PatternOverview/PatternCard";
import { defaultPattern } from "components/PatternOverview/premadeIllustrations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppPath from "types/AppPath";
import { Language } from "types/Language";
import {
  HAT_PATTERN_PRICE_WITHOUT_YARN,
  SWEATER_PATTERN_PRICE_WITHOUT_YARN,
} from "utils/constants/ShoppingCartConstants";
import { convertFromNOKToCurrency, getConversionRateUSD } from "utils/currency";
import { getLanguage } from "utils/intl/languageUtil";
import patternOverviewStyles from "./PatternOverview.module.scss";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import HatIllustration from "components/Design/IllustrationContainer/HatIllustration";
import { DEFAULT_HAT } from "types/DesignChoices/Hat";
import { SessionStoragePatternId } from "utils/shoppingCartUtils";

export function PatternOverview() {
  const { t } = useTranslation();
  const [conversionRate, setConversionRate] = useState(0);
  const { updatePatternType, setYarn, setYarnIsChosen } = useDesign();

  useEffect(() => {
    getConversionRateUSD().then((rate) => setConversionRate(rate));
  }, []);

  function displayPrice(priceNok: number) {
    return `${
      conversionRate && getLanguage() === Language.EN
        ? convertFromNOKToCurrency(priceNok, conversionRate)
        : priceNok
    } ${t("genericTerms.currency")}`;
  }

  function resetYarn() {
    setYarn({
      yarnId: undefined,
      name: "",
      secondName: undefined,
      supplier: "",
      needlesize: 0,
      yardage: 0,
      secondYardage: 0,
      gaugeWidth: 0,
    });
    setYarnIsChosen(false);
  }

  return (
    <div className={patternOverviewStyles["container"]}>
      <div
        className={patternOverviewStyles["pattern-card-wrapper"]}
        onClick={() => {
          updatePatternType(ProductType.SWEATERPATTERN);
          resetYarn();
          SessionStoragePatternId.remove();
        }}
        data-cy="PatternCard-sweater"
      >
        <PatternCard
          navigateTo={AppPath.SWEATER}
          name={t("genericTerms.sweater")}
          price={displayPrice(SWEATER_PATTERN_PRICE_WITHOUT_YARN)}
        >
          <SweaterIllustration
            sweater={defaultPattern}
            className={patternOverviewStyles["illustration-svg"]}
          />
        </PatternCard>
      </div>
      <div
        className={patternOverviewStyles["pattern-card-wrapper"]}
        onClick={() => {
          updatePatternType(ProductType.HATPATTERN);
          resetYarn();
          SessionStoragePatternId.remove();
        }}
        data-cy="hat-pattern-card"
      >
        <PatternCard
          navigateTo={AppPath.HAT}
          name={t("genericTerms.hat")}
          price={displayPrice(HAT_PATTERN_PRICE_WITHOUT_YARN)}
        >
          <HatIllustration
            hat={DEFAULT_HAT}
            className={patternOverviewStyles["illustration-svg"]}
          />
        </PatternCard>
      </div>
    </div>
  );
}
