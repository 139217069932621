import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import "./ConstructionSelectionBox.scss"; // REMOVE
import "./FitSelectionBox.scss"; // REMOVE
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { HandleClickHatData } from "components/Design/DesignAccordion/HatAccordion";
import { Hat } from "types/DesignChoices";
import { HatDecreases } from "types/Accessories";
import { HatKeys } from "types/DesignChoices/Hat";

const DecreasesSelectionBox = (props: { handleClick: HandleClickHatData }) => {
  const { design, updateDesign } = useDesign();
  const { t } = useTranslation();

  const useEffectDependencies = [
    HatKeys.DECREASES in design.designChoices
      ? design.designChoices.decreases
      : "",
  ];

  const [activeClassDecreases, setActiveClassDecreases] = useState(
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : "",
  );

  useEffect(() => {
    if (design.patternType === ProductType.HATPATTERN) {
      setActiveClassDecreases(design.designChoices.decreases);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);

  const decreasesOptions = createSelectionOptions<Hat>({
    keys: Object.values(HatDecreases),
    dispatchFunction: updateDesign,
    designChoiceCategory: HatKeys.DECREASES,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassDecreases,
    translationMapper: (key) => mapKeyToTranslationKey(key, HatKeys.DECREASES),
  });

  return (
    <SelectionContainer>
      <SelectionOptionsBox
        options={decreasesOptions}
        activeOption={activeClassDecreases}
        displayType="row"
      />
    </SelectionContainer>
  );
};

export default DecreasesSelectionBox;
