import pageNotFoundImage from "assets/404.png";
import ColorButton from "components/Other/ColorButton/ColorButton";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { logError } from "utils/logging/applicationInsightsLogger";
import "./pageNotFoundView.scss";

const PageNotFoundView = () => {
  const { t } = useTranslation();

  useEffect(() => {
    logError(
      Error(
        `User tried accessing ${window.location.pathname}, but page doesn't exist`,
      ),
    );
  }, []);

  return (
    <div className="pageNotFound">
      <img src={pageNotFoundImage} width="80%" alt="Page Not Found" />
      <div className="pageNotFoundText">
        <h1>{t("pageNotFound.title")}</h1>
        <Trans i18nKey="pageNotFound.content">
          <p>
            Det ser ut som om siden du leter etter ikke eksisterer, dette må vi
            nesten rekke opp i. Dersom du mener dette ikke stemmer kan du sende
            mail til <a href="mailto:hello@dreamknit.no">hello@dreamknit.no</a>
          </p>
        </Trans>
        <div className="button">
          <a className="navLink" href="https://www.dreamknit.com/">
            <ColorButton>{t("pageNotFound.button")}</ColorButton>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundView;
