import { Needlesizes } from "hooks/useGaugeWidthAndNeedlesizeAPI";
import { Design, useDesign } from "store/DesignContext";
import { HatBodyStructure, HatKeys } from "types/DesignChoices/Hat";

interface NeedlesizeProps {
  needlesizes: Needlesizes;
}

const Needlesize = ({ needlesizes }: NeedlesizeProps) => {
  const { design } = useDesign();

  const getDesignStructure = (design: Design) =>
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : HatBodyStructure.STOCKINETTE;

  const getNeedlesize = (design: Design, needlesizes: Needlesizes) => {
    let needlesize: number;

    const isStockinette =
      getDesignStructure(design) === HatBodyStructure.STOCKINETTE;
    if (isStockinette || needlesizes.rib === undefined) {
      needlesize = needlesizes.stockinette.needlesize;
    } else {
      needlesize = needlesizes.rib.needlesize;
    }

    return needlesize.toFixed(1);
  };

  return <span>{getNeedlesize(design, needlesizes)} mm</span>;
};

export default Needlesize;
