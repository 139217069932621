import { Button, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { TrackingEvents, trackEvent } from "utils/logging/tracking";
import { conditionallyRemoveYarnItem } from "utils/shoppingCartUtils";
import { useShoppingCart } from "store/ShoppingCartContext";
import { CalculateHatYardage, CalculateSweaterYardage } from "types/APITypes";
import { SweaterKeys } from "types/DesignChoices/Sweater";
import { ProductType } from "types/ShoppingCart";
import { CustomYarn } from "types/Yarn";
import { CalculateYardageApi } from "utils/api/CalculateYardageApi";
import { GAUGE_WIDTHS, YARDAGES } from "utils/constants/helpers";
import { NEEDLE_SIZES } from "utils/constants/needlesizes";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import { setUrlParamsFromYarnAndDesign } from "utils/setUrlParams";
import InputField from "./AutocompleteTextField/InputField";

const AddYarnInputFields = (props) => {
  const { design, updateDesign, yarn, setYarn, setYarnIsChosen } = useDesign();
  const { t } = useTranslation();
  const { removeYarnFromShoppingCart, shoppingCart } = useShoppingCart();
  const [nameInput, setNameInput] = useState<string>("");
  const [needlesizeInput, setNeedlesizeInput] = useState<number>(0);
  const [gaugewidthInput, setGaugewidthInput] = useState<number>(0);
  const [yardageInput, setYardageInput] = useState<number>(0);
  const pushToHistory = useDreamknitHistory();

  if (design.patternType === ProductType.MITTENSPATTERN) return <></>;

  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;

  const handleInputField = (fieldname, value) => {
    if (fieldname === "needlesize") {
      setNeedlesizeInput(parseFloat(value) || 0);
    } else if (fieldname === "gaugeWidth") {
      setGaugewidthInput(parseInt(value) || 0);
    } else if (fieldname === "yardage") {
      setYardageInput(parseInt(value) || 0);
    }
  };
  const validateInputFields: () => boolean = () => {
    return (
      nameInput !== "" &&
      needlesizeInput !== 0 &&
      gaugewidthInput !== 0 &&
      yardageInput !== 0
    );
  };

  const handleClick = () => {
    setYarn({
      yarnId: undefined,
      name: nameInput,
      secondName: undefined,
      supplier: "",
      needlesize: needlesizeInput,
      yardage: yardageInput,
      secondYardage: undefined,
      gaugeWidth: gaugewidthInput,
    });

    conditionallyRemoveYarnItem(shoppingCart.items, removeYarnFromShoppingCart);
    let data: CalculateSweaterYardage | CalculateHatYardage;
    switch (design.patternType) {
      case ProductType.SWEATERPATTERN:
        data = {
          gaugeWidth: gaugewidthInput,
          needlesize: needlesizeInput,
          secondYardage: undefined,
          yardage: yardageInput,
          size: design.designChoices.size,
          bodyLength: design.designChoices.bodyLength,
          bodyWidth: design.designChoices.bodyWidth,
          construction: design.designChoices.construction,
          neckline: design.designChoices.neckline,
          sleeve: design.designChoices.sleeve,
          productTypeId: design.patternType,
        };
        break;
      case ProductType.HATPATTERN:
        data = {
          gaugeWidth: gaugewidthInput,
          needlesize: needlesizeInput,
          secondYardage: undefined,
          yardage: yardageInput,
          size: design.designChoices.size,
          decreases: design.designChoices.decreases,
          brim: design.designChoices.brim,
          brimStructure: design.designChoices.brimStructure,
          bodyStructure: design.designChoices.bodyStructure,
          productTypeId: design.patternType,
        };
        break;
    }

    const customYarn: CustomYarn = {
      name: nameInput,
      needlesize: needlesizeInput,
      yardage: yardageInput,
      gaugeWidth: gaugewidthInput,
    };
    setUrlParamsFromYarnAndDesign({
      design,
      yarn,
      pushToHistory,
      customYarn,
    });

    CalculateYardageApi.post(data).then((res) => {
      updateDesign(SweaterKeys.YARDAGE_METER, res.data.yardageMeter);
      updateDesign(SweaterKeys.SKEINS, res.data.skeins);
    });
    setYarnIsChosen(true);
  };
  return (
    <>
      <FormControl sx={{ display: "flex" }} data-cy="addYarnForm">
        <TextField
          color="primary"
          size="small"
          sx={{ margin: "10px 15px 6px 15px" }}
          label={t("design.designAccordion.yarn.yarnName")}
          InputLabelProps={{ style: { color: "black", marginTop: "-7px" } }}
          InputProps={{ notched: false }}
          onChange={(event) => {
            setNameInput(event.target.value);
          }}
          required
          data-cy="addYarnNameInput"
        />
        <InputField
          placeholder={t("design.designAccordion.yarn.needlesize")}
          options={NEEDLE_SIZES}
          field={"needlesize"}
          handleInput={handleInputField}
          data-cy="addYarnNeedleSizeInput"
        />
        <InputField
          placeholder={t("design.designAccordion.yarn.gauge")}
          options={GAUGE_WIDTHS}
          field={"gaugeWidth"}
          handleInput={handleInputField}
          data-cy="addYarnGaugeWidthInput"
        />
        <InputField
          placeholder={t("design.designAccordion.yarn.yardage")}
          options={YARDAGES}
          field={"yardage"}
          handleInput={handleInputField}
          data-cy="addYarnYardageInput"
        />
      </FormControl>
      <Button
        className="button"
        variant="contained"
        onClick={() => {
          handleClick();
          if (mobileView) {
            props.handleClose();
          }
          trackEvent(TrackingEvents.ADD_CUSTOM_YARN, {
            yarn: nameInput,
            needlesize: needlesizeInput,
            yardage: yardageInput,
            gaugeWidth: gaugewidthInput,
          });
        }}
        fullWidth
        data-cy="addYarnButtonComplete"
        disabled={!validateInputFields()}
      >
        {t("design.designAccordion.yarn.select")}
      </Button>
    </>
  );
};

export default AddYarnInputFields;
