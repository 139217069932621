import { Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ColorButton from "components/Other/ColorButton/ColorButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCard from "../../BasicCard/BasicCard";
import PopUpWindow from "../PopUpWindow/PopUpWindow";
import "./AddYarnCard.scss";
import AddYarnCardBody from "./AddYarnCardBody";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "transparent",
}));

const AddYarnCard = () => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;

  const handleSetShowInfo = () => {
    setShowInfo((value) => !value);
  };

  return (
    <BasicCard>
      {showInfo ? (
        <AddYarnCardBody closeInputFields={handleSetShowInfo} />
      ) : (
        <div className="addYarnCard">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ height: "inherit" }}
          >
            <Item elevation={0}>
              <h3>{t("yarndb.addCustomYarn")}</h3>
            </Item>
            <Item elevation={0}>
              {mobileView ? (
                <PopUpWindow buttonText={t("yarndb.fillIn")} />
              ) : (
                <ColorButton
                  onClick={handleSetShowInfo}
                  data-cy="addYarnButton"
                >
                  {t("yarndb.fillIn")}
                </ColorButton>
              )}
            </Item>
          </Stack>
        </div>
      )}
    </BasicCard>
  );
};

export default AddYarnCard;
