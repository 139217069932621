enum AppPath {
  YARN = "/yarn",
  CHECKOUT = "/checkout",
  STATUS = "/status",
  SUCCESS = "/success",
  CANCEL = "/cancel",
  SWEATER = "/sweater",
  HAT = "/beanie",
  BASE = "/",
}

export default AppPath;
