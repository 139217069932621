import { Design } from "store/DesignContext";
import AppPath from "types/AppPath";
import { DesignYarn } from "types/Yarn";
import { ProductType } from "types/ShoppingCart";
import { CustomYarn } from "types/Yarn";

interface setUrlParamsProps {
  yarn: DesignYarn;
  design: Design;
  pushToHistory: ({ pathname, search }) => void;
  customYarn?: CustomYarn;
  yarnInfo?: { id: string; name: string };
}

export const setUrlParamsFromYarnAndDesign = ({
  yarn,
  design,
  pushToHistory,
  customYarn,
  yarnInfo,
}: setUrlParamsProps) => {
  setUrlParams({
    design,
    yarn,
    pushToHistory,
    customYarn,
    yarnInfo,
  });
};

export interface setUrlParamProps {
  design: Design;
  yarn: DesignYarn;
  pushToHistory: ({ pathname, search }) => void;
  customYarn?: CustomYarn;
  yarnInfo?: { id: string; name: string };
}

export const setUrlParams = ({
  design,
  yarn,
  pushToHistory,
  customYarn,
  yarnInfo,
}: setUrlParamProps) => {
  let yarnId, yarnName;
  if (
    (yarn.yarnId !== undefined && JSON.parse(yarn.yarnId) !== null) ||
    yarnInfo !== undefined
  ) {
    yarnId = yarnInfo?.id ?? yarn.yarnId;
    yarnName = yarnInfo?.name ?? yarn.name;
  } else if (customYarn !== undefined) {
    yarnId = null;
    yarnName = customYarn.name;
  }

  let searchString = "";
  if (typeof customYarn !== "undefined") {
    searchString += `?yarn=${encodeURIComponent(customYarn.name)}&id=${null}`;
    searchString += `&gaugeWidth=${encodeURIComponent(customYarn.gaugeWidth)}`;
    searchString += `&needlesize=${encodeURIComponent(customYarn.needlesize)}`;
    searchString += `&yardage=${encodeURIComponent(customYarn.yardage)}`;
  } else if (yarnId) {
    searchString += `?yarn=${encodeURIComponent(
      yarnName,
    )}&id=${encodeURIComponent(yarnId)}`;
  }

  let path = AppPath.BASE;
  switch (design.patternType) {
    case ProductType.SWEATERPATTERN:
      searchString += `&sleeve=${encodeURIComponent(
        design.designChoices.sleeve,
      )}`;
      searchString += `&bodyWidth=${encodeURIComponent(
        design.designChoices.bodyWidth,
      )}`;
      searchString += `&bodyLength=${encodeURIComponent(
        design.designChoices.bodyLength,
      )}`;
      searchString += `&neckline=${encodeURIComponent(
        design.designChoices.neckline,
      )}`;
      searchString += `&size=${encodeURIComponent(design.designChoices.size)}`;
      searchString += `&construction=${encodeURIComponent(
        design.designChoices.construction,
      )}`;
      path = AppPath.SWEATER;
      break;
    case ProductType.HATPATTERN:
      searchString += `&size=${encodeURIComponent(design.designChoices.size)}`;
      searchString += `&bodyStructure=${encodeURIComponent(
        design.designChoices.bodyStructure,
      )}`;
      searchString += `&brim=${encodeURIComponent(design.designChoices.brim)}`;
      searchString += `&brimStructure=${encodeURIComponent(
        design.designChoices.brimStructure,
      )}`;
      searchString += `&decreases=${encodeURIComponent(
        design.designChoices.decreases,
      )}`;
      path = AppPath.HAT;
      break;
  }

  pushToHistory({
    pathname: path,
    search: searchString,
  });
};

export const extractCustomYarnFromUrl = (
  url: URLSearchParams,
): CustomYarn | undefined => {
  const name = url.get("yarn");
  const gaugeWidth = url.get("gaugeWidth");
  const needlesize = url.get("needlesize");
  const yardage = url.get("yardage");

  if (
    name === null ||
    gaugeWidth === null ||
    needlesize === null ||
    yardage === null
  )
    return undefined;

  return {
    name,
    gaugeWidth: parseInt(gaugeWidth),
    needlesize: parseFloat(needlesize),
    yardage: parseInt(yardage),
  };
};
