import DkTextField, { DkTextFieldProps } from "./DkTextField";

const DkEmailInputField = (props: DkTextFieldProps) => {
  return (
    <DkTextField
      {...props}
      inputProps={{ type: "email", "auto-complete": "email" }}
    />
  );
};

export default DkEmailInputField;
