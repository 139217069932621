import { DesignChoicesBase } from "./DesignChoices";

const searchParams = new URLSearchParams(window.location.search);

export enum SweaterSize {
  XS = "xs",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
  XXL = "xxl",
}

export enum SweaterConstruction {
  SETIN = "setin",
  RAGLAN = "raglan",
}

export enum SweaterBodyWidth {
  REGULAR = "regular",
  OVERSIZED = "oversized",
}

export enum SweaterBodyLength {
  REGULAR = "regular",
  CROPPED = "cropped",
}
export enum SweaterNeckline {
  REGULAR = "regular",
  VNECK = "vneck",
  MOCKNECK = "mockneck",
  TURTLENECK = "turtleneck",
}

export enum SweaterSleeve {
  REGULAR = "regular",
  BALLOON = "balloon",
  BOXY = "boxy",
  NOSLEEVE = "nosleeve",
  SHORT = "tshirt",
}

export interface Sweater extends DesignChoicesBase {
  size: SweaterSize;
  construction: SweaterConstruction;
  bodyWidth: SweaterBodyWidth;
  bodyLength: SweaterBodyLength;
  neckline: SweaterNeckline;
  sleeve: SweaterSleeve;
}

export enum SweaterKeys {
  SIZE = "size",
  CONSTRUCTION = "construction",
  BODY_WIDTH = "bodyWidth",
  BODY_LENGTH = "bodyLength",
  NECKLINE = "neckline",
  SLEEVE = "sleeve",
  YARDAGE_METER = "yardageMeter",
  SKEINS = "skeins",
}

export const DEFAULT_SWEATER: Sweater = {
  size: SweaterSize.M,
  construction: SweaterConstruction.SETIN,
  bodyWidth: SweaterBodyWidth.REGULAR,
  bodyLength: SweaterBodyLength.REGULAR,
  neckline: SweaterNeckline.REGULAR,
  sleeve: SweaterSleeve.REGULAR,
  skeins: 0,
  yardageMeter: 0,
};

export const urlParamsSweater: Sweater = {
  size:
    (searchParams.get(SweaterKeys.SIZE) as SweaterSize) ?? DEFAULT_SWEATER.size,
  construction:
    (searchParams.get(SweaterKeys.CONSTRUCTION) as SweaterConstruction) ??
    DEFAULT_SWEATER.construction,
  bodyWidth:
    (searchParams.get(SweaterKeys.BODY_WIDTH) as SweaterBodyWidth) ??
    DEFAULT_SWEATER.bodyWidth,
  bodyLength:
    (searchParams.get(SweaterKeys.BODY_LENGTH) as SweaterBodyLength) ??
    DEFAULT_SWEATER.bodyLength,
  neckline:
    (searchParams.get(SweaterKeys.NECKLINE) as SweaterNeckline) ??
    DEFAULT_SWEATER.neckline,
  sleeve:
    (searchParams.get(SweaterKeys.SLEEVE) as SweaterSleeve) ??
    DEFAULT_SWEATER.sleeve,
  skeins:
    Number(searchParams.get(SweaterKeys.SKEINS)) ?? DEFAULT_SWEATER.skeins,
  yardageMeter:
    Number(searchParams.get(SweaterKeys.YARDAGE_METER)) ??
    DEFAULT_SWEATER.yardageMeter,
};
