import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "assets/locales/en.json";
import translationsNB from "assets/locales/nb.json";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationsEN,
    },
    nb: {
      translation: translationsNB,
    },
  },
  lng: "nb",
  fallbackLng: "nb",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
