import { api } from "./api/api";
import { APIPath } from "types/APIPath";

interface ConversionRate {
  conversionRateNOK_USD: number;
}

export async function getConversionRateUSD() {
  const queryParams = "?currencyCode=USD";
  const exchangeRatesResponse = await api.get(
    APIPath.GET_CONVERSION_RATES + queryParams,
  );
  const exchangeRates: ConversionRate = exchangeRatesResponse.data;
  return exchangeRates.conversionRateNOK_USD;
}

export function convertFromNOKToCurrency(
  nokValue: number,
  exchangeRate: number,
  precision?: number,
): string {
  return (nokValue / exchangeRate).toFixed(precision ?? 1);
}
