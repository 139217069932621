import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useShoppingCart } from "store/ShoppingCartContext";
import {
  DeliveryCustomerInfo,
  DeliveryMethodOptions,
  PickupCustomerInfo,
} from "types/Checkout";
import { requiresDeliveryAndShippingForm } from "utils/requireDeliveryAndShippingForm";
import {
  getMailShippingInformationFormValues,
  getNoYarnEmailValue,
  getPickupShippingInformationFormValues,
  getSelectedDeliveryMethod,
  hasAcceptedTerms,
  isEmailAddressValid,
  isFormValuesValid,
  isShippingFormValid,
} from "utils/shippingInformationFormUtils";
import VippsEcomButton from "./VippsEcomButton";

export default function VippsCheckoutForm() {
  const [displayFormError, setDisplayFormError] = useState(false);
  const [message, setMessage] = useState("");
  const { shoppingCart } = useShoppingCart();
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", paddingBottom: "0.5rem" }}>
      <form
        onSubmit={(e) => e.preventDefault()}
        className="vipps-checkout-form"
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "1rem",
            }}
          >
            <p className="text-s" style={{ marginBottom: "0.5rem" }}>
              {t("checkout.checkoutForm.vipps.explanation")}
            </p>
            <VippsEcomButton
              onClick={() => {
                const customerInfo: PickupCustomerInfo | DeliveryCustomerInfo =
                  getSelectedDeliveryMethod() === DeliveryMethodOptions.PICKUP
                    ? getPickupShippingInformationFormValues()
                    : getMailShippingInformationFormValues();
                const isFormValid =
                  requiresDeliveryAndShippingForm(shoppingCart) &&
                  isFormValuesValid(getSelectedDeliveryMethod()!, customerInfo);

                const isValid = isShippingFormValid(
                  requiresDeliveryAndShippingForm(shoppingCart),
                  isFormValid,
                  isEmailAddressValid(getNoYarnEmailValue()),
                  hasAcceptedTerms(),
                );

                if (!isValid) {
                  setDisplayFormError(true);
                  setMessage(
                    t("checkout.checkoutForm.shipping.errorMessage") ?? "",
                  );
                } else if (isValid && displayFormError) {
                  setDisplayFormError(false);
                  setMessage("");
                }
              }}
            />
            {message && (
              <div className="payment-error-message text-s">{message}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
