import { Container } from "@mui/material";
import GoBackButton from "components/Other/GoBackButton/GoBackButton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDesign } from "store/DesignContext";
import { CalculateHatYardage, CalculateSweaterYardage } from "types/APITypes";
import AppPath from "types/AppPath";
import { ProductType } from "types/ShoppingCart";
import {
  MainAndSecondarySkeins,
  VariantYarn,
  VariantYarnCollection,
  Yarn,
} from "types/Yarn";
import { CalculateYardageApi } from "utils/api/CalculateYardageApi";
import { YarnsApi } from "utils/api/YarnsApi";
import CollapsibleInformationBoxes from "./CollapsibleInformationBoxes";
import ColorCircleContainer from "./ColorCircleContainer";
import "./DetailedYarnView.scss";
import PurchasableYarnSection from "./PurchasableYarnSection";
import SimilarYarnsContainer from "./SimilarYarnsContainer";
import YarnCharacteristics from "./YarnCharacteristics";
import YarnImageContainer from "./YarnImageContainer";
import YarnTitleAndSupplier from "./YarnTitleAndSupplier";
import Spinner from "components/Other/Loading/Spinner";

const findMainAndSecondaryYarnVariants = (
  variantYarnCollection: VariantYarnCollection,
  yarnHasTwoYarns: boolean,
) => {
  const mainAndSecondaryYarnVariants = {
    mainVariant: {} as VariantYarn,
    secondaryVariant: {} as VariantYarn,
  };
  variantYarnCollection.variants.forEach((variant, index) => {
    const isFirstItem = index === 0;

    if (isFirstItem) {
      const isMainVariant = variant.main;

      if (isMainVariant) {
        mainAndSecondaryYarnVariants.mainVariant = variant;

        if (yarnHasTwoYarns) {
          const secondaryVariantYarn = variantYarnCollection.variants.find(
            (secondaryVariant) => {
              const isSecondaryVariant = !secondaryVariant.main;
              const colorMatchesMainVariant =
                secondaryVariant.color === variant.color;
              return isSecondaryVariant && colorMatchesMainVariant;
            },
          );

          if (secondaryVariantYarn !== undefined)
            mainAndSecondaryYarnVariants.secondaryVariant =
              secondaryVariantYarn;
        }
      } else {
        // Variant is secondary yarn
        mainAndSecondaryYarnVariants.secondaryVariant = variant;
        const mainVariantYarn = variantYarnCollection.variants.find(
          (mainVariant) => {
            const isMainVariant = mainVariant.main;
            const colorMatchesSecondVariant =
              mainVariant.color === variant.color;
            return isMainVariant && colorMatchesSecondVariant;
          },
        );

        if (mainVariantYarn !== undefined)
          mainAndSecondaryYarnVariants.mainVariant = mainVariantYarn;
      }
    }
  });

  return mainAndSecondaryYarnVariants;
};

const DetailedYarnView = () => {
  const yarnId = useParams<{ id: string }>().id;
  const { design } = useDesign();
  const [isLoading, setIsLoading] = useState(true);
  const [yarnData, setYarnData] = useState<Yarn>();
  const [variantYarnData, setVariantYarnData] =
    useState<VariantYarnCollection>();
  const [selectedVariantYarnMain, setSelectedVariantYarnMain] =
    useState<VariantYarn>();
  const [selectedVariantYarnSecondary, setSelectedVariantYarnSecondary] =
    useState<VariantYarn>();
  const [skeins, setSkeins] = useState<MainAndSecondarySkeins>();

  useEffect(() => {
    setIsLoading(true);
    setVariantYarnData(undefined);
    setSelectedVariantYarnMain(undefined);
    setSelectedVariantYarnSecondary(undefined);
    YarnsApi.getById(parseInt(yarnId))
      .then((yarn) => {
        if (!yarn.purchasable) {
          setIsLoading(false);
        }
        setYarnData(yarn);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [yarnId]);

  useEffect(() => {
    if (yarnData === undefined) return;
    if (yarnData?.purchasable) {
      YarnsApi.getVariantYarnById(parseInt(yarnId))
        .then((variantYarnCollection) => {
          setVariantYarnData(variantYarnCollection);

          const mainVariantYarnSelected = selectedVariantYarnMain !== undefined;
          const yarnHasTwoYarns = yarnData.secondName !== undefined;

          if (mainVariantYarnSelected) return;

          const { mainVariant, secondaryVariant } =
            findMainAndSecondaryYarnVariants(
              variantYarnCollection,
              yarnHasTwoYarns,
            );
          const secondaryVariantDefined =
            secondaryVariant !== undefined &&
            Object.keys(secondaryVariant).length > 0;

          setSelectedVariantYarnMain(mainVariant);
          if (secondaryVariantDefined)
            setSelectedVariantYarnSecondary(secondaryVariant);
        })
        .finally(() => setIsLoading(false));
    }

    getSkeins().then((skeins) => {
      if (skeins) setSkeins(skeins);
    });
  }, [yarnData]);

  function getSkeins(): Promise<MainAndSecondarySkeins | void> {
    if (design.patternType === ProductType.MITTENSPATTERN)
      return Promise.resolve();
    if (yarnData === undefined) return Promise.resolve();

    if (design.patternType === ProductType.SWEATERPATTERN) {
      const { bodyLength, bodyWidth, construction, neckline, size, sleeve } =
        design.designChoices;
      const calculateYardageData: CalculateSweaterYardage = {
        needlesize: yarnData?.needlesize,
        gaugeWidth: yarnData?.gaugeWidth,
        yardage: yarnData.yardage,
        secondYardage: yarnData.secondYardage,
        bodyLength: bodyLength,
        bodyWidth: bodyWidth,
        construction: construction,
        neckline: neckline,
        size: size,
        sleeve: sleeve,
        productTypeId: ProductType.SWEATERPATTERN,
      };

      return CalculateYardageApi.post(calculateYardageData).then((res) => {
        const data = res.data;
        const skeins = data.skeins;

        return {
          main: skeins[0],
          secondary: skeins[1],
        } as MainAndSecondarySkeins;
      });
    } else if (design.patternType === ProductType.HATPATTERN) {
      const { size, bodyStructure, brimStructure, decreases, brim } =
        design.designChoices;
      const calculateYardageData = {
        needlesize: yarnData?.needlesize,
        gaugeWidth: yarnData?.gaugeWidth,
        yardage: yarnData.yardage,
        secondYardage: yarnData.secondYardage,
        size,
        decreases,
        brim,
        brimStructure,
        bodyStructure,
        productTypeId: ProductType.HATPATTERN,
      } as CalculateHatYardage;

      return CalculateYardageApi.post(calculateYardageData).then((res) => {
        const data = res.data;
        const skeins = data.skeins;

        return {
          main: skeins[0],
          secondary: skeins[1],
        } as MainAndSecondarySkeins;
      });
    }
    return Promise.resolve();
  }

  function getInfoText() {
    if (yarnData?.purchasable && variantYarnData !== undefined)
      return variantYarnData?.infoText;

    if (yarnData?.infoText !== undefined) return yarnData.infoText;

    return "";
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Container id="detailed-yarn-view-container">
      <div style={{ height: "50px" }}>
        <GoBackButton fallbackPath={AppPath.SWEATER} />
      </div>
      <div className="detailed-yarn-view-content-container"></div>
      <div className="detailed-yarn-view-flex-container">
        <YarnImageContainer
          imgLocation={
            selectedVariantYarnMain
              ? selectedVariantYarnMain.imagePath
              : yarnData?.imagePath
          }
        />
        <div style={{ maxWidth: "600px", width: "100%" }}>
          <Container className="detailed-yarn-view-section yarn-information-container">
            <YarnTitleAndSupplier
              supplier={yarnData?.supplier ?? ""}
              yarnName={yarnData?.name ?? ""}
              yarnPrice={selectedVariantYarnMain?.price}
              yarnTwoName={yarnData?.secondName ?? ""}
              yarnTwoPrice={selectedVariantYarnSecondary?.price}
              colorName={selectedVariantYarnMain?.colorName}
            />
            <span className="color-container">
              <ColorCircleContainer
                hasSecondaryYarn={yarnData?.secondName !== ""}
                variantYarnData={variantYarnData}
                selectedVariantYarnMain={selectedVariantYarnMain}
                setSelectedVariantYarnMain={setSelectedVariantYarnMain}
                setSelectedVariantYarnSecondary={
                  setSelectedVariantYarnSecondary
                }
              />
            </span>
            <YarnCharacteristics yarnData={yarnData} skeins={skeins} />
          </Container>
          <PurchasableYarnSection
            shouldRenderPurchaseSection={
              yarnData?.purchasable && variantYarnData !== undefined
            }
            yarnData={yarnData}
            selectedVariantYarnMain={selectedVariantYarnMain}
            selectedVariantYarnSecondary={selectedVariantYarnSecondary}
            skeins={skeins}
          />
          <Container
            className="collapsible-information-section"
            data-cy="collapsible-information-section"
            sx={{ padding: 0 }}
          >
            <CollapsibleInformationBoxes
              renderShippingInformation={yarnData?.purchasable}
              infoText={getInfoText()}
            />
          </Container>
        </div>
      </div>
      <SimilarYarnsContainer
        gaugeWidth={yarnData?.gaugeWidth ?? 0}
        ownYarnId={yarnData?.yarnId ?? ""}
      />
    </Container>
  );
};

export default DetailedYarnView;
