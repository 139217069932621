import { DesignChoicesBase } from "./DesignChoices";

const searchParams = new URLSearchParams(window.location.search);
export enum HatSize {
  BABY = "baby",
  KIDS = "kids",
  S = "s",
  M = "m",
  L = "l",
  XL = "xl",
}

export enum HatBodyStructure {
  STOCKINETTE = "stockinette",
  ONE_BY_ONE = "1x1",
  TWO_BY_TWO = "2x2",
}

export enum HatBrim {
  NO = "no",
  SINGLE = "single",
  DOUBLE = "double",
}

export enum HatBrimStructure {
  STOCKINETTE = "stockinette",
  ONE_BY_ONE = "1x1",
  TWO_BY_TWO = "2x2",
}

export enum HatDecreases {
  CROSS = "cross",
  SPIRAL = "spiral",
  SIDE = "side",
}

export interface Hat extends DesignChoicesBase {
  size: HatSize;
  bodyStructure: HatBodyStructure;
  brim: HatBrim;
  brimStructure: HatBrimStructure;
  decreases: HatDecreases;
}

export enum HatKeys {
  SIZE = "size",
  BODY_STRUCTURE = "bodyStructure",
  BRIM = "brim",
  BRIM_STRUCTURE = "brimStructure",
  YARDAGE_METER = "yardageMeter",
  DECREASES = "decreases",
  SKEINS = "skeins",
}

export const DEFAULT_HAT: Hat = {
  size: HatSize.M,
  bodyStructure: HatBodyStructure.ONE_BY_ONE,
  brim: HatBrim.SINGLE,
  brimStructure: HatBrimStructure.ONE_BY_ONE,
  decreases: HatDecreases.CROSS,
  yardageMeter: 0,
  skeins: 0,
};

export const urlParamsHat: Hat = {
  size: (searchParams.get("size") as HatSize) ?? DEFAULT_HAT.size,
  bodyStructure:
    (searchParams.get("bodyStructure") as HatBodyStructure) ??
    DEFAULT_HAT.bodyStructure,
  brim: (searchParams.get("brim") as HatBrim) ?? DEFAULT_HAT.brim,
  brimStructure:
    (searchParams.get("brimStructure") as HatBrimStructure) ??
    DEFAULT_HAT.brimStructure,
  decreases:
    (searchParams.get("decreases") as HatDecreases) ?? DEFAULT_HAT.decreases,
  yardageMeter:
    (searchParams.get("yardageMeter") as unknown as number) ??
    DEFAULT_HAT.yardageMeter,
  skeins:
    (searchParams.get("skeins") as unknown as number) ?? DEFAULT_HAT.skeins,
};
