import { APIPath } from "types/APIPath";
import { checkStatusAndLogApiError } from "utils/logging/applicationInsightsLogger";
import { api } from "./api";
import { YarnSupplierResponse } from "types/APITypes";

export async function fetchYarnSuppliers(): Promise<YarnSupplierResponse[]> {
  return api
    .get<YarnSupplierResponse[]>(APIPath.GET_YARN_SUPPLIERS)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatusAndLogApiError(
        APIPath.GET_YARN_SUPPLIERS,
        error,
        error.config,
      );
      throw error;
    });
}
