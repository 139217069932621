import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";
import BasicCard from "components/Yarn/BasicCard/BasicCard";
import { useState } from "react";
import AddYarnCardBody from "../AddYarn/AddYarnCard/AddYarnCardBody";
import "./NoMatchesFound.scss";
import PopUpWindow from "../AddYarn/PopUpWindow/PopUpWindow";

const NoMatchesFound = () => {
  const [showAddYarnCard, setShowAddYarnCard] = useState(false);

  const openInputFields = () => setShowAddYarnCard(true);
  const closeInputFields = () => setShowAddYarnCard(false);

  return (
    <Grid
      container
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      justifyContent={"flex-start"}
    >
      <Grid item key={"empty"} data-cy="noYarnFoundGrid">
        <BasicCard>
          {showAddYarnCard ? (
            <AddYarnCardBody closeInputFields={closeInputFields} />
          ) : (
            <NoMatchesFoundCardBody openInputFields={openInputFields} />
          )}
        </BasicCard>
      </Grid>
    </Grid>
  );
};

interface NoMatchesFoundCardBodyProps {
  openInputFields: () => void;
}

const NoMatchesFoundCardBody = (props: NoMatchesFoundCardBodyProps) => {
  const { t } = useTranslation();
  return (
    <div className="noMatchesFoundCard">
      <h3 className="noMatchesFoundTitle" data-cy="noYarnFound">
        {t("yarndb.noSearchMatch")}
      </h3>
      <div className="sm-surface-button-container">
        <PopUpWindow
          buttonText={t("yarndb.addYarn")}
          className="sm-surface-button"
        />
      </div>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={() => props.openInputFields()}
        className="md-lg-surface-button"
      >
        {t("yarndb.addYarn")}
      </Button>
    </div>
  );
};

export default NoMatchesFound;
