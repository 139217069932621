const searchParams = new URLSearchParams(window.location.search);

export interface DesignYarn {
  yarnId: string | undefined;
  name: string;
  secondName?: string;
  supplier: string;
  needlesize: number;
  yardage: number;
  secondYardage?: number;
  gaugeWidth: number;
}

export interface Yarn extends DesignYarn {
  popular: boolean;
  numColors?: number;
  fullName: string;
  imagePath: string;
  infoText?: string;
  purchasable: boolean;
  url: string;
}

export interface CustomYarn {
  name: string;
  needlesize: number;
  yardage: number;
  gaugeWidth: number;
}

export interface VariantYarn {
  available: boolean;
  color: string;
  colorName: string;
  id: string;
  imagePath: string;
  main: boolean;
  name: string;
  price: number;
}

export interface VariantYarnCollection {
  fullName: string;
  id: string;
  infoText: string;
  name: string;
  numberColors: number;
  supplier: string;
  variants: VariantYarn[];
}

export interface MainAndSecondarySkeins {
  main: number;
  secondary: number;
}

export const urlParamsDesignYarn: DesignYarn = {
  yarnId: searchParams.get("id") || undefined,
  name: searchParams.get("yarn") || "",
  gaugeWidth: parseFloat(searchParams.get("gaugeWidth") || "0"),
  needlesize: parseFloat(searchParams.get("needlesize") || "0"),
  yardage: parseInt(searchParams.get("yardage") || "0"),
  supplier: "",
};
