import { Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export function Newsletter() {
  const { t } = useTranslation();

  return (
    <Typography>
      <Trans t={t} i18nKey="checkout.checkoutForm.newsletter">
        Ja, jeg vil gjerne motta nyhetsbrev fra Dreamknit
      </Trans>
    </Typography>
  );
}
