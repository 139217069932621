import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useRef, useState } from "react";
import { useParentElementWidth } from "utils/hooks";

interface MultipleSelectProps {
  items: string[];
  label: string;
  onSelectionChange: (selectedItems: string[]) => void;
  initialSelectedItems?: string[];
}

export default function MultipleSelect({
  items,
  label,
  onSelectionChange,
  initialSelectedItems = [],
}: MultipleSelectProps) {
  const [selectedItems, setSelectedItems] =
    useState<string[]>(initialSelectedItems);
  const formControlRef = useRef<HTMLDivElement>(null);
  const selectWidth = useParentElementWidth(formControlRef);

  useEffect(() => {
    onSelectionChange(selectedItems);
  }, [selectedItems, onSelectionChange]);

  return (
    <div ref={formControlRef} style={{ width: "100%" }}>
      <Autocomplete
        multiple
        size="small"
        options={items}
        value={selectedItems}
        onChange={(event, newValue) => {
          setSelectedItems(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder={label}
          />
        )}
        style={{ width: selectWidth }}
      />
    </div>
  );
}
