import { Sweater } from "types/DesignChoices";

export const defaultPattern = {
  size: "m",
  construction: "setin",
  bodyWidth: "regular",
  bodyLength: "regular",
  neckline: "regular",
  sleeve: "regular",
  skeins: 0,
  yardageMeter: 0,
} as Sweater;
