import accordionHeaderStyles from "./AccordionHeader.module.scss";

interface AccordionHeaderProps {
  title: string;
}

const AccordionHeader = (props: AccordionHeaderProps) => {
  return (
    <div className={accordionHeaderStyles["accordionHeader"]}>
      <h1>{props.title}</h1>
    </div>
  );
};

export default AccordionHeader;
