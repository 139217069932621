import {
  DeliveryCustomerInfo,
  DeliveryMethodOptions,
  EmailCustomerInfo,
  PickupCustomerInfo,
} from "types/Checkout";
import {
  validateEmail,
  validateName,
  validatePhoneNumber,
  validateZipcode,
} from "./regexValidators";

const INVALID_STRING = "INVALID";

const isEmailAddressValid = (emailAddr: string) => {
  return validateEmail(emailAddr);
};

const isNameValid = (name: string) => {
  return validateName(name) && name.length > 0;
};

const isZipcodeValid = (zipcode: string | undefined) => {
  if (zipcode === undefined) return false;
  return validateZipcode(zipcode);
};

const isPhoneNumberValid = (phoneNumber: string) => {
  return validatePhoneNumber(phoneNumber);
};

const getPickupShippingInformationFormValues = () => {
  const formValues = fetchPickupShippingInformationFormValuesFromIDs();
  const replaceInvalidProperties = (customerInfo: PickupCustomerInfo) => {
    if (!isEmailAddressValid(customerInfo.email))
      customerInfo.email = INVALID_STRING;
    if (!isNameValid(customerInfo.firstName))
      customerInfo.firstName = INVALID_STRING;
    if (!isNameValid(customerInfo.lastName))
      customerInfo.lastName = INVALID_STRING;
    if (!isPhoneNumberValid(customerInfo.phone))
      customerInfo.phone = INVALID_STRING;
  };

  replaceInvalidProperties(formValues);
  return formValues;
};

const getMailShippingInformationFormValues = () => {
  const formValues = fetchMailShippingInformationFormValuesFromIDs();
  const replaceInvalidProperties = (customerInfo: DeliveryCustomerInfo) => {
    if (!isEmailAddressValid(customerInfo.email))
      customerInfo.email = INVALID_STRING;
    if (!isNameValid(customerInfo.firstName))
      customerInfo.firstName = INVALID_STRING;
    if (!isNameValid(customerInfo.lastName))
      customerInfo.lastName = INVALID_STRING;
    if (customerInfo.address === "") customerInfo.address = INVALID_STRING;
    if (!isZipcodeValid(customerInfo.zipcode))
      customerInfo.zipcode = INVALID_STRING;
    if (customerInfo.city === "") customerInfo.city = INVALID_STRING;
    if (!isPhoneNumberValid(customerInfo.phone))
      customerInfo.phone = INVALID_STRING;
  };

  replaceInvalidProperties(formValues);
  return formValues;
};

const getEmailShippingInformationFormValues = () => {
  const fromValues = fetchEmailShippingInformationFormValuesFromIDs();
  const replaceInvalidProperties = (customerInfo: EmailCustomerInfo) => {
    if (!isEmailAddressValid(customerInfo.email))
      customerInfo.email = INVALID_STRING;
  };
  replaceInvalidProperties(fromValues);
  return fromValues;
};

const isFormValuesValid = (
  deliveryOption: DeliveryMethodOptions,
  customerInfo?: PickupCustomerInfo | DeliveryCustomerInfo,
) => {
  if (deliveryOption === undefined) return true;

  if (customerInfo === undefined) return false;

  const { email, firstName, lastName, phone } = customerInfo;

  switch (deliveryOption) {
    case DeliveryMethodOptions.MAIL: {
      // If mail, all fields must be valid
      const { address, zipcode, city } = customerInfo as DeliveryCustomerInfo;
      if (email === "INVALID") return false;
      if (firstName === "INVALID") return false;
      if (lastName === "INVALID") return false;
      if (address === "INVALID") return false;
      if (zipcode === "INVALID") return false;
      if (city === "INVALID") return false;
      if (phone === "INVALID") return false;
      return true;
    }
    case DeliveryMethodOptions.PICKUP: {
      // If pickup, address fields are omitted
      if (email === "INVALID") return false;
      if (firstName === "INVALID") return false;
      if (lastName === "INVALID") return false;
      if (phone === "INVALID") return false;
      return true;
    }
    default:
      return true;
  }
};

const getNoYarnEmailValue = () => {
  const email = getHTMLInputElementValueByID("shipping-information-user-email");

  return isEmailAddressValid(email) ? email : INVALID_STRING;
};

const isShippingFormValid = (
  isBuyingYarn: boolean,
  isFormValid: boolean,
  isEmailAddressValid: boolean,
  termsAccepted: boolean,
) => {
  return (
    ((isBuyingYarn && isFormValid) || (!isBuyingYarn && isEmailAddressValid)) &&
    termsAccepted
  );
};

const hasAcceptedTerms = () => {
  const element = document.getElementById(
    "shipping-information-accept-terms",
  ) as HTMLInputElement;
  return element.checked;
};

const fetchPickupShippingInformationFormValuesFromIDs =
  (): PickupCustomerInfo => {
    const email: string = getHTMLInputElementValueByID(
      "shipping-information-email",
    );
    const firstName: string = getHTMLInputElementValueByID(
      "shipping-information-firstname",
    );
    const lastName: string = getHTMLInputElementValueByID(
      "shipping-information-lastname",
    );
    const phone: string = getHTMLInputElementValueByID(
      "shipping-information-phone",
    );
    const wantsNewsletter: boolean = (
      document.getElementById(
        "shipping-information-newsletter",
      ) as HTMLInputElement
    ).checked;

    return {
      email,
      firstName,
      lastName,
      phone,
      wantsNewsletter,
    } as PickupCustomerInfo;
  };

const fetchMailShippingInformationFormValuesFromIDs =
  (): DeliveryCustomerInfo => {
    const email: string = getHTMLInputElementValueByID(
      "shipping-information-email",
    );
    const firstName: string = getHTMLInputElementValueByID(
      "shipping-information-firstname",
    );
    const lastName: string = getHTMLInputElementValueByID(
      "shipping-information-lastname",
    );
    const address: string = getHTMLInputElementValueByID(
      "shipping-information-address",
    );
    const zipcode: string = getHTMLInputElementValueByID(
      "shipping-information-zip",
    );
    const city: string = getHTMLInputElementValueByID(
      "shipping-information-city",
    );
    const phone: string = getHTMLInputElementValueByID(
      "shipping-information-phone",
    );
    const wantsNewsletter: boolean = (
      document.getElementById(
        "shipping-information-newsletter",
      ) as HTMLInputElement
    ).checked;

    return {
      email,
      firstName,
      lastName,
      address,
      zipcode,
      city,
      phone,
      wantsNewsletter,
    } as DeliveryCustomerInfo;
  };

const fetchEmailShippingInformationFormValuesFromIDs =
  (): EmailCustomerInfo => {
    const email = getHTMLInputElementValueByID(
      "shipping-information-user-email",
    );
    const wantsNewsletter: boolean = (
      document.getElementById(
        "shipping-information-newsletter",
      ) as HTMLInputElement
    ).checked;
    return {
      email,
      wantsNewsletter,
    } as EmailCustomerInfo;
  };
function getHTMLInputElementValueByID(id: string) {
  const element = document.getElementById(id) as HTMLInputElement;
  return element !== null
    ? (document.getElementById(id) as HTMLInputElement).value
    : "";
}

function getSelectedDeliveryMethod(): DeliveryMethodOptions {
  const getSelectorString = (deliveryMethodOption: DeliveryMethodOptions) =>
    `[id="dk-radio-button-${deliveryMethodOption}"] > span > input[type="radio"]:checked`;

  const isMailSelected =
    document.querySelector(getSelectorString(DeliveryMethodOptions.MAIL)) !==
    null;

  const isPickupSelected =
    document.querySelector(getSelectorString(DeliveryMethodOptions.PICKUP)) !==
    null;

  const noDeliveryMethodSelected = !isMailSelected && !isPickupSelected;

  if (noDeliveryMethodSelected) return DeliveryMethodOptions.NO_DELIVERY;

  return isMailSelected
    ? DeliveryMethodOptions.MAIL
    : DeliveryMethodOptions.PICKUP;
}

export {
  getEmailShippingInformationFormValues,
  getMailShippingInformationFormValues,
  getNoYarnEmailValue,
  getPickupShippingInformationFormValues,
  getSelectedDeliveryMethod,
  hasAcceptedTerms,
  isEmailAddressValid,
  isFormValuesValid,
  isNameValid,
  isPhoneNumberValid,
  isShippingFormValid,
  isZipcodeValid,
};
