import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "./TabPanel";
import TabProps from "./TabProps";

interface MobileDesignTabProps {
  tabSections: Array<{
    title: string;
    component: JSX.Element;
  }>;
}

function MobileDesignTab(props: MobileDesignTabProps) {
  const { tabSections } = props;
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(0);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          // scrollButtons
          allowScrollButtonsMobile
          // sx={{ width: "100%" }}
        >
          {tabSections.map((section, index) => (
            <Tab
              label={t(section.title)}
              key={section.title}
              {...TabProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabSections.map((section, index) => (
        <TabPanel value={value} index={index} key={section.title}>
          {section.component}
        </TabPanel>
      ))}
    </Box>
  );
}

export default MobileDesignTab;
