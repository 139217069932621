import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type InputSizes = "small" | "medium";

interface InputFieldProps {
  placeholder: string;
  options: any; // Specify a more appropriate type if known
  field: any; // Specify a more appropriate type if known
  handleInput: (field, value) => void; // Specify a more appropriate type for the input parameter if known
  size?: InputSizes;
  containerClassName?: string;
  padding?: boolean;
}

const InputField = ({
  placeholder,
  options,
  field,
  handleInput,
  size = "small",
  containerClassName = "",
  padding = true,
}: InputFieldProps) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>();
  const paddingValues = padding ? "10px 15px 6px 15px" : "0px";

  return (
    <div className={containerClassName}>
      <Autocomplete
        autoHighlight
        noOptionsText={t("design.designAccordion.yarn.yarnInputErrorMessage", {
          val1: options[0],
          val2: options[options.length - 1],
        })}
        onInputChange={(event, value) => {
          handleInput(field, value);
          setInputValue(value);
        }}
        inputValue={inputValue}
        options={options}
        sx={{ padding: paddingValues }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            InputLabelProps={{ style: { color: "black", marginTop: "-7px" } }}
            InputProps={{
              ...params.InputProps,
              notched: false,
            }}
            size={size}
            variant="outlined"
            label={placeholder}
            data-cy="addYarnInput"
          />
        )}
      />
    </div>
  );
};

export default InputField;
