import { DeliveryMethodOptions } from "types/Checkout";
import {
  getMailShippingInformationFormValues,
  getPickupShippingInformationFormValues,
  getEmailShippingInformationFormValues,
} from "./shippingInformationFormUtils";

export function getCustomerInfo(selectedDeliveryMethod: DeliveryMethodOptions) {
  let customerInfo;

  switch (selectedDeliveryMethod) {
    case DeliveryMethodOptions.PICKUP:
      customerInfo = getPickupShippingInformationFormValues();
      break;
    case DeliveryMethodOptions.MAIL:
      customerInfo = getMailShippingInformationFormValues();
      break;
    case DeliveryMethodOptions.NO_DELIVERY:
      customerInfo = getEmailShippingInformationFormValues();
      break;
    default:
      throw new Error(`Unknown delivery method: ${selectedDeliveryMethod}`);
  }
  return customerInfo;
}
