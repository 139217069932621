import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import "./ConstructionSelectionBox.scss"; // REMOVE
import "./FitSelectionBox.scss"; // REMOVE
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { HandleClickHatData } from "components/Design/DesignAccordion/HatAccordion";
import { Hat } from "types/DesignChoices";
import { HatBodyStructure, HatKeys } from "types/DesignChoices/Hat";

const StructureSelectionBox = (props: { handleClick: HandleClickHatData }) => {
  const { design, updateDesign } = useDesign();
  const { t } = useTranslation();

  const useEffectDependencies = [
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : "",
    HatKeys.BRIM_STRUCTURE in design.designChoices
      ? design.designChoices.brimStructure
      : "",
  ];

  const [activeClassStructure, setActiveClassStructure] = useState(
    HatKeys.BODY_STRUCTURE in design.designChoices
      ? design.designChoices.bodyStructure
      : "",
  );

  useEffect(() => {
    if (design.patternType === ProductType.HATPATTERN) {
      setActiveClassStructure(design.designChoices.bodyStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useEffectDependencies);

  const structureOptions = createSelectionOptions<Hat>({
    keys: Object.values(HatBodyStructure),
    dispatchFunction: updateDesign,
    designChoiceCategory: HatKeys.BODY_STRUCTURE,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassStructure,
    translationMapper: (key) =>
      mapKeyToTranslationKey(key, HatKeys.BODY_STRUCTURE),
  });

  return (
    <SelectionContainer>
      <SelectionOptionsBox
        options={structureOptions}
        activeOption={activeClassStructure}
        displayType="column"
      />
    </SelectionContainer>
  );
};

export default StructureSelectionBox;
