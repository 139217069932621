import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useDesign } from "store/DesignContext";
import { useShoppingCart } from "store/ShoppingCartContext";
import AppPath from "types/AppPath";
import { Hat } from "types/DesignChoices/Hat";
import { HAT_PATTERN_PRICE_WITHOUT_YARN } from "utils/constants/ShoppingCartConstants";
import { useDreamknitHistory } from "utils/intl/DreamknitHistory";
import {
  SessionStoragePatternId,
  getCurrentPrice,
} from "utils/shoppingCartUtils";
import BrimSelectionBox from "../DesignContainer/SelectionComponents/BrimSelectionBox";
import DecreasesSelectionBox from "../DesignContainer/SelectionComponents/DecreasesSelectionBox";
import SizeSelectionBox from "../DesignContainer/SelectionComponents/SizeSelectionBox";
import StructureSelectionBox from "../DesignContainer/SelectionComponents/StructureSelectionBox";
import YarnSelectionBox from "../DesignContainer/SelectionComponents/YarnSelectionBox";
import { AddToShoppingCartButton } from "./AddToShoppingCartButton";
import GenericAccordion from "./GenericAccordion";
import MobileDesignTab from "./MobileTab/MobileDesignTab";
import AccordionPrice from "./AccordionPrice";

interface HatDesignAccordionProps {
  setShowYarnErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export type HandleClickHatData = <K extends keyof Hat>(
  key: K,
  value: Hat[K],
) => void;

export const HatAccordion = (props: HatDesignAccordionProps) => {
  const { setShowYarnErrorMessage } = props;
  const { design, yarn, yarnIsChosen } = useDesign();
  const { shoppingCart, setIsOpen } = useShoppingCart();
  const { t } = useTranslation();
  const [itemPrice, setItemPrice] = useState(HAT_PATTERN_PRICE_WITHOUT_YARN);
  const pushToHistory = useDreamknitHistory();
  const location = useLocation();
  const openShoppingCart = () => setIsOpen(true);

  useEffect(() => {
    const patternId = SessionStoragePatternId.get();
    if (patternId === null) {
      setItemPrice(HAT_PATTERN_PRICE_WITHOUT_YARN);
      return;
    }

    setItemPrice(getCurrentPrice(shoppingCart.items, patternId));
  }, [design, yarn, shoppingCart]);

  const addQuery = <K extends keyof Hat>(key: K, value: Hat[K]) => {
    let searchParams = new URLSearchParams(location.search);
    searchParams.set(key, value.toString());
    pushToHistory({
      pathname: AppPath.HAT,
      search: searchParams.toString(),
    });
  };

  const handleSelectOption: HandleClickHatData = <K extends keyof Hat>(
    key: K,
    value: Hat[K],
  ) => {
    addQuery(key, value);
  };

  const designAccordionContent = [
    {
      component: <SizeSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.hatSize.title"),
    },
    {
      component: <StructureSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.hatStructure.title"),
    },
    {
      component: <BrimSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.hatBrim.title"),
    },
    {
      component: <DecreasesSelectionBox handleClick={handleSelectOption} />,
      title: t("design.designAccordion.hatDecreases.title"),
    },
    {
      component: <YarnSelectionBox />,
      title: t("design.designAccordion.yarn.title"),
    },
  ];

  return (
    <div className="designAccordion">
      <GenericAccordion accordionSections={designAccordionContent} />
      <div className="mobileAccordion">
        <MobileDesignTab tabSections={designAccordionContent} />
      </div>
      <div className="add-to-shopping-cart-section">
        <div className="add-to-shopping-cart-section-price">
          <AccordionPrice itemPrice={itemPrice} />
        </div>
        <div
          onClick={() => !yarnIsChosen && setShowYarnErrorMessage(true)}
          className="add-to-shopping-cart-section-button"
        >
          <AddToShoppingCartButton
            openShoppingCart={openShoppingCart}
            yarnIsChosen={yarnIsChosen}
          />
        </div>
      </div>
    </div>
  );
};
