import { Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDesign } from "store/DesignContext";
import { ProductType } from "types/ShoppingCart";
import "./ConstructionSelectionBox.scss"; // REMOVE
import "./FitSelectionBox.scss"; // REMOVE
import "./SelectionBox.scss";
import {
  createSelectionOptions,
  mapKeyToTranslationKey,
} from "utils/SelectionComponentUtils";
import { SelectionContainer } from "./SelectionContainer";
import { SelectionOptionsBox } from "./SelectionOptionsBox";
import { HandleClickHatData } from "components/Design/DesignAccordion/HatAccordion";
import { Hat } from "types/DesignChoices";
import { HatSize } from "types/Accessories";
import { HatKeys } from "types/DesignChoices/Hat";
import HatSizeGuide from "components/Design/SizeGuide/HatSizeGuide";

const SizeSelectionBox = (props: { handleClick: HandleClickHatData }) => {
  const { design, updateDesign } = useDesign();
  const { t } = useTranslation();

  const [showSizeGuide, setShowSizeGuide] = useState(false);

  const handleShowSizeGuide = () => {
    if (!showSizeGuide) {
      window.dataLayer.push({ eventProps: undefined });
      window.dataLayer.push({
        event: "showSizeGuide",
      });
    }
    setShowSizeGuide((value) => !value);
  };

  const [activeClassSize, setActiveClassSize] = useState(
    design.designChoices.size as string,
  );

  useEffect(() => {
    if (design.patternType === ProductType.HATPATTERN) {
      setActiveClassSize(design.designChoices.size);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [design.designChoices.size]);

  const sizeOptions = createSelectionOptions<Hat>({
    keys: Object.values(HatSize),
    dispatchFunction: updateDesign,
    designChoiceCategory: HatKeys.SIZE,
    handleClick: props.handleClick,
    setActiveClass: setActiveClassSize,
    translationMapper: (key) => mapKeyToTranslationKey(key, HatKeys.SIZE),
  });

  return (
    <SelectionContainer>
      <div className="sizeSelectionBoxInfo">
        <Link
          className="sizeGuideLink"
          onClick={handleShowSizeGuide}
          sx={{ color: "secondary.dark" }}
        >
          {t("design.designAccordion.fit.sizeGuide")}
        </Link>
        {showSizeGuide ? <HatSizeGuide /> : null}
      </div>
      <SelectionOptionsBox
        options={sizeOptions}
        activeOption={activeClassSize}
        displayType="row"
      />
    </SelectionContainer>
  );
};

export default SizeSelectionBox;
