import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import "../../../styles/colors.scss";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.main),
  backgroundColor: theme.palette.primary.main,
  margin: 2,
  padding: 10,
  fontFamily: "Brut Grotesque Book",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export default ColorButton;
