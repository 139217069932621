import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import AddYarnInputFields from "../AddYarnInputFields/AddYarnInputFields";

interface AddYarnCardBodyProps {
  closeInputFields: () => void;
}

const AddYarnCardBody = (props: AddYarnCardBodyProps) => {
  return (
    <div>
      <IconButton onClick={() => props.closeInputFields()}>
        <Cancel />
      </IconButton>
      <AddYarnInputFields />
    </div>
  );
};

export default AddYarnCardBody;
