import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "types/Language";
import { getLanguage } from "utils/intl/languageUtil";
import CollapsibleShippingInformation from "./CollapsibleShippingInformation";
import CollapsibleInformationContainer from "./CollapsibleInformationContainer";
import { Container } from "@mui/material";
import { infoTextIsMultilingual, parseInfoText } from "utils/api/YarnsApi";

interface CollapsibleInformationBoxesProps {
  infoText: string | undefined;
  renderShippingInformation?: boolean;
}

const CollapsibleInformationBoxes = (
  props: CollapsibleInformationBoxesProps,
) => {
  const { infoText, renderShippingInformation = false } = props;
  const { t } = useTranslation();
  const [showShippingInfo, setShowShippingInfo] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  if (infoText === undefined) {
    return <></>;
  }

  return (
    <>
      {renderShippingInformation && getLanguage() === Language.NO && (
        <CollapsibleShippingInformation
          isOpen={showShippingInfo}
          toggleOpen={() => setShowShippingInfo((prev) => !prev)}
        />
      )}
      {(getLanguage() === Language.NO || infoTextIsMultilingual(infoText)) && (
        <div className="padding-wrapper">
          <CollapsibleInformationContainer
            title={t("yarndb.detailedYarnView.moreInformation")}
            isOpen={showMoreInfo}
            toggleOpen={() => setShowMoreInfo((prev) => !prev)}
          >
            <Container
              className="collapsed-content"
              data-cy="collapsed-content-infotext"
            >
              {parseInfoText(infoText, getLanguage())}
            </Container>
          </CollapsibleInformationContainer>
        </div>
      )}
    </>
  );
};

export default CollapsibleInformationBoxes;
