import { useEffect, useState } from "react";

export function useParentElementHeight(childRef) {
  const [parentHeight, setParentHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (childRef.current && childRef.current.parentNode) {
        setParentHeight(childRef.current.parentNode.offsetHeight);
      }
    };

    // Initial check
    handleResize();

    // Set up event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [childRef]);

  return parentHeight;
}

export function useParentElementWidth(childRef) {
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (childRef.current && childRef.current.parentNode) {
        setParentWidth(childRef.current.parentNode.offsetWidth);
      }
    };

    // Initial check
    handleResize();

    // Set up event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [childRef]);

  return parentWidth;
}
